import { AppThunk } from "../../../../store";
import {
 
  CourierServiceChargesFailed,
  CourierServiceChargesSuccess,
} from "./slice";
import { fetchCourierCharges  } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const getCourierCharges =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await fetchCourierCharges(params);
      

      if (data.success === "N") {
        
         dispatch(CourierServiceChargesFailed(data));
      } else {
        
         dispatch(CourierServiceChargesSuccess(data));
      }
    } catch (err) {
      
       dispatch(CourierServiceChargesFailed(err));
    }
    dispatch<any>(EndProgress());
  };
