import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../../../utils/buyer.types";
import { Activity } from "../../../../utils/types";

type activityState = {
  purchaseHistory: Activity["purchaseHistory"] | null;
  bids: Activity["bids"] | null;
  offers: Activity["offers"] | null;
  recentlyViewed: Activity["recentlyViewed"] | null;
  recentlyViewedDelete: Activity["recentlyViewedDelete"] | null;
  recentlyViewedDeleteError: any | null;
  buyAgain: Activity["buyAgain"] | null;
  buyAgainError: any | null;
  watchlist: Activity["watchlist"] | null;
  savedSellers: Activity["savedSellers"] | null;
  noteSave: Activity["noteSave"] | null;
  noteError: any | null;
  error: any | null;
  saveDataProduct: Activity["saveDataProduct"] | null;
  saveOfferCounter: Activity["saveOfferCounter"] | null;
  saveOfferCounterError: Activity["saveOfferCounterError"] | null;


  saveDataSeller: Activity["saveDataSeller"] | null;
  feedbackBuyer: Activity["feedbackBuyer"] | null;
singleProductReview:Activity["singleProductReview"] | null;
  saveDataError: Activity["saveDataError"] | null;
  productReview: Activity["productReview"] | null;
  productReviewError:any | null ,
  orderDetails: Activity["orderDetails"] | null;
  returnDetailsBuyer: Activity["returnDetailsBuyer"] | null;
  returnDetailsBuyerError:any | null ,
  courierServiceCharges: Activity["courierServiceCharges"] | null;

};

const initialState: activityState = {
  purchaseHistory: null,
  bids: null,
  offers: null,
  recentlyViewed: null,
  recentlyViewedDelete: null,
  recentlyViewedDeleteError: null,
  buyAgain: null,
  feedbackBuyer:null,
  buyAgainError: null,
  watchlist: null,
  savedSellers: null,
  orderDetails:null,
  noteSave: null,
  noteError: null,
  error: null,
  saveDataProduct: null,
  saveDataSeller: null,
  saveOfferCounter:null,
  saveOfferCounterError:null,
  singleProductReview:null,
  saveDataError: null,
  productReview:null,
  productReviewError:null,
  returnDetailsBuyer:null,
  returnDetailsBuyerError:null,
  courierServiceCharges:null,
};
const slice = createSlice({
  name: "purchaseHistory",
  initialState,
  reducers: {
    reset: (state) => {
      
      state.noteSave = null;
      state.noteError = null;
      state.saveDataProduct = null;
      state.saveOfferCounter=null;
      state.saveOfferCounterError=null;
      state.saveDataSeller = null;
      state.saveDataError = null;
    },
    resetSaveProductFeedback:(state)=>{
      state.saveDataProduct = null;
      state.saveDataSeller = null;
      
    },
    resetSaveSellerFeedback:(state)=>{
      state.saveDataSeller = null;
      state.saveDataError = null;
    },
    resetOrderDetails:(state)=>{
      state.orderDetails = null;
      state.error = null;
      state.returnDetailsBuyer=null;
      state.returnDetailsBuyerError=null;
      state.courierServiceCharges=null;
    },
    resetRecentlyViewedDelete: (state) => {
      state.recentlyViewedDelete = null;
      state.recentlyViewedDeleteError = null;
    },
    PurchaseHistorySuccess: (state, action: PayloadAction<any>) => {
      state.purchaseHistory = action.payload;
      state.error = null;
      
    },
    PurchaseHistoryFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.purchaseHistory = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    OrderDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.orderDetails = action.payload;
      state.error = null;
      
    },
    OrderDetailsFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.orderDetails = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    CourierServiceChargesSuccess: (state, action: PayloadAction<any>) => {
      state.courierServiceCharges = action.payload;
      state.error = null;
      
    },
    CourierServiceChargesFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.courierServiceCharges = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    ReturnDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.returnDetailsBuyer = action.payload;
      state.returnDetailsBuyerError = null;
      
    },
    ReturnDetailsFailed: (state, action: PayloadAction<any>) => {
      state.returnDetailsBuyerError = action.payload;
      state.returnDetailsBuyer = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.returnDetailsBuyerError = errorMessage;
    },
    RecentlyViewedSuccess: (state, action: PayloadAction<any>) => {
      state.recentlyViewed = action.payload;
      state.error = null;
      
    },
    RecentlyViewedFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.recentlyViewed = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    BuyAgainSuccess: (state, action: PayloadAction<any>) => {
      state.buyAgain = action.payload;
      state.error = null;
      
    },
    BuyAgainFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.buyAgain = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.buyAgainError = errorMessage;
    },
    RecentlyViewedDeleteSuccess: (state, action: PayloadAction<any>) => {
      state.recentlyViewedDelete = action.payload;
      state.recentlyViewedDeleteError = null;
      
    },
    RecentlyViewedDeleteFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.recentlyViewedDelete = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.recentlyViewedDeleteError = errorMessage;
    },
    BidsSuccess: (state, action: PayloadAction<any>) => {
      state.bids = action.payload;
      state.error = null;
      
    },
    BidsFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.bids = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    OffersSuccess: (state, action: PayloadAction<any>) => {
      state.offers = action.payload;
      state.error = null;
      
    },
    OffersFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.offers = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    NoteSuccess: (state, action: PayloadAction<any>) => {
      state.noteSave = action.payload;
      state.noteError = null;
      
    },
    NoteFailed: (state, action: PayloadAction<any>) => {
      state.noteError = action.payload;
      state.noteSave = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.noteError = errorMessage;
    },
    SaveDataSellerSuccess: (state, action: PayloadAction<any>) => {
      state.saveDataSeller = action.payload;
      state.saveDataError = null;
      
    },
    SaveDataSellerFailed: (state, action: PayloadAction<any>) => {
      state.saveDataError = action.payload;
      state.saveDataSeller = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveDataError = errorMessage;
    },
    SaveDataProductSuccess: (state, action: PayloadAction<any>) => {
      state.saveDataProduct = action.payload;
      state.saveDataError = null;
      
    },
    SaveDataProductFailed: (state, action: PayloadAction<any>) => {
      state.saveDataError = action.payload;
      state.saveDataProduct = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveDataError = errorMessage;
    },
    SaveOfferCounterSuccess: (state, action: PayloadAction<any>) => {
      state.saveOfferCounter = action.payload;
      state.saveOfferCounterError = null;
      
    },
    SaveOfferCounterFailed: (state, action: PayloadAction<any>) => {
      state.saveOfferCounterError = action.payload;
      state.saveOfferCounter = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveOfferCounterError = errorMessage;
    },
    WatchlistSuccess: (state, action: PayloadAction<any>) => {
      state.watchlist = action.payload;
      state.error = null;
      
    },
    WatchlistFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.watchlist = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    SavedSellersSuccess: (state, action: PayloadAction<any>) => {
      state.savedSellers = action.payload;
      state.error = null;
      
    },
    SavedSellersFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.savedSellers = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    BuyerFeedbackSuccess: (state, action: PayloadAction<any>) => {
      state.feedbackBuyer = action.payload;
      state.error = null;
      
    },
    BuyerFeedbackFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.feedbackBuyer = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    SingleProductReviewSuccess: (state, action: PayloadAction<any>) => {
      state.singleProductReview = action.payload;
      state.error = null;
      
    },
    SingleProductReviewFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.singleProductReview = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    ProductReviewSuccess: (state, action: PayloadAction<any>) => {
      state.productReview = action.payload;
      state.productReviewError = null;
      
    },
    ProductReviewFailed: (state, action: PayloadAction<any>) => {
      state.productReviewError = action.payload;
      state.productReview = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.productReviewError = errorMessage;
    },
  },
});

export const activity = slice.reducer;

export const {
  PurchaseHistorySuccess,
  PurchaseHistoryFailed,
  BidsFailed,
  BidsSuccess,
  OffersFailed,
  OffersSuccess,
  NoteFailed,
  NoteSuccess,
  reset,
  RecentlyViewedFailed,
  RecentlyViewedSuccess,
  RecentlyViewedDeleteFailed,
  RecentlyViewedDeleteSuccess,
  resetRecentlyViewedDelete,
  BuyAgainFailed,
  BuyAgainSuccess,
  SaveDataProductFailed,
  SaveDataProductSuccess,
  SaveDataSellerFailed,
  SaveDataSellerSuccess,
  WatchlistFailed,
  WatchlistSuccess,
  SavedSellersFailed,
  SavedSellersSuccess,
   BuyerFeedbackFailed,
   BuyerFeedbackSuccess,
   resetSaveProductFeedback,
   resetSaveSellerFeedback,
   SingleProductReviewFailed,
   SingleProductReviewSuccess,
   ProductReviewFailed,
   ProductReviewSuccess,
   OrderDetailsFailed,
   OrderDetailsSuccess,
   resetOrderDetails,
   ReturnDetailsFailed,
   ReturnDetailsSuccess,
   CourierServiceChargesFailed,
   CourierServiceChargesSuccess,
   SaveOfferCounterFailed,
   SaveOfferCounterSuccess
} = slice.actions;
