import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { RootState } from "../../../utils/types";
import { ToastContainer } from "react-toastify";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../components/footer/Footer";
import { getOrderDetailShipment } from "../../../modules/orders/getOrderDetailShipment";
import { dateToLocal } from "../../../modules/date/date";
import ShipmentCancelModel from "./ShipmentCancelModel";
import Edit from "../../../assets/images/edit.svg"
import UpdatePickupDateModel from "./UpdatePickupDateModel";
import { getOrderTracking } from "../../../modules/orders/getOrderTracking";
import moment from "moment";

const OrderDetailShipment = () => {
  const { state } = useLocation();
  const orderID: any = state?.orderId;
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );

interface cancelPickupValues {
  visible:string;
  PickUpId:string | number;
  Reason:string;
}
interface createPickupValues {
  visible:string;
  PickUpId:string | number;
  PlannedPickupDateAndTime:string;
  date:string
}
const [createShipment, setCreateShipment] = useState<createPickupValues>({
  visible:"none",
  PlannedPickupDateAndTime: "",
  PickUpId: "",
  date:"",
});
  const [cancelShipment, setCancelShipment] = useState<cancelPickupValues>({
    visible:"none",
  
    PickUpId: "",
    Reason: "",
  });
 const convertAndDownloadPDF = (content:string,name:string) => {
    // Replace 'base64String' with your actual base64 string
    const base64String = content;

    // Decode the base64 string into a Uint8Array
    const bytes = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = url;
    a.download = name+'.pdf';

    // Trigger a click event on the anchor element to initiate the download
    a.click();

    // Clean up by revoking the URL object
    URL.revokeObjectURL(url);
  };


  const printPDF = (content:string) => {
    // Replace 'base64String' with your actual base64 string
    const base64String = content;

    // Decode the base64 string into a Uint8Array
    const bytes = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open a new window with the PDF for printing
    const printWindow:any = window.open(url);
    
    // Wait for the PDF to load and then trigger the print dialog
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        // Clean up by closing the print window and revoking the URL object
        printWindow.close();
        URL.revokeObjectURL(url);
      };
    };
  };

  useEffect(() => {
    if (isFirstRender.current && orderID!==undefined) {
      dispatch<any>(resetOrder("details")).then(
        dispatch<any>(getOrderDetailShipment({ orderId: orderID }))
      );
      // if (commonResponse?.common === null) dispatch<any>(getCommonData());
    isFirstRender.current = false;

    }
    
  }, [state]);
  useEffect(() => {
    if (order?.shipmentDetails?.data?.shipmentId) {
      dispatch<any>(getOrderTracking({ shipmentId: order?.shipmentDetails?.data?.shipmentId }));
      const parsedDate = moment(order?.shipmentDetails?.data?.plannedShippingDateAndTime);

      // Format the date as "YYYY-MM-DDTHH:mm"
      const formattedDate = parsedDate.format('YYYY-MM-DDTHH:mm');
    
      setCreateShipment({...createShipment, date:formattedDate})
    }
    
  }, [order?.shipmentDetails?.data?.shipmentId]);


    const handleCancelPickup =()=>{
      
      setCancelShipment({...cancelShipment, PickUpId:order?.shipmentDetails?.data?.pickUpId ,visible:"block" , Reason:""})

    }
    const handleUpdatePickup =()=>{
      
      setCreateShipment({...createShipment, PickUpId:order?.shipmentDetails?.data?.pickUpId ,visible:"block"})

    }
   
  useEffect(() => {
    if(!isFirstRender.current && orderID)
   { if (order.saveData != null) {
      if (order.saveData.success == "Y") {
        notify(order.saveData.message, constant.SUCCESS);

      setCancelShipment({
        visible: "none",
        PickUpId: "",
        Reason: "",
      });
      setCreateShipment({
        visible:"none",
        PlannedPickupDateAndTime: "",
        PickUpId: "",
        date:"",
      });
        dispatch<any>(getOrderDetailShipment({ orderId: orderID }))
      } 
    }
       else if (order.saveDataError !== null)
        notify(order.saveDataError, constant.ERROR);
      dispatch<any>(resetOrder());
    }

  
  }, [order.saveData, order.saveDataError]);
  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />
     <ShipmentCancelModel 
     cancelShipment={cancelShipment}
     setCancelShipment={setCancelShipment}
     />
        <UpdatePickupDateModel
     createShipment={createShipment}
     setCreateShipment={setCreateShipment}
     />
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"/seller/overview"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/seller/orders"}>orders</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">Shipment detail</li>
          </ul>
        </div>
      </section>

      <main className="seller-page-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="order-detail-heading">
                <h2 className="seller-head-style">Shipment detail</h2>
              </div>
              <div className="order-detail-block">
                <div className="order-id-style">
                  Created on:{" "}
                 {moment(dateToLocal( order?.shipmentDetails?.data?.createdOn)).format("LLL")}
                </div>
                <div className="order-detail-block-inner order-detail-seller">
                  <div className="order-detail-section-l">
                    <div className="order-estimate-block">
                    Expected delivery{" "}
                      <strong>
                        { moment(order?.shipmentDetails?.data?.estimatedDeliveryDate ).format("MMMM Do YYYY")}
                      </strong> 
                      {/* |  Pickup date{"   "} 
                      <strong>
                        {order?.shipmentDetails?.data?.pickupDate}
                      </strong>
                      <img style={{height:18,width:25 ,cursor:"pointer"}} onClick={handleCancelPickup} src={Edit} /> */}
                    </div>
                    <div className="order-estimate-product">
                      <div className="table-order-detail">
                          <div className="order-detail-img-content " >
                            <div className="order-detail-img-l">
                           
                            </div>
                            <div className="order-detail-content-m">
                            
                             
                              <p className="order-product-text">
                              <span className="order-item-label"> Pickup date :</span>{" "}
                                { moment(order?.shipmentDetails?.data?.pickupDate ).format("MMMM Do YYYY")} { state?.status ==="New" &&<img style={{height:18,width:25 ,cursor:"pointer"}} onClick={handleUpdatePickup} src={Edit} />}
                              </p>
                              <table className="order-item-table">
                                <tr>
                                  <td style={{paddingRight:10}}>
                                    <label className="order-item-label">
                                    Pickup earliest
                                    </label>
                                    <p className="order-item-text">{order?.shipmentDetails?.data?.pickupEarliest}</p>
                                  </td>
                                  <td style={{paddingRight:10}}>
                                    <label className="order-item-label">
                                    Pickup Latest
                                    </label>
                                    <p className="order-item-text">
                                      {order?.shipmentDetails?.data?.pickupLatest}
                                    </p>
                                  </td>
                                  <td>
                                    <label className="order-item-label">
                                    Pickup No
                                    </label>
                                    <p className="order-item-text">
                                      {order?.shipmentDetails?.data?.pickupNo}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                              <p className="order-product-text">
                               <span className="order-item-label">Shipment cost :</span> {" "}
                              $ {order?.shipmentDetails?.data?.shipmentAmount}
                              </p>
                              <p className="order-product-text">
                              <span className="order-item-label"> Shipment description :</span>{" "}
                                {order?.shipmentDetails?.data?.shipmentDescription}
                              </p>
                              <p className="order-product-text">
                              <span className="order-item-label"> Tracking no :</span>{" "}
                                {order?.shipmentDetails?.data?.trackingNumber}
                              </p>
                              <p className="order-product-text">
                              <span className="order-item-label"> Tracking details:</span>{" "}
                            
                              </p>
                              <div className="historyTable" style={{minHeight:0, padding:0}}>
                {/* <div className="row trackDetail">
                  <div className="col-md-auto mr-md-5 mb-3 mb-md-0">
                    <strong>Tracking number</strong>
                    <span>1235698471AS425</span>
                  </div>
                  <div className="col-md-auto">
                    <strong>Expected delivery</strong>
                    <span>August 30, 2021</span>
                  </div>
                </div> */}
             
           {  order?.trackingDetails?.data?.trackings &&   <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date & Time</th>
                        <th>Stats of Item</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                    { order?.trackingDetails?.data?.trackings?.map((item)=> <tr>
                        <td>{moment(item.date).format("MMMM Do YYYY")}, { item.time}</td>
                        <td>{item.description}</td>
                        <td>{item.packageLocation[0]?.description}</td>
                      </tr>)}
                   
                    </tbody>
                  </table>
                </div>}
              </div>
                            </div>
                            <div>
                              <div className="order-button-block">
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                  convertAndDownloadPDF(order?.shipmentDetails?.data?.labelContent,"label")
                                  }
                                >
                                  Download label
                                </button>
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                  convertAndDownloadPDF(order?.shipmentDetails?.data?.billDocContent,"bill")}
                                >
                                  Download bill
                                </button>
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                    printPDF(order?.shipmentDetails?.data?.labelContent)
                                  }
                                >
                                 Print label
                                </button>
                            
                               
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                    printPDF(order?.shipmentDetails?.data?.billDocContent)
                                  }
                                >
                                 Print bill
                                </button>
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                    handleCancelPickup()
                                  }
                                >
                                 Cancel Pickup
                                </button>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div className="order-detail-section-r">
                    <div className="delivery-add-block">
                      <div className="delivery-add-title">Buyer address</div>
                      <div className="delivery-small-text">
                        {order?.shipmentDetails?.data?.buyerName}
                      </div>
                      <div className="delivery-add-text">
                        {order?.shipmentDetails?.data?.buyerAddress1},
                    { order?.shipmentDetails?.data?.buyerAddress2 &&<>   <br /> {order?.shipmentDetails?.data?.buyerAddress2}</>}
                        <br /> {order?.shipmentDetails?.data?.buyerCity}
                        <br /> {order?.shipmentDetails?.data?.buyerZipCode}
                        <br /> {order?.shipmentDetails?.data?.buyerCountryName}
                        <br />Contact no: {order?.shipmentDetails?.data?.buyerMobileNo}
                     
                      </div>
                    </div>
                    <div className="delivery-add-block">
                      <div className="delivery-add-title">Seller address</div>
                      <div className="delivery-small-text">
                        {order?.shipmentDetails?.data?.sellerName}
                      </div>
                      <div className="delivery-add-text">
                        {order?.shipmentDetails?.data?.sellerAddress1},
                    { order?.shipmentDetails?.data?.sellerAddress2 &&<>   <br /> {order?.shipmentDetails?.data?.sellerAddress2}</>}
                        <br /> {order?.shipmentDetails?.data?.sellerCity}
                        <br /> {order?.shipmentDetails?.data?.sellerZipCode}
                        <br /> {order?.shipmentDetails?.data?.sellerCountryName}
                        <br />Contact no: {order?.shipmentDetails?.data?.sellerMobileNo}
                     
                      </div>
                    </div>
                    {/* <div className="order-total-block">
                      <div className="delivery-add-title order-total-btm">
                        Order total
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Platform fee</div>
                        <div className="order-total-col-r">
                          $ {order?.orderDetail?.data?.platformFee}
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Transaction fee</div>
                        <div className="order-total-col-r">
                          {" "}
                          $ {order?.orderDetail?.data?.transactionFee}
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Shipment fee</div>
                        <div className="order-total-col-r">
                          ${order?.orderDetail?.data?.shipmentAmount}
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">
                          Receivable amount
                        </div>
                        <div className="order-total-col-r">
                        
                          ${order?.orderDetail?.data?.sellerReceivableAmount}
                        
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Order total</div>
                        <div className="order-total-col-r">
                          ${" "}
                          {order?.orderDetail?.data?.orderTotal *
                            order?.orderDetail?.data?.quantity}
                        </div>
                      </div>
                      <div className="order-total-row">
                        <div className="order-total-col-l">Postage</div>
                        <div className="order-total-col-r">check</div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {( cancelShipment.visible=="block" || createShipment?.visible=="block")  &&   <div className="modal-backdrop show"></div>}

      <Footer />
    </DefaultLayout>
  );
};

export default OrderDetailShipment;
