import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getBuyerProfile } from "../../../../../modules/buyer/myEfindit/account/getBuyerProfile";
import { accountResponseSelector } from "../../../../../modules/buyer/myEfindit/account/selectors";
import { updateMobileNo } from "../../../../../modules/buyer/myEfindit/account/updateMobileNo";
import { updateProfileAddress } from "../../../../../modules/buyer/myEfindit/account/updateProfileAddress";
import { updateUserName } from "../../../../../modules/buyer/myEfindit/account/updateUserName";
import { getCountries } from "../../../../../modules/common/getCountries";
import { getStateByCountryCode } from "../../../../../modules/common/getStateByCountryCode";
import { CommonResponseSelector } from "../../../../../modules/common/selectors";
import { notify } from "../../../../../modules/notification/notify";
import { reset } from "../../../../../modules/buyer/myEfindit/account/reset";
import { constant } from "../../../../../utils/constant";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import SideNav from "../sideNav";
import BuyerFooter from "../../../buyerFooter";
import errorMsg from "../../../../../utils/errorMsg";
import { getSavedPayments } from "../../../../../modules/buyer/myEfindit/account/getSavedPayments";
import { removeSavedPayments } from "../../../../../modules/buyer/myEfindit/account/removeSavedPayments";

const Payments = () => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);




let accountResponse = useSelector<RootState, RootState["account"]>(
  accountResponseSelector
);



useEffect(()=>{

  dispatch<any>(getSavedPayments())
  isFirstRender.current=false
},[])

useEffect(() => {
  if (!isFirstRender.current) {
    if (accountResponse.saveData != null) {
      if (accountResponse.saveData.success === "Y") {
        notify(accountResponse.saveData.message, constant.SUCCESS);

      
        dispatch<any>(getSavedPayments());
      } else if (accountResponse.saveData.success === "N") {
        notify(accountResponse.saveData.message, constant.ERROR);
      }
     
    }
   
    dispatch<any>(reset());
    
  }
}, [accountResponse.saveData, accountResponse.errors]);
  return (
    <>
      <BuyerHeader />

      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">My eFindit</h1>

          <GroupTab currentPage="Account" />

          <section className="withFilter">
            <SideNav />

            <ToastContainer />

            {(
              <div className="right-wrapper">
                <section className="deals-group pt-0">
                  <div className="deal-group-list four-col bg-white mt-0">
                    <div className="right-wrap-title-block">
                      <div className="row align-items-center">
                        <div className="col-md-auto">
                          <div className="right-wrap-title">
                            Payments
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accountInfo">
                  { accountResponse?.payments?.data?.paymentTokens?.map((payment)=>   <div className="acountRow">
                        <div className="row">
                          <div className="col-lg-2">
                            <div className="title">{payment.paymentSource?.card?.brand} </div>
                            
                          </div>
                          <div className="col-lg-2 signForm">
                            { (
                              <p>{payment.paymentSource?.card?.name}</p>
                            )}
                            
                          </div>
                          <div className="col-lg-2 signForm">
                        
                              <p>XXXX XXXX XXXX {payment.paymentSource?.card?.lastDigits}</p>
                          
                            
                          </div>
                          <div className="col-lg-4 text-right">
                          <button
                                className="btn verify"
                                style={{width:100}}
                                onClick={() => dispatch<any>(removeSavedPayments(payment.id))}
                              >
                              
                                Delete card
                              </button>
                            
                          </div>
                          {/* <div className="col-lg-3 text-right">
                            {userNameInput.updateBtn && (
                              <button
                                className="btn verify"
                                onClick={() => handleUpdate("username")}
                              >
                                Update
                              </button>
                            )}
                            {userNameInput.editBtn && (
                              <button
                                className="btn edit"
                                onClick={() => showInput("username")}
                              >
                                Edit
                              </button>
                            )}
                            {userNameInput.cancelBtn && (
                              <button
                                className="btn edit"
                                onClick={() => handleCancel("username")}
                              >
                                Cancel
                              </button>
                            )}
                          </div> */}
                        </div>
                      </div>)}
                     
                          {
                            accountResponse?.payments?.data?.paymentTokens==null && <h1>No records found</h1>
                          }
                    
                    </div>
                  </div>
                </section>
              </div>
            )}
          </section>
        </div>
      </main>
      <BuyerFooter/>
    </>
  );
};

export default Payments;
