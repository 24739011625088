import {
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";

import { login } from "./modules/userLogin/slice";
import { register } from "./modules/userRegister/slice";
import { paypal } from "./modules/paypal/slice";

import { progress } from "./modules/progressBar/slice";

import { forgotpassword } from "./modules/forgetPassword/slice";
import { resetpassword } from "./modules/resetPassword/slice";
import { sales } from "./modules/sellerSales/slice";
import { mobileotp } from "./modules/mobileOtp/slice";
import { verifyotp } from "./modules/verifyOtp/slice";

import { dashboard } from "./modules/sellerDashboard/slice";
import { sellerTraffic } from "./modules/sellerTraffic/slice";
import { sellerProduct } from "./modules/sellerProduct/slice";

import { sellerStore } from "./modules/sellerStore/slice";
import { sellerOrders } from "./modules/orders/slice";
// import { products } from "./modules/products/slice";
// import { buyers } from "./modules/buyers/slice";
// import { manageUser } from "./modules/userManagement/slice";
import { common } from "./modules/common/slice";
// import { contactUser } from "./modules/contactUser/slice";
// import { webPages } from "./modules/websiteContent/slice";
// import { banners } from "./modules/banner/slice";
// import { offers } from "./modules/offer/slice";
import { socialMedias } from "./modules/socialMedia/slice";
import { confirmEmail } from "./modules/confirmEmail/slice";
import { categories } from "./modules/categories/slice";

import logger from "redux-logger";
import { RootState } from "./utils/types";
import { account } from "./modules/buyer/myEfindit/account/slice";
import { activity } from "./modules/buyer/myEfindit/activity/slice";
import { deals } from "./modules/buyer/deals/slice";
import { buyerProduct } from "./modules/buyer/product/slice";
import { homepage } from "./modules/buyer/homepage/slice";
import { messages } from "./modules/buyer/myEfindit/message/slice";
import { totalCartItems } from "./modules/cartItems/slice";

const middleware = [...getDefaultMiddleware(), logger];

const combinedReducer = combineReducers({
  login: login,
  register: register,
  progress: progress,
  forgotpassword: forgotpassword,
  resetpassword: resetpassword,
  dashboard: dashboard,
  sellerTraffic: sellerTraffic,
  sales: sales,
  mobileotp: mobileotp,
  verifyotp: verifyotp,
  categories: categories,
  common: common,
  sellerProduct: sellerProduct,
  socialMedias: socialMedias,
  sellerStore: sellerStore,
  paypal: paypal,
  confirmEmail: confirmEmail,
  sellerOrders: sellerOrders,
  account: account,
  activity: activity,
  deals: deals,
  messages: messages,
  buyerProduct: buyerProduct,
  homepage: homepage,
  totalCartItems: totalCartItems
});
const rootReducer = (state: any, action: any) => {
  if (action.type === "login/Reset") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
// export const store = configureStore({
//   reducer: {
//     login,
//     register,
//     progress,
//     forgotpassword,
//     resetpassword,
//     dashboard,
//     sellerTraffic,
//     sales,
//     mobileotp,
//     verifyotp,
//     categories,
//     common,
//     sellerProduct,
//     socialMedias,
//     sellerStore,
//     paypal,
//     confirmEmail,
//     sellerOrders,
//   },
//   middleware,
// });
export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
