import React, { useEffect, useRef, useState,CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../../components/footer/Footer'
import { DefaultLayout } from '../../../layout/DefaultLayout'
import { getCart } from '../../../modules/buyer/product/getCart'
import { buyerProductSelector } from '../../../modules/buyer/product/selectors'
import { RootState } from '../../../utils/types'
import "./checkout.scss";
import { Formik, Form } from "formik";

import BuyerHeader from '../BuyerHeader'
import { BASE_URLS } from '../../../utils/api.urls'
import DefaultImage from "../../../assets/images/defaultImage.png";
import { getBuyerProfile } from '../../../modules/buyer/myEfindit/account/getBuyerProfile'
import { accountResponseSelector } from '../../../modules/buyer/myEfindit/account/selectors'
import { useLocation, useNavigate } from 'react-router-dom'
import {  PayPalButtons,PayPalScriptProvider,
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  usePayPalHostedFields,
  BraintreePayPalButtons, } from "@paypal/react-paypal-js";
import { capturePayment, postOrder } from '../../../modules/orders/api'
import { getMerchatId } from '../../../modules/orders/getMerchatId'
import { SellerOrdersResponseSelector } from '../../../modules/orders/selectors'
import { errorMessage } from '../../../modules/notification/error'
import { resetOrder } from '../../../modules/orders/resetOrder'
import paypalImage from '../../../assets/images/paypal.png'
import creditCardLogo from '../../../assets/images/creditcard_logo.png'
import ClipLoader from "react-spinners/ClipLoader";
import { MoonLoader, PropagateLoader } from 'react-spinners'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from '../../../modules/notification/notify'
import { constant } from '../../../utils/constant'
import { getCheckout } from '../../../modules/buyer/product/getCheckout'
import { resetDetails } from '../../../modules/orders/slice'
import { resetDetail } from '../../../modules/buyer/product/resetDetail'
import { getSavedPayments } from '../../../modules/buyer/myEfindit/account/getSavedPayments'
import ShippingAddress from '../myEfindit/account/personalInfo/shippingAddress'
import { CommonResponseSelector } from '../../../modules/common/selectors'
import { getCountries } from '../../../modules/common/getCountries'
import errorMsg from '../../../utils/errorMsg'
import { reset } from '../../../modules/buyer/myEfindit/account/reset'
import { dateToLocal } from '../../../modules/date/date'
import moment from 'moment'

const CUSTOM_FIELD_STYLE = {
  "border-width": "1px",
  "border-color": "rgb(141, 141, 141)",
  "border-style": "solid",
  "border-radius": "3px",
  "background-color": "rgb(255, 255, 255)",
  "height": "45px",
  "font-size": "13px",
  "font-family": "var(--font-montserrat)",
  "color": "rgb(51, 51, 51)",
  "line-height": "1.2",
  "text-align": "left",
  "margin-bottom": "5px",
  "padding-left": "10px",
  "width": "100%"
};
const INVALID_COLOR = {
	color: "#dc3545",
};


const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


const SubmitPayment = ({cardHolderName, setErrorMessage, cart, newData,saveCard,isDeliverable}:any) => {
	const [paying, setPaying] = useState(false);
  const navigate = useNavigate();

	// const cardHolderName = useRef<any>(null);
	const hostedField = usePayPalHostedFields();

	const handleClick = () => {
    setErrorMessage("");
		if (!hostedField?.cardFields) {
            const childErrorMessage = 'Unable to find any child components in the <PayPalHostedFieldsProvider />';

            // action(ERROR)(childErrorMessage);
            throw new Error(childErrorMessage);
        }
		const isFormInvalid =
			Object.values(hostedField.cardFields.getState().fields).some(
				(field) => !field.isValid
			) || cardHolderName==""

		if (isFormInvalid) {
			return setErrorMessage("Invalid Details")
      
      // alert(
			// 	"The payment form is invalid"
			// );
		}
		setPaying(true);
    // paying.current=true

		hostedField.cardFields
			.submit({
				cardholderName: cardHolderName,
        contingencies: ['SCA_ALWAYS'],
        
			})
			.then((data) => {
				// Your logic to capture the transaction

        if (data.liabilityShift === "POSSIBLE") {
          // 3D Secure passed successfully
          capturePayment(data.orderId)
					.then((response) => 
          
         {
          
          setPaying(false);

          if(response.success==="N")
          {
           
            setErrorMessage(response.message)
          }
          if(response.success==="Y")
        {
          navigate("/buyer/confirmOrder", {
            state: { cart:cart , data:newData
            }})
        }
        
        }
          )
					// .then((data) => {
					// })
					.catch((err) => {
						// Here handle error
            notify("Something went wrong", constant.ERROR);
					})
					.finally(() => {
						setPaying(false);
    // paying.current=false

					});
     }
     else
   { 
     return    setErrorMessage("Authentication failed")}
     
		
			})
			.catch((err) => {
				// Here handle error
				setPaying(false);
    // paying.current=false

			}).finally(()=>{

        setPaying(false);
      });
	};

	return (
		<>
            {/* <label title="This represents the full name as shown in the card">
				Card Holder Name
				<input
					id="card-holder"
					ref={cardHolderName}
					className="card-field"
					style={{  outline: "none" }}
					type="text"
					placeholder="Full name"
				/>
				</label> */}
			{paying ?   <MoonLoader
        color={"red"}
        loading={paying}
        cssOverride={override}
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> : 	<button
				className={`btn${paying ? "" :" btn-primary"}`}
				onClick={handleClick}
        disabled={!isDeliverable}
			>
				Pay
			</button>}
		</>
	);
};

const Checkout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [paying, setPaying] = useState(false);

  let productResponse = useSelector<RootState, RootState["buyerProduct"]>(
    buyerProductSelector
  );

  let accountResponse = useSelector<RootState, RootState["account"]>(
    accountResponseSelector
  );


  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);

  // const [quantityDropdown, setQuantityDropdown] = useState<number[]>([]);
  const [itemTotal, setItemTotal] = useState(0);
  const [shippingTotal, setShippingTotal] = useState(0);

  const {  state }: any = useLocation();
  const [show, setShow] = useState(false);
  const [showAddNewAddress, setShowAddNewAddress] = useState(false);

    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(0);
    const [shippigAddresId, setshippigAddresId] = useState(0);
	const shippingId = useRef(0);

    const [orders, setOrders] = useState<any>([])
    const [isDeliverable, setIsDeliverable] = useState(false)

  const [paymentMethod, setPaymentMethod] = useState("card");

  const [saveCard, setSaveCard] = useState(false);
    const [cardHolderName, setCardHolderName]=useState("");

    const commonResponse = useSelector<RootState, RootState["common"]>(
      CommonResponseSelector
    );
    const [address, setAddress]=useState<any>(null);
    const [newShippingAddress, setNewShippingAddress] = useState({
      Id: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Address1: "",
      Address2: "",
      City: "",
      StateId: 0,
      CountryId: 0,
      ZipCode: "",
      CallingCode: 91,
      MobileNo: "",
    });
	const save = useRef<any>();
    const [initialOptions, setInitialOptions]=useState<any>(
    //   {
    //   components:"buttons,hosted-fields",
    //   "client-id": merchantId?.merchantId?.data?.clientId ? merchantId?.merchantId?.data?.clientId:"",
    // "currency": "USD",
    // intent: "capture",
    // "data-client-token": merchantId?.merchantId?.data?.token ? merchantId?.merchantId?.data?.token:"",
    // "data-merchant-id":""

    // }
    ""
    );
    // creates a paypal order
  

    // check Approval
 

    //capture likely error
    const onError = (data:any, actions:any) => {
        setErrorMessage("An Error occured with your payment ");
    };

    useEffect(() => {
        if (success) {
            alert("Payment successful!!");
        }
    },[success]); 

    const addShippingAddress = () => {
      setNewShippingAddress({
        Id: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Address1: "",
        Address2: "",
        City: "",
        StateId: 0,
        CountryId: 0,
        ZipCode: "",
        CallingCode: 91,
        MobileNo: "",
      });
      setShowAddNewAddress(!showAddNewAddress)
      // setShippingBtnTitle("Save");
      // showInput("shippingAddress");
    };

  useEffect(() => {
    if (isFirstRender.current) {
      
      dispatch<any>(getBuyerProfile());
      dispatch<any>(getSavedPayments())
      dispatch<any>(getCountries());
      dispatch<any>(resetDetail()).then(()=>dispatch<any>(getCheckout({...state, ShippingAddressId:null})));
    }

    
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (productResponse?.checkout) {
      let total = 0; let shipTotal=0;
let placeOrders:any=[], productIDs:any=[];
      // productResponse?.cart?.data?.forEach((product, Index)=>{
      //   placeOrders.push({
      //     productId: product.productId,
      //     quantity: product.quantity,
      //     VariantId:product.variantId
      //   })
      // })
      
      productResponse?.checkout?.data?.items?.forEach((item) => {
        total =
          total +
          (item.buyNowPrice
            ? item.buyNowPrice * item.quantity
            : item.actualPrice * item.quantity);
shipTotal=shipTotal+ ( item?.shippingCharges && item.shippingCharges[0]?.price? item.shippingCharges[0].price : 0)
            placeOrders.push({
              productId: item.productId,
              quantity:state?.type==="BuyItNow" ? state?.quantity : item.quantity,
              VariantId:item.variantId
            })
            productIDs.push(item.productId)
      });
      // if(merchantId?.merchantId==null)
      // dispatch<any>(getMerchatId({productIds:productIDs}));
      setItemTotal(total);
      setShippingTotal(shipTotal);

      setOrders(placeOrders);
      checkIsDeliverable();

    }
    // if (productResponse?.checkoutError) {
    //   setItemTotal(0);
    //   setShippingTotal(0);
    //   setOrders([])
    // }
  }, [productResponse?.checkout?.data?.items ]);
  useEffect(() => {
    if (accountResponse?.profile && isFirstRender2.current) {
      accountResponse?.profile?.value?.[0]?.Addresses?.forEach((address,Index)=> {

        if(address?.IsDefaultAddress && address?.Active==="Y" && address?.IsShipping 
        ) 
        {setshippigAddresId(address?.Id);
          shippingId.current=address?.Id;
        setAddress(address)}
      })
    isFirstRender2.current=false;
    }
  }, [accountResponse?.profile]);



        useEffect(()=>{
          if(productResponse?.checkout)
          {
          
  if(productResponse?.checkout?.data?.merchantIdsClientIdToken?.merchantId.length==1)
  {
    setInitialOptions({ components: "buttons,hosted-fields",
    "client-id": productResponse?.checkout?.data?.merchantIdsClientIdToken?.clientId ,
    "currency":"USD",
    "intent":"capture",
    "data-client-token": productResponse?.checkout?.data?.merchantIdsClientIdToken?.clientIdToken })
  }
  else
  setInitialOptions({ components: "buttons,hosted-fields",
  "client-id": productResponse?.checkout?.data?.merchantIdsClientIdToken?.clientId,
  "currency":"USD",
  "intent":"capture",
  "data-client-token": productResponse?.checkout?.data?.merchantIdsClientIdToken?.clientIdToken ,
  "data-merchant-id": productResponse?.checkout?.data?.merchantIdsClientIdToken?.merchantId.join(","),
})
          }

        },[productResponse?.checkout])


        const calculateItems=()=>{
          let total=0;
       
         
         { productResponse?.checkout?.data?.items?.forEach((item)=>{
      total=total+ item.quantity;
          })
          
        }
       
        
          return total
        }

      

const payBySavedCard=async (id:any)=>{
  setPaying(true);

  let datanew=				{
    // placeOrders: orders,
    productId: state.ProductId,
        quantity: state.quantity,
        variantId: state.VariantId,
        cartIds: state.CartIds,
    //     "shippigAddresId": 5077,
    SaveCard:false,
        fundingSource:"card",
        VaultId:id,
    shippigAddresId: shippingId.current,
    // fundingSource:data.paymentSource
    // Name:cardHolderName
  };
let result=await postOrder(datanew);
if(result?.success=="Y")
 {
  setPaying(false);
  navigate("/buyer/confirmOrder", {
    state: { cart:productResponse?.checkout?.data?.items, data:state
    }})}
    if(result?.success=="N")
 {
  setPaying(false);
  notify(result.message, constant?.ERROR)

  }
// .then((order) => {
//   setPaying(false);

//   navigate("/buyer/confirmOrder", {
//     state: { cart:productResponse?.checkout?.data?.items, data:state
//     }})
//    setSuccess(true);
// }).catch((err) => {
// alert(err);
// });
}


const handleAddressChange=(address:any)=>{
setAddress(address);
shippingId.current= address?.Id
setShow(false);
}

useEffect(()=>{
 if(shippingId?.current!==null) 
 dispatch<any>(getCheckout({...state, ShippingAddressId:shippingId.current}))
},[shippingId.current])

useEffect(() => {
  if (!isFirstRender.current) {
    if (accountResponse.saveData != null) {
      if (accountResponse.saveData.success === "Y") {
        notify(accountResponse.saveData.message, constant.SUCCESS);
          setShowAddNewAddress(false);
          setShow(true)
        dispatch<any>(getBuyerProfile());
      } else if (accountResponse.saveData.success === "N") {
        notify(accountResponse.saveData.message, constant.ERROR);
      }  
    }

    if (accountResponse.errors != null) {
      errorMsg(accountResponse.errors)       
    }
    dispatch<any>(reset());
  }
}, [accountResponse.saveData, accountResponse.errors]);



const checkIsDeliverable =()=>{
  
  let deliverable=true;
  productResponse?.checkout?.data?.items?.forEach((item)=>{
    if(item.estimatedDeliveryDateAndTime===null)
  {  deliverable=false;
    }
        })
      setIsDeliverable(deliverable)  
      
}
  return (
    <DefaultLayout>
  <ToastContainer/>
    <BuyerHeader />
    <main className="min-section">
    <div className="container">
      <section>
        <div className="row">
          <div className={`${initialOptions!=="" && shippingId.current!==0 ?"col-xl-9" :"col-xl-12" } && `}>
            <div className="myCart mt-5 checkoutSection">
              <h1>Shopping cart ( { state?.type=="BuyItNow" ? state?.quantity  : calculateItems()} )</h1>
          
              {initialOptions!=="" && shippingId.current!==0 && shippingId.current!==0 && productResponse?.checkout?.data?.deliverable && <PayPalScriptProvider 
                options={initialOptions }>
      <div className="cardSection">
   
<PayPalHostedFieldsProvider


					

                createOrder={async () => {
                    // Here define the call to create and order

                    let datanew=				{
                      // placeOrders: orders,
                      productId: state.ProductId,
                          quantity: state.quantity,
                          variantId: state.VariantId,
                          cartIds: state.CartIds,
                      //     "shippigAddresId": 5077,
                      SaveCard:save.current.checked,
                          fundingSource:"card",
                          VaultId:null,
                      shippigAddresId: shippingId.current,
                      // fundingSource:data.paymentSource
                      // Name:cardHolderName
                    };
        return await postOrder(datanew)
          // .then((response) => response.json())
          .then((order) => {
          if(order.success=="Y")
            {setOrderID(order.id);
            return order.id}
            if(order.success=="N")
            {notify(order.message, constant.ERROR);
            }
          }).catch((err) => {
            alert(err);
          });
      
                    // return fetch(
                    //     "/your-server-side-integration-endpoint/orders"
                    // )
                    //     .then((response) => response.json())
                    //     .then((order) => order.id)
                    //     .catch((err) => {
                    //         // Handle any error
                        // });
                }}

                // styles={{".valid":{"color":"#28a745"},".invalid":{"color":"#dc3545"},"input":{"font-family":"monospace","font-size":"16px"}}}
            >
           

         
                <h3>
                <input type="radio" id="card"  name="card"   value="card" 
                  onChange={()=>setPaymentMethod("card")}
                  checked={paymentMethod === "card" ? true : false}
                  />  
                  <label htmlFor="card" className="radio-label"><img src={creditCardLogo} alt=""/> Credit card or Debit card</label>
                </h3>
{
               <>
                <div className="cardNoInput mb-4">

                  <div className="row">
                    <div className="col-xl-5">

                      <label htmlFor="card-number">
                        Card number
                        <span style={INVALID_COLOR}>*</span>
                      </label>  
                      <PayPalHostedField
                          id="card-number"
                          hostedFieldType="number"
                          style={CUSTOM_FIELD_STYLE}
                          options={{ selector: "#card-number" }}
                      />

                    </div>
                    <div className="col-xl-5">

                      <label htmlFor="cvv">
                        CVV<span style={INVALID_COLOR}>*</span>
                      </label>
                      <PayPalHostedField
                          id="cvv"
                          hostedFieldType="cvv"
                          style={CUSTOM_FIELD_STYLE}
                          options={{ selector: "#cvv" ,  maskInput: true,}}
                      />

                    </div>

                    <div className="col-xl-5">

                      <label htmlFor="expiration-date">
                          Expiration date
                          <span style={INVALID_COLOR}>*</span>
                      </label>
                      <PayPalHostedField
                          id="expiration-date"
                          hostedFieldType="expirationDate"
                          style={CUSTOM_FIELD_STYLE}
                          options={{
                              selector: "#expiration-date",
                              placeholder: "MM/YY",
                          }}
                      />

                    </div>


                    <div className="col-xl-5">

                      <label htmlFor="card-holder">
                        Card holder name
                          <span style={INVALID_COLOR}>*</span>
                      </label>
                      <div>
                        <input id="card-holder" style={CUSTOM_FIELD_STYLE} type={"text"} value={cardHolderName} onChange={(e)=> { let check=/^[A-Za-z\s]*$/ ;
                         check.test(e.target.value) && setCardHolderName(e.target.value)}}/>
                      </div>  

                    </div>
                
                  </div>
               {paymentMethod==="card" &&   <div className="form-group pb-0 mb-0">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            name="isInternationalReturnAccepted"
                            // onChange={(e:any)=> setSaveCard(e.target.checked ? true : false)}
                            // checked={
                            //   saveCard
                            // }
                            ref={save}
                            className="custom-control-input"
                            id="internationReturn"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="internationReturn"
                          >
                            Save card
                          </label>
                        </div>
                      </div>}
                </div>
                
                <p className="errorMessagePayment">{ErrorMessage}</p>
               {paymentMethod==="card" && <div className="paymentButton">
                  <SubmitPayment cardHolderName={cardHolderName} setErrorMessage={setErrorMessage} isDeliverable={isDeliverable} saveCard={saveCard}  cart={productResponse?.checkout?.data?.items} newData={state}/>
                </div>  }
                </>   }     

                

            </PayPalHostedFieldsProvider>

      <hr className="break"/>

                    
      <h3>
      <input type="radio" id="paypal2"  name="paypal2"   value="paypal2" 
                  onChange={()=>setPaymentMethod("paypal")}
                  checked={paymentMethod === "paypal" ? true : false}
                  />
        <label htmlFor="paypal2" className="paypal-label">
        <PayPalButtons 
               
               disabled={paymentMethod==="paypal" && isDeliverable ?  false :true}
               createOrder={async () => {
                // Here define the call to create and order

                 let datanew=				{
      // placeOrders: orders,
      productId: state.ProductId,
          quantity: state.quantity,
          variantId: state.VariantId,
          cartIds: state.CartIds,
      //     "shippigAddresId": 5077,
          fundingSource:"paypal",
      shippigAddresId: shippingId.current,
      // fundingSource:data.paymentSource
      // Name:cardHolderName
    };

    return await postOrder(datanew)
      // .then((response) => response.json())
      .then((order) => {
      if(order.success=="Y")
        {
          setOrderID(order.id);
        return order.id
      }
        else if(order.success=="N")
        notify(order.message, constant.ERROR)
      }).catch((err) => {
        alert(err);
      });
  
                // return fetch(
                //     "/your-server-side-integration-endpoint/orders"
                // )
                //     .then((response) => response.json())
                //     .then((order) => order.id)
                //     .catch((err) => {
                //         // Handle any error
                    // });
            }}



            onApprove={async (data, actions)=> {
               
              return 	capturePayment(data.orderID)
                .then((response) =>  { if(response.success==="Y")
                {
                //  alert(response.message);
                navigate("/buyer/confirmOrder", {
                  state: { cart:productResponse?.checkout?.data?.items, data:state
                  }})
                 setSuccess(true);

                 
                }
                })
                .then((orderData:any) => {
                 
                 alert(orderData.message);
                 setSuccess(true);
                })
          }}
        
      
              
          
               />
               </label> 
               </h3> 
               {accountResponse?.payments?.data?.paymentTokens?.map((payments) =>  
           <> <hr className="break"/>


               <h3>
                <input type="radio" id={payments?.id}  name={payments?.id}   value={payments?.id} 
                  onChange={()=>setPaymentMethod(payments?.id)}
                  checked={paymentMethod === payments?.id ? true : false}
                  />
                  <label htmlFor={payments?.id} className="radio-label"><img src={creditCardLogo} alt=""/> XXXX XXXX XXXX {payments?.paymentSource?.card?.lastDigits} {paying && paymentMethod === payments?.id?   <MoonLoader
        color={"red"}
        loading={paying}
        cssOverride={override}
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> : paymentMethod === payments?.id ? 	<button
				className={`btn${paying ? "" :" btn-primary"}`}
        disabled={!isDeliverable}
				onClick={()=>payBySavedCard(payments?.id)}
    
			>
				Pay
			</button> :null}</label>
                </h3> 
                
                </>)}
                
                 </div>
                </PayPalScriptProvider> }
              
            {/* {merchantId?.merchantId?.data?.token  && <PayPalScriptProvider options={
     merchantId?.merchantId?.data?.merchantId.length==1 ? {
        components: "buttons,hosted-fields",
        "client-id": merchantId?.merchantId?.data?.clientId ,
        "currency":"USD",
        "intent":"capture",
				"data-client-token": merchantId?.merchantId?.data?.token ,
            // "merchant-id":"*",
        // "data-merchant-id":initialOptions,
        
        vault:false

          } : 
          {
            components: "buttons,hosted-fields",
        "client-id": merchantId?.merchantId?.data?.clientId ,
        "currency":"USD",
        "intent":"capture",
				"data-client-token": merchantId?.merchantId?.data?.token ,
            // "merchant-id":"*",
        "data-merchant-id":initialOptions,
          }
            
            }>
               <PayPalButtons style={{ layout: "horizontal" }} 
               
               
               createOrder={(data, actions) => {
                return actions.order.create({
                    purchase_units: [
                        {
                            amount: {
                                value: "1.99",
                            },
                        },
                    ],
                });
            }}
            onApprove={(data, actions:any) => {
                return actions.order.capture().then((details:any) => {
                    const name = details.payer.name.given_name;
                    alert(`Transaction completed by ${name}`);
                });
            }}
               />
              </PayPalScriptProvider>} */}

<div className="shipTo">
             <div className="title">Ship to</div>

             {/* {accountResponse?.profile?.value?.[0]?.Addresses?.map((address,Index)=> {
               
                 if(address?.IsDefaultAddress && address?.Active==="Y") 
               return   <div >
                <p className="addressText"> {`${address?.FirstName}  ${address?.LastName}`}<br/>{address?.Address1}<br/>
                  {address?.Address2}<br/>
                  
                 </p>

                  <p className="mobNo">{address?.MobileNo}</p>
               
              </div> })} */}
              {accountResponse?.profile && 
               
              
             <div >
         {address ?     <p className="addressText"> {`${address?.FirstName}  ${address?.LastName}`}<br/>
              {address?.Address1}
                                  { address?.Address2 && <><br />{address?.Address2}</> } <br/>
                                  {`${address?.City}, ${address?.State?.StateName}  ${address?.ZipCode}`} <br />
                                  {address?.Country?.CountryName} <br/>

               </p> : <p className="addressText">Please add an address</p>}
             
                <p className="mobNo">{address?.MobileNo}</p>
             
            </div> }
                  {/* <div className="btnBlock">
                    <div>
                      <button className="smallBtn"  onClick={()=> navigate("/buyer/myaccount/addresses", {
                        state: { isCheckout:true}})}>Change or Add new</button>
                    
                    </div>
                  </div> */}
                  <div className="btnBlock">
                    <div>
                     { showAddNewAddress ==false && <button className="smallBtn"  onClick={()=> setShow(!show)}> {show ? "Cancel" : "Change"} </button>}
                      {show==false && <button className="smallBtn" onClick={()=> addShippingAddress()}>{showAddNewAddress ? "Cancel" : "Add a new address"}</button>}
                     
                    </div>
                  </div>
          { showAddNewAddress &&
              <div className='accountInfo mt-5'>
               <ShippingAddress
                              shippingBtnTitle={"Save"}
                              address={newShippingAddress}
                              common={commonResponse}
                              setNewShippingAddress={setNewShippingAddress}
                            />
                
                </div>  } 
                  {show && accountResponse?.profile?.value?.[0]?.Addresses?.map((item,Index)=> {
               
               if(address?.Id!==item.Id && item?.Active==="Y" &&  item?.IsShipping) 
             return   <div className="btnBlock mt-5 border p-5"  >
              <p className="addressText"> {`${item?.FirstName}  ${item?.LastName}`}<br/>{item?.Address1}<br/>
                {item?.Address2}<br/>
                {item?.City}<br/>
                {item?.State?.StateName}<br/>
                {item?.Country?.CountryName}<br/>
               </p>

                <p className="mobNo">{item?.MobileNo}   <button className="smallBtn"  onClick={()=> handleAddressChange(item)}>Select </button></p>
             
            </div> })}
              </div>

              <div className="cartProducts">
            {productResponse?.checkout?.data?.items?.map((product, Index)=>   <div className="cartProductLists">
                  <div className="row align-items-center mb-5 mb-md-0">
                    <div className="col-md-auto">
                      <div className="sellerName">
                        <span>Seller</span>
                     <div style={{display:"flex", flexDirection:"row"}}>   <span style={{color:"#0000EE"}} className="mr-5">{product.storeName}</span>
                        <span className="mr-5" style={{cursor:"pointer", color:"#0000EE" , fontWeight:500}}  onClick={() => {
                                       navigate(
                                          "/buyer/contactSeller",
                                         {  state: {product:{sellerId:product?.sellerId,
                                              productId:product?.productId,
                                            
                                        }

                                          ,route:`/buyer/checkout/`,
                                        
                                        
                                          ProductId: state?.ProductId,
                                          quantity:state?.quantity,
                                        VariantId: state?.VariantId,
                                        type:state?.type,
                                        CartIds: state?.CartIds
                                        }},
                                          
                                        );
                                      }}>Message to seller</span>
                                      </div>
                      </div>
                    </div>
                  </div>

                  <div className="productCount">
                    <div className="img">
                    <img
                              src={
                                BASE_URLS.AWS_URL +
                                "/Thumb" +
                                product.defaultImage
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DefaultImage;
                              }}
                              alt=""
                            />
                    </div>
                    <div className="prodContext">
                      <div className="row">
                        <div className="col-lg-8">
                          <h3>{product.title}</h3>
                          <div className="brandNew">Condition:{product.condition}
                         
                      < div className='mt-2'>
                        {
                                  JSON.parse(product.variant)?.map((item:any)=>(
                                  <p> {`${item.AttName} : ${item.Option}`} </p>
                                  ))

                                 } </div> </div></div>
                        <div className="col-lg-12">
                          <div className="disPrice">US ${product.actualPrice}</div>
                          <div className="price">US ${product.buyNowPrice? product.buyNowPrice : product.actualPrice}</div>
                          
                        </div>
                        <div className="col-lg-12">
                          <div className="qty">
                            <div><label htmlFor="">QTY</label></div>
                            <div className="product-qty">{state?.type==="BuyItNow" ? state?.quantity :product.quantity}</div>
                          </div>
                         {product?.buyerMessage && <div className="qty">
                            {/* <div><label htmlFor="">QTY</label></div> */}
                            <div className="product-qty">Message for seller: {product?.buyerMessage}</div>
                          </div>}
                          <strong className="boldTxt">Delivery</strong>
                          <div className="text">
                              Estimated delivery: {product?.estimatedDeliveryDateAndTime  &&<span>{ moment(dateToLocal(product?.estimatedDeliveryDateAndTime)).format("DD MMM YYYY") }</span>}{product?.shippingAvailabilityText &&<span style={{color:"red"}}> {product?.shippingAvailabilityText}</span>} <br/>
                              {/* USPS First className Mail International / First className Package International Service */}
                          </div>
                         {product?.isShippingAvailable && <div className="packPrice">
                            US ${product.shippingCharges? product.shippingCharges?.[0].price : 0}
                          </div>}
                        </div>
                      </div>

                      {/* <div className="btnBlock">
                        <div className="saveupto">Save up to 30%</div>  
                        <div>
                      <button className="saveForLater">Save for later</button> 
                          <button className="remove">Remove</button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>)}

               

              </div>
            </div>
          </div>

       {initialOptions!=="" && shippingId.current!==0  &&    <div className="col-xl-3 mb-5">
            <div className="checkoutBlock mt-5">
              {/* <a href="" className="checkoutBtn">PAy</a> */}

              <div className="itemPrice">
                <div className="row">
                  <div className="col-6">
                    <p>Items ({state?.type=="BuyItNow" ? state?.quantity  :calculateItems()})</p>
                  </div>
                  <div className="col-6 text-right">
                    <p>US ${state?.type=="BuyItNow" ? state?.quantity * (productResponse?.checkout?.data?.items[0]?.buyNowPrice ?productResponse?.checkout?.data?.items[0]?.buyNowPrice :productResponse?.checkout?.data?.items[0]?.actualPrice  ) :itemTotal}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Shipping </p>
                  </div>
                  <div className="col-6 text-right">
                    <p>US $ {shippingTotal.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              <div className="subtotal">
                <div className="row">
                  <div className="col-6">
                    <p>Subtotal</p>
                  </div>
                  <div className="col-6 text-right">
                    <p>US ${state?.type=="BuyItNow" ?((productResponse?.checkout?.data?.items[0]?.shippingCharges  && productResponse?.checkout?.data?.items[0]?.shippingCharges[0]?.price )+ state?.quantity * (productResponse?.checkout?.data?.items[0]?.buyNowPrice ?productResponse?.checkout?.data?.items[0]?.buyNowPrice :productResponse?.checkout?.data?.items[0]?.actualPrice  )).toFixed(2) :(itemTotal+shippingTotal).toFixed(2)}</p>
                  </div>
                </div>
              </div>
              {/* <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                    /> */}
  
            </div>

            
          </div>}
        </div>
      </section>

    </div>
  </main>
  <Footer/>
   </DefaultLayout>
  )
}

export default Checkout