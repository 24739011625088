import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import { getPurchaseHistory } from "../../../../../modules/buyer/myEfindit/activity/getPurchaseHistory";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { dateToLocal } from "../../../../../modules/date/date";
import { notify } from "../../../../../modules/notification/notify";
import { BASE_URLS } from "../../../../../utils/api.urls";
import { constant } from "../../../../../utils/constant";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import ActivityNavPanel from "../ActivityNavPanel";

import "react-toastify/dist/ReactToastify.css";
import { getCommonData } from "../../../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../../../modules/common/selectors";
import OrderCancelModel from "../../../../seller/order/OrderCancelModel";
import { SellerOrdersResponseSelector } from "../../../../../modules/orders/selectors";
import { resetOrder } from "../../../../../modules/orders/resetOrder";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getOrderTracking } from "../../../../../modules/orders/getOrderTracking";
import moment from "moment";

const TrackOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const shipmentId: number = state;
  const isFirstRender2 = useRef(true);
  const isFirstRender = useRef(true);

  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );
  let commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );

  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );

  const [filter, setFilter] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
  const [typeCheck, setTypeCheck] = useState<any>("All");
  interface cancelValues {
    visible: string;
    orderId: string;
    reason: string | null;
    description: string | null;
  }

  const [cancelOrder, setCancelOrder] = useState<cancelValues>({
    visible: "none",
    orderId: "",
    reason: null,
    description: null,
  });

  if (activityResponse.purchaseHistory !== null && pageCount === 0) {
    setPageCount(
      Math.ceil(
        activityResponse?.purchaseHistory?.data?.totalResult / itemCount
      )
    );
  }

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  useEffect(() => {
    if (shipmentId!==undefined) {
      dispatch<any>(getOrderTracking({ shipmentId: shipmentId }));
    }
    
  }, [shipmentId]);
  // useEffect(() => {
  //   if (order.saveData != null) {
  //     if (order.saveData.success == "Y") {
  //       notify(order.saveData.message, constant.SUCCESS);
  //       dispatch<any>(
  //         getPurchaseHistory({
  //           currentPage: currentPage + 1,
  //           rows: itemCount,
  //           status: filter,
  //         })
  //       ).then(() => {
  //         if (currentPage === 0) setPageCount(0);
  //       });
  //     } else if (order.saveData.success == "N") {
  //       notify(order.saveData.message, constant.ERROR);
  //     }

  //     setCancelOrder({
  //       visible: "none",
  //       orderId: "",
  //       description: null,
  //       reason: null,
  //     });
  //   }
  //   if (order.saveDataError !== null) {
  //     notify(order.saveDataError, constant.ERROR);
  //   }
  //   dispatch<any>(resetOrder());
  // }, [order.saveData, order.saveDataError]);

  // useEffect(() => {
  //   if (!isFirstRender2.current) {
  //     dispatch<any>(
  //       getPurchaseHistory({
  //         currentPage: currentPage + 1,
  //         rows: itemCount,
  //         status: filter,
  //       })
  //     ).then(() => {
  //       if (currentPage === 0) setPageCount(0);
  //     });
  //   }
  //   isFirstRender2.current = false;
  // }, [currentPage, itemCount, filter]);
  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     dispatch<any>(
  //       getPurchaseHistory({
  //         currentPage: currentPage + 1,
  //         rows: itemCount,
  //         status: filter,
  //       })
  //     ).then(() => {
  //       if (currentPage === 0) setPageCount(0);
  //     });
  //   }
  //   commonResponse.common === null && dispatch<any>(getCommonData());
  //   isFirstRender.current = false;
  // }, []);
  return (
    <div>
      <DefaultLayout>
        <BuyerHeader />
        <ToastContainer />
        
        <main className="min-section">
          <div className="container">
            <h1 className="sectionTitle mt-5">My eFindit</h1>

            <GroupTab currentPage="Activity" />
            <section className="withFilter">
              <ActivityNavPanel currentPage="PurchaseHistory" />

              <div className="right-wrapper">
          <section className="deals-group pt-0">
            <div className="deal-group-list four-col bgLight mt-0">
              <div className="right-wrap-title-block">
                <div className="row align-items-center">
                  <div className="col-md-auto mb-2 mb-md-0">
                    <div className="right-wrap-title">Purchase history</div>
                  </div>
                  
                  <div className="col-md-auto ml-auto">
                    {/* <a href="" className="backBtn">Back to purchase history</a> */}
                    <Link className="backBtn" to="/buyer/activity/purchaseHistory">Back to purchase history</Link>
                  </div>
                </div>
              </div>
              <div className="historyTable">
                <div className="row trackDetail">
                  <div className="col-md-auto mr-md-5 mb-3 mb-md-0">
                    <strong>Tracking number</strong>
                    <span>{order?.trackingDetails?.trackingNumber}</span>
                  </div>
                  <div className="col-md-auto">
                    <strong>Expected delivery</strong>
                    <span>{order?.trackingDetails?.estimatedDeliveryDate && moment(dateToLocal(order?.trackingDetails?.estimatedDeliveryDate)).format("MMMM Do YYYY")}</span>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date & Time</th>
                        <th>Status of Item</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                    { order?.trackingDetails?.data?.trackings?.map((item)=> <tr>
                        <td>{moment(item.date).format("MMMM Do YYYY")}, { item.time}</td>
                        <td>{item.description}</td>
                        <td>{item.packageLocation[0]?.description}</td>
                      </tr>)}
                    </tbody>
                  </table>
                </div>
              </div>
  
           
            </div>
          </section>

        </div>
            </section>
          </div>
        </main>
      </DefaultLayout>
    </div>
  );
};

export default TrackOrder;
