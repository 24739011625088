import * as React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from "react-router-dom";
import RequireAuth from "./RequireAuth";
import { Login } from "../views/login/login";
import { Dashboard } from "../views/dashboard/dashboard";
import { Register } from "../views/login/register";
import ForgotPassword from "../views/forgotPassword/ForgotPassword";
import { ResetPass } from "../views/resetPass/resetPass";
import Listing from "../views/seller/listing/Listing";
import { Overview } from "../views/seller/overview/Overview";
import { LoginSeller } from "../views/login/loginSeller";
import RequireAuthSeller from "./RequireAuthSeller";

import { StoreInfo } from "../views/seller/onboarding/storeInfo/StoreInfo";
import { StoreAddress } from "../views/seller/onboarding/storeAddress/StoreAddress";
import { PaymentInfo } from "../views/seller/onboarding/paymentInfo/PaymentInfo";

import { EditProfile } from "../views/seller/editProfile/editProfile";
import { ConfirmEmail } from "../views/confirmEmail/confirmEmail";
import AllOrder from "../views/seller/order/AllOrder";
import AwaitingShipment from "../views/seller/order/AwaitingShipment";
import OrderPaidShipped from "../views/seller/order/OrderPaidShipped";
import ActiveListing from "../views/seller/listing/ActiveListing";
import EditListing from "../views/seller/listing/EditListing";
import UnsoldListing from "../views/seller/listing/UnsoldListing";
import DraftListing from "../views/seller/listing/DraftListing";
import ScheduledListing from "../views/seller/listing/ScheduledListing";
import EndedListing from "../views/seller/listing/EndedListing";
import Cancellation from "../views/seller/order/Cancellation";
import ReturnOrders from "../views/seller/order/ReturnOrders";
import ContactBuyer from "../views/seller/order/ContactBuyer";
import OrderDetail from "../views/seller/order/orderDetail";
import CancelOrderDetail from "../views/seller/order/CancelOrderDetail";
import DeliveredOrder from "../views/seller/order/DeliveredOrder";
import ReportBuyer from "../views/seller/order/ReportBuyer";
import ReturnRequest from "../views/seller/order/returnRequest";
import ConfirmReturnRequest from "../views/seller/order/confirmReturnRequest";
import SoldListing from "../views/seller/listing/SoldListing";
import { StoreFeedback } from "../views/seller/storeFeedback/storeFeedback";
import BuyerHeader from "../views/buyer/BuyerHeader";
import PersonalInformation from "../views/buyer/myEfindit/account/personalInfo/personalInformation";
import Security from "../views/buyer/myEfindit/account/personalInfo/security";
import Addresses from "../views/buyer/myEfindit/account/personalInfo/addresses";
import AllMessages from "../views/buyer/myEfindit/messages/allmessages";
import BidsOffers from "../views/buyer/myEfindit/activity/bidsOffers/BidsOffers";
import DailyDeal from "../views/buyer/deals/DailyDeal";
import PurchaseHistory from "../views/buyer/myEfindit/activity/purchaseHistory/PurchaseHistory";
import RecentlyViewed from "../views/buyer/myEfindit/activity/recentlyViewed/RecentlyViewed";
import RequireAuthBuyer from "./RequireAuthBuyer";
import BuyAgain from "../views/buyer/myEfindit/activity/buyAgain/BuyAgain";
import Inbox from "../views/buyer/myEfindit/messages/inbox";
import EfindIt from "../views/buyer/myEfindit/messages/efindIt";
import LeaveFeedback from "../views/buyer/leaveFeedback/LeaveFeedback";
import Watchlist from "../views/buyer/myEfindit/activity/watchlist/Watchlist";
import Details from "../views/buyer/myEfindit/messages/details";
import SavedSellers from "../views/buyer/myEfindit/activity/savedSellers/SavedSellers";
import SearchResult from "../views/buyer/searchResult/SearchResult";
import ProductCategory from "../views/buyer/productCategory/ProductCategory";
import ProductDetail from "../views/buyer/productDetail/ProductDetail";
import MyCart from "../views/buyer/myCart/MyCart";
import { Home } from "../views/home/home";
import Deal from "../views/buyer/deals/Deal";
import Checkout from "../views/buyer/checkout/Checkout";
import SavedSearches from "../views/buyer/myEfindit/activity/savedSearches/SavedSearches";
import { Bidding } from "../views/seller/bidding/Bidding";
import ConfirmOrder from "../views/buyer/checkout/ConfirmOrder";
import ProductReviews from "../views/buyer/productReview/ProductReviews";
import SellerStorePage from "../views/buyer/searchResult/SellerStorePage";
import TrackOrder from "../views/buyer/myEfindit/activity/trackOrder/TrackOrder";
import Print from "../views/buyer/myEfindit/messages/Print";
import ContactSeller from "../views/buyer/contactSeller/ContactSeller";
import ReportItem from "../views/buyer/reportItem/ReportItem";
import OfferSeller from "../views/seller/offers/OffersSeller";
import BidsSeller from "../views/seller/bids/BidsSeller";
import DealsSeller from "../views/seller/deals/DealsSeller";
import AddNewProduct from "../views/seller/deals/AddNewProduct";
import Payments from "../views/buyer/myEfindit/account/payment/Payments";
import PrintOrders from "../views/seller/print/PrintOrders";
import PrintOrdersCancelled from "../views/seller/print/PrintOrdersCancelled";
import PrintOrdersReturned from "../views/seller/print/PrintOrdersReturned";
import PrintAllListing from "../views/seller/print/PrintAllListing";
import PrintUnsoldListing from "../views/seller/print/PrintUnsoldListing";
import PrintDraftListing from "../views/seller/print/PrintDraftListing";
import PrintScheduledListing from "../views/seller/print/PrintScheduledListing";
import PrintEndedListing from "../views/seller/print/PrintEndedListing";
import PaymentSeller from "../views/seller/payments/PaymentSeller";
import PrintPayments from "../views/seller/print/PrintPayments";
import OrderDetailBuyer from "../views/buyer/myEfindit/activity/purchaseHistory/OrderDetailBuyer";
import OrderDetailShipment from "../views/seller/order/OrderDetailShipment";
import ReturnDetailBuyer from "../views/buyer/myEfindit/activity/purchaseHistory/ReturnDetailBuyer";
import { ActivationUser } from "../views/login/ActivationUser";
import { PrivacyPolicy } from "../views/common/PrivacyPolicy";
import { AboutUs } from "../views/common/AboutUs";
import { TermsAndConditions } from "../views/common/TermsAndConditions";

const Routes = (props: any) => {
  return (
    <Router>
      <Switch>
        <Route path="/login" element={<Login />} />
        <Route path="/login-seller" element={<LoginSeller />} />

        <Route
          path="/"
          element={
            <RequireAuthSeller>
              <Overview />
            </RequireAuthSeller>
          }
        />

        <Route path="/register" element={<Register />} />

        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/reset-password/reset-pass" element={<ResetPass />} />

        <Route
          path="/seller/createListing"
          element={
            <RequireAuthSeller>
              <Listing />
            </RequireAuthSeller>
          }
        />

        <Route
          path="/seller/bidding"
          element={
            <RequireAuthSeller>
              <Bidding />
            </RequireAuthSeller>
          }
        />        

        <Route
          path="/seller/editListing"
          element={
            <RequireAuthSeller>
              <EditListing />
            </RequireAuthSeller>
          }
        />

        <Route
          path="/seller/listing"
          element={
            <RequireAuthSeller>
              <ActiveListing />
            </RequireAuthSeller>
          }
        />

        <Route
          path="/seller/unsoldListing"
          element={
            <RequireAuthSeller>
              <UnsoldListing />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/draftListing"
          element={
            <RequireAuthSeller>
              <DraftListing />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/scheduledListing"
          element={
            <RequireAuthSeller>
              <ScheduledListing />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/endedListing"
          element={
            <RequireAuthSeller>
              <EndedListing />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/edit-profile"
          element={
            <RequireAuthSeller>
              <EditProfile />
            </RequireAuthSeller>
          }
        />

        <Route
          path="/seller/store-info"
          element={
            <RequireAuthSeller>
              <StoreInfo />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/store-add"
          element={
            <RequireAuthSeller>
              <StoreAddress />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/payment-info"
          element={
            <>
              <PaymentInfo />
            </>
          }
        />

        <Route
          path="/seller/overview"
          element={
            <RequireAuthSeller>
              <Overview />
            </RequireAuthSeller>
          }
        />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/seller/orders" element={<AllOrder />} />
        <Route
          path="/seller/awaitingShip"
          element={
            <RequireAuthSeller>
              <AwaitingShipment />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/orderPaidShipped"
          element={
            <RequireAuthSeller>
              <OrderPaidShipped />
            </RequireAuthSeller>
          }
        />

        <Route
          path="/seller/orderCancellation"
          element={
            <RequireAuthSeller>
              <Cancellation />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/orderReturn"
          element={
            <RequireAuthSeller>
              <ReturnOrders />
            </RequireAuthSeller>
          }
        />
          <Route
          path="/seller/printOrders"
          element={
            <RequireAuthSeller>
              <PrintOrders />
            </RequireAuthSeller>
          }
        />
           <Route
          path="/seller/printCancelledOrders"
          element={
            <RequireAuthSeller>
              <PrintOrdersCancelled />
            </RequireAuthSeller>
          }
        />
          <Route
          path="/seller/printReturnOrders"
          element={
            <RequireAuthSeller>
              <PrintOrdersReturned />
            </RequireAuthSeller>
          }
        />

<Route
          path="/seller/printAllListing"
          element={
            <RequireAuthSeller>
              <PrintAllListing />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/printPayments"
          element={
            <RequireAuthSeller>
              <PrintPayments />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/printUnsoldListing"
          element={
            <RequireAuthSeller>
              <PrintUnsoldListing />
            </RequireAuthSeller>
          }
        />
         <Route
          path="/seller/printDraftListing"
          element={
            <RequireAuthSeller>
              <PrintDraftListing />
            </RequireAuthSeller>
          }
        />
         <Route
          path="/seller/printScheduledListing"
          element={
            <RequireAuthSeller>
              <PrintScheduledListing />
            </RequireAuthSeller>
          }
        />
          <Route
          path="/seller/printEndedListing"
          element={
            <RequireAuthSeller>
              <PrintEndedListing />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/order/contactBuyer"
          element={
            <RequireAuthSeller>
              <ContactBuyer />
            </RequireAuthSeller>
          }
        />

        <Route
          path="/seller/orderDetail"
          element={
            <RequireAuthSeller>
              <OrderDetail />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/cancelOrderDetail"
          element={
            <RequireAuthSeller>
              <CancelOrderDetail />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/deliveredOrder"
          element={
            <RequireAuthSeller>
              <DeliveredOrder />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/reportBuyer"
          element={
            <RequireAuthSeller>
              <ReportBuyer />
            </RequireAuthSeller>
          }
        />

        <Route
          path="/seller/returnRequest"
          element={
            <RequireAuthSeller>
              <ReturnRequest />
            </RequireAuthSeller>
          }
        />

        <Route
          path="/seller/confirmReturnRequest"
          element={
            <RequireAuthSeller>
              <ConfirmReturnRequest />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/soldListing"
          element={
            <RequireAuthSeller>
              <SoldListing />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/storeFeedback"
          element={
            <RequireAuthSeller>
              <StoreFeedback />
            </RequireAuthSeller>
          }
        />
 <Route
          path="/seller/offers"
          element={
            <RequireAuthSeller>
              <OfferSeller />
            </RequireAuthSeller>
          }
        />
         <Route
          path="/seller/orderDetailsShipment"
          element={
            <RequireAuthSeller>
              <OrderDetailShipment />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/bids"
          element={
            <RequireAuthSeller>
              <BidsSeller />
            </RequireAuthSeller>
          }
        />

<Route
          path="/seller/deals/:dealId?"
          element={
            <RequireAuthSeller>
              <DealsSeller />
            </RequireAuthSeller>
          }
        />
        <Route
          path="/seller/addProductInDeal"
          element={
            <RequireAuthSeller>
              <AddNewProduct />
            </RequireAuthSeller>
          }
        />
           <Route
          path="/seller/payments"
          element={
            <RequireAuthSeller>
              <PaymentSeller />
            </RequireAuthSeller>
          }
        />
        {/* -------------------------- buyer routes  ------------*/}

        <Route path="/buyer/myaccount" element={<RequireAuth><PersonalInformation /></RequireAuth>} />
        <Route path="/buyer/myaccount/security" element={<RequireAuth><Security /></RequireAuth>} />
        <Route path="/buyer/myaccount/addresses" element={<RequireAuthBuyer><Addresses /></RequireAuthBuyer>} />
        <Route path="/buyer/messages/inbox" element={<RequireAuthBuyer><Inbox /></RequireAuthBuyer>} />
        <Route path="/buyer/messages/all" element={<RequireAuthBuyer><AllMessages /></RequireAuthBuyer>} />
        <Route path="/buyer/messages/efindit" element={<RequireAuthBuyer><EfindIt /></RequireAuthBuyer>} />
        <Route path="/buyer/messages/details" element={<RequireAuthBuyer><Details /></RequireAuthBuyer>} />
        <Route
          path="/buyer/myaccount"
          element={
            <RequireAuthBuyer>
              <PersonalInformation />
            </RequireAuthBuyer>
          }
        />
           <Route
          path="/buyer/myaccount/payments"
          element={
            <RequireAuthBuyer>
              <Payments />
            </RequireAuthBuyer>
          }
        />
        <Route
          path="/buyer/myaccount/security"
          element={
            <RequireAuthBuyer>
              <Security />
            </RequireAuthBuyer>
          }
        />
        <Route
          path="/buyer/myaccount/addresses"
          element={
            <RequireAuthBuyer>
              <Addresses />
            </RequireAuthBuyer>
          }
        />

        <Route path="/BuyerHead" element={<BuyerHeader />} />
        <Route
          path="/buyer/activity/bidsOffers"
          element={
            <RequireAuthBuyer>
              <BidsOffers />
            </RequireAuthBuyer>
          }
        />
        <Route path="/buyer/dailyDeal" element={<DailyDeal />} />
        <Route
          path="/buyer/activity/purchaseHistory"
          element={
            <RequireAuthBuyer>
              <PurchaseHistory />
            </RequireAuthBuyer>
          }
        />
          <Route
          path="/buyer/activity/trackOrder"
          element={
            <RequireAuthBuyer>
              <TrackOrder />
            </RequireAuthBuyer>
          }
        />
        <Route
          path="/buyer/activity/recentlyViewed"
          element={
            <RequireAuthBuyer>
              <RecentlyViewed />
            </RequireAuthBuyer>
          }
        />
        <Route
          path="/buyer/activity/buyAgain"
          element={
            <RequireAuthBuyer>
              <BuyAgain />
            </RequireAuthBuyer>
          }
        />
        <Route
          path="/buyer/activity/leaveFeedback"
          element={
            <RequireAuthBuyer>
              <LeaveFeedback />
            </RequireAuthBuyer>
          }
        />
        <Route
          path="/buyer/productReviews"
          element={
            
              <ProductReviews />
         
          }
        />
        <Route
          path="/buyer/activity/watchlist"
          element={
            <RequireAuthBuyer>
              <Watchlist />
            </RequireAuthBuyer>
          }
        />
        <Route
          path="/buyer/activity/savedSellers"
          element={
            <RequireAuthBuyer>
              <SavedSellers />
            </RequireAuthBuyer>
          }
        />
          <Route
          path="/buyer/checkout"
          element={
            <RequireAuthBuyer>
              <Checkout />
              </RequireAuthBuyer>
          }
          />
          <Route
          path="/buyer/confirmOrder"
          element={
            <RequireAuthBuyer>
              <ConfirmOrder />
              </RequireAuthBuyer>
          }
          />
        <Route
          path="/buyer/activity/savedSearches"
          element={
            <RequireAuthBuyer>
              <SavedSearches />
            </RequireAuthBuyer>
          }
        />
        <Route path="/buyer/searchResult" element={<SearchResult />} />
        <Route
          path="/buyer/productCategory/:Id/:name"
          element={<ProductCategory />}
        />
        <Route path="/buyer/productDetail/:Id/:variId?/:date?/" element={<ProductDetail />} />
        <Route path="/buyer/myCart" element={<MyCart />} />
        <Route path="/buyer/deal/:Id/:name" element={<Deal />} />
        <Route path="/buyer/sellerStore/:Id/:categoryId?" element={<SellerStorePage />} />
        <Route
          path="/buyer/messages/print"
          element={
            <RequireAuthBuyer>
              <Print />
            </RequireAuthBuyer>
          }
        />
        {/* ---------------- Home routes  ------------*/}

        <Route path="/home" element={<Home />} />
        <Route
          path="/buyer/contactSeller"
          element={
            <RequireAuthBuyer>
              <ContactSeller />
              </RequireAuthBuyer>
          }
          />
<Route
          path="/buyer/reportItem"
          element={
            <RequireAuthBuyer>
              <ReportItem />
              </RequireAuthBuyer>
          }
          />
          <Route
          path="/buyer/orderDetail"
          element={
            <RequireAuthBuyer>
              <OrderDetailBuyer />
              </RequireAuthBuyer>
          }
          />
           <Route
          path="/buyer/returnDetail"
          element={
            <RequireAuthBuyer>
              <ReturnDetailBuyer />
              </RequireAuthBuyer>
          }
          />
            <Route
          path="/activation"
          element={
              <ActivationUser />
          }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/about-us" element={<AboutUs />} />
      </Switch>
    </Router>
  );
};
export default Routes;
