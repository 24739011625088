import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../../components/header/Header";
import { postContactBuyer } from "../../../modules/orders/postContactBuyer";

const ContactBuyer = ({ contactBuyer, setContactBuyer }: any) => {
  const dispatch = useDispatch();
  const [error, setError]=useState("")
 
useEffect(()=>{
setError("")
},[contactBuyer?.visible])
  const handleSubmit =()=>{
    if(contactBuyer.message=="")
    {
      setError("*Required!")
    } 
    else{
      dispatch<any>(postContactBuyer(contactBuyer));
                  setContactBuyer({ ...contactBuyer, visible: "none" });
    }
      }
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: contactBuyer.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Contact buyer</div>

            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Write to buyer </label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="write some thing"
                value={contactBuyer.message}
                onChange={(e) =>
                 { setContactBuyer({
                    ...contactBuyer,
                    message: e.target.value,
                  });
                  setError("");
                }
                }
              ></textarea>
              <p>{contactBuyer.message.length}/500</p>
              <p style={{color:"red", fontSize:12}}>
                {error && error}
               
              </p>
            </div>
            <div className="buyer-checkbox-items">
              <p>
                <input
                  type="checkbox"
                  id="checkbox4"
                  checked={contactBuyer.sendCopy}
                  onChange={(e: any) =>
                    setContactBuyer({
                      ...contactBuyer,
                      sendCopy: e.target.checked,
                    })
                  }
                  name="radio-group"
                />
                <label htmlFor="checkbox4">
                  Send a copy to my email address
                </label>
              </p>
            </div>
            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn"
                onClick={() => {

                  handleSubmit()
                
                }}
                // disabled={contactBuyer.message === "" ? true : false}
              >
                Submit
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() =>
                  setContactBuyer({ ...contactBuyer, visible: "none" })
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBuyer;
