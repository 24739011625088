import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import BuyerHeader from "../../../BuyerHeader";
import { RootState } from "../../../../../utils/types";
import { SellerOrdersResponseSelector } from "../../../../../modules/orders/selectors";
import { API_URLS, BASE_URLS } from "../../../../../utils/api.urls";
import BuyerFooter from "../../../buyerFooter";
import { resetActivity } from "../../../../../modules/buyer/myEfindit/activity/resetActivity";
import { getOrderDetailBuyer } from "../../../../../modules/buyer/myEfindit/activity/getOrderDetailBuyer";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { dateToLocal } from "../../../../../modules/date/date";
import axios from "axios";
import { getCourierServiceDetails } from "../../../../../modules/sellerProduct/getCourierServiceDetails";
import { SellerProductResponseSelector } from "../../../../../modules/sellerProduct/selector";
import { resetOrder } from "../../../../../modules/orders/resetOrder";
import { notify } from "../../../../../modules/notification/notify";
import { constant } from "../../../../../utils/constant";
import { buyerReturnLabel } from "../../../../../modules/buyer/myEfindit/activity/buyerReturnLabel";
import { getRetunDetailBuyer } from "../../../../../modules/buyer/myEfindit/activity/getReturnDetailBuyer";
import moment from "moment";
import DefaultImage from "../../../../../assets/images/defaultImage.png"
import { activity } from "../../../../../modules/buyer/myEfindit/activity/slice";
// import Header from "../../../components/header/Header";
// import { DefaultLayout } from "../../../layout/DefaultLayout";
// import { RootState } from "../../../utils/types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCourierCharges } from "../../../../../modules/buyer/myEfindit/activity/getCourierCharges";

// import { getOrderDetail } from "../../../modules/orders/getOrderDetail";
// import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
// import { BASE_URLS } from "../../../utils/api.urls";
// import ContactBuyer from "./ContactBuyer";
// import OrderFeedback from "./OrderFeedback";
// import { CommonResponseSelector } from "../../../modules/common/selectors";
// import { notify } from "../../../modules/notification/notify";
// import { constant } from "../../../utils/constant";
// import { resetOrder } from "../../../modules/orders/resetOrder";
// import "react-toastify/dist/ReactToastify.css";
// import { getCommonData } from "../../../modules/common/getCommonData";
// import Footer from "../../../components/footer/Footer";

const ReturnDetailBuyer = () => {
  const { state } = useLocation();
  const returnId: any = state;
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );
  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );


  const sellerProductResponse = useSelector<
    RootState,
    RootState["sellerProduct"]
  >(SellerProductResponseSelector);

  const [courierService, setCourierService]=useState('');
  const [pickupRadio, setpickupRadio]=useState('true');

  // const commonResponse = useSelector<RootState, RootState["common"]>(
  //   CommonResponseSelector
  // );

  useEffect(() => {
    if (activityResponse.saveDataSeller != null) {
      if (activityResponse.saveDataSeller.success == "Y") {
        notify(activityResponse.saveDataSeller.message, constant.SUCCESS);
        dispatch<any>(getRetunDetailBuyer( returnId))
      } else if (activityResponse.saveDataSeller.success == "N") {
        notify(activityResponse.saveDataSeller.message, constant.ERROR);
      }

     
    }
  
    if (activityResponse.saveDataError !== null) {
      notify(activityResponse.saveDataError, constant.ERROR);
    }

    
    dispatch<any>(resetActivity("sellerFeedback"));
  }, [activityResponse.saveDataSeller, activityResponse.saveDataError]);

  useEffect(() => {
    if (state ) {
      dispatch<any>(resetActivity("sellerFeedback")).then(()=> dispatch<any>(resetActivity("orderDetails"))).then(
        dispatch<any>(getRetunDetailBuyer( returnId))
      );
      dispatch<any>(getCourierServiceDetails(returnId));
      // if (commonResponse?.common === null) dispatch<any>(getCommonData());
    }
    // isFirstRender.current = false;
    // dispatch<any>(getCourierServiceDetails());

  }, [state]);

  
  const onCourierChange=(e:any)=>{
    setCourierService(e.target.value)
    e.target.value!=="" && dispatch<any>(getCourierCharges({returnId:returnId, courierServiceId:e.target.value}))

  }
  const handleSubmit =()=>{
  const now = new Date();

    const timezoneOffsetMinutes = now.getTimezoneOffset();
   const timezone= timezoneOffsetMinutes < 0 ? timezoneOffsetMinutes*-1 : timezoneOffsetMinutes;

    dispatch<any>(buyerReturnLabel({

      courierServiceId: courierService,
    returnId: returnId,
    timezone: timezone,
    IsPickUpRequired: pickupRadio==="true" ?true :false,
    }))
  }


  const convertAndDownloadPDF = (content:string,name:string) => {
    // Replace 'base64String' with your actual base64 string
    const base64String = content;

    // Decode the base64 string into a Uint8Array
    const bytes = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = url;
    a.download = name+'.pdf';

    // Trigger a click event on the anchor element to initiate the download
    a.click();

    // Clean up by revoking the URL object
    URL.revokeObjectURL(url);
  };


  const printPDF = (content:string) => {
    // Replace 'base64String' with your actual base64 string
    const base64String = content;

    // Decode the base64 string into a Uint8Array
    const bytes = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open a new window with the PDF for printing
    const printWindow:any = window.open(url);
    
    // Wait for the PDF to load and then trigger the print dialog
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        // Clean up by closing the print window and revoking the URL object
        printWindow.close();
        URL.revokeObjectURL(url);
      };
    };
  };
  // useEffect(() => {
  //   if (order.saveData != null) {
  //     if (order.saveData.success == "Y") {
  //       notify(order.saveData.message, constant.SUCCESS);
  //     } else if (order.saveData.success == "N") {
  //       notify(order.saveData.message, constant.ERROR);
  //     } else if (order.saveDataError !== null)
  //       notify("PreDefinedMessage or Message is required", constant.ERROR);
  //     dispatch<any>(resetOrder());
  //     setFeedback({
  //       visible: "none",
  //       orderId: "",
  //       definedMessage: null,
  //       message: "",
  //     });

  //     setContactBuyer({
  //       visible: "none",
  //       orderId: "",
  //       message: "",
  //       sendCopy: false,
  //     });
  //   }
  // }, [order.saveData, order.saveDataError]);
  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />
      {/* <ContactBuyer
        contactBuyer={contactBuyer}
        setContactBuyer={setContactBuyer}
      />

      <OrderFeedback
        feedback={feedback}
        setFeedback={setFeedback}
        commonResponse={commonResponse}
      /> */}
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"/"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/buyer/activity/purchaseHistory"}>purchase history</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">Return Detail</li>
          </ul>
        </div>
      </section>

      <main className="min-section">
    <div className="container">
      <section>
        <div className="row">
          <div className="col-xl-9">
            <div className="report mt-5">
              <h1>Return Detail</h1>
             
            <div className="prodInfo prod-info-block d-flex">
                {/* <div className="title">
                 
               {activityResponse?.returnDetailsBuyer?.data?.title}</div> */}
              
               <div className="return-detail-left">
                <div className="title ml-4 pb-2" style={{fontSize:20}}>
                  {activityResponse?.returnDetailsBuyer?.data?.title}
                </div>
                
                <div className="info">
                  <div className="row">
                   
                    <div className="col-auto">
                      <div className="p-img ml-4" >
                        <img 
                         src={BASE_URLS.AWS_URL + "/Thumb" + activityResponse?.returnDetailsBuyer?.data?.defaultImage}

                         
                         onError={({ currentTarget }) => {
                           currentTarget.onerror = null; // prevents looping
                           currentTarget.src = DefaultImage;
                         }}
                         alt=""
                        />
                      </div>
                    </div>
               
                  <div className="col-auto">
                  {activityResponse?.returnDetailsBuyer?.data.variant &&
                                  JSON.parse(activityResponse?.returnDetailsBuyer?.data.variant)?.map((product:any)=>(
                                  <p  style={{fontSize:16}}> {`${product.AttName} : ${product.Option}`} </p>
                                  ))

                                 }
                          
                                 </div>
                    {/* <div className="col-auto">
                      <label htmlFor="">Pickup date</label>
                      <p>{moment(activityResponse?.returnDetailsBuyer?.data?.pickupDate).format("MMMM Do YYYY")}</p>
                    </div>
                    <div className="col-auto">
                      <label htmlFor="">Pickup earliest</label>
                      <p>{activityResponse?.returnDetailsBuyer?.data?.pickupEarliest}</p>
                    </div>
                    <div className="col-auto">
                      <label htmlFor="">Pickup Latest</label>
                      <p>{activityResponse?.returnDetailsBuyer?.data?.pickupLatest}</p>
                    </div> */}
                  </div>
                  <div>
                  <p className=" order-item-label ml-4 mt-4"> Note: Please return your item to our nearest drop-off location  </p>

                  </div>
                 
                </div>
                </div>
                   
           {   activityResponse?.returnDetailsBuyer?.data?.billDocContent &&    <div className="order-estimate-product order-estimate-buttons" style={{marginTop:10}}>
                      <div className="table-order-detail">
                          <div className="order-detail-img-content " >
                            <div className="order-detail-img-l">
                           
                            </div>
                            
                         {/* {activityResponse?.returnDetailsBuyer?.data?.pickupDate &&   <div className="order-detail-content-m mt-5">
                            
                             
                              <p className="order-product-text "  style={{fontSize:16}}>
                              <span className="order-item-label"  style={{fontSize:16}}> Pickup date :</span>{" "}
                                { moment(activityResponse?.returnDetailsBuyer?.data?.pickupDate ).format("MMMM Do YYYY")} 
                              </p>
                              <table className="order-item-table">
                                <tr>
                                  <td style={{paddingRight:10}}>
                                    <label className="order-item-label"  style={{fontSize:16}}>
                                    Pickup earliest
                                    </label>
                                    <p className="order-item-text" style={{fontSize:16}}>{activityResponse?.returnDetailsBuyer?.data?.pickupEarliest}</p>
                                  </td>
                                  <td style={{paddingRight:10}}>
                                    <label className="order-item-label" style={{fontSize:16}}>
                                    Pickup Latest
                                    </label>
                                    <p className="order-item-text" style={{fontSize:16}}>
                                      {activityResponse?.returnDetailsBuyer?.data?.pickupLatest}
                                    </p>
                                  </td>
                                  
                                </tr>
                              </table>
                          
                 
                            </div>} */}
                            <div>
                              <div className="order-button-block">
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                  convertAndDownloadPDF(activityResponse?.returnDetailsBuyer?.data?.labelContent,"label")
                                  }
                                >
                                  Download label
                                </button>
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                  convertAndDownloadPDF(activityResponse?.returnDetailsBuyer?.data?.billDocContent,"bill")}
                                >
                                  Download bill
                                </button>
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                    printPDF(activityResponse?.returnDetailsBuyer?.data?.labelContent)
                                  }
                                >
                                 Print label
                                </button>
                            
                               
                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                    printPDF(activityResponse?.returnDetailsBuyer?.data?.billDocContent)
                                  }
                                >
                                 Print bill
                                </button>
                              
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
}


              </div>
              
          
          { activityResponse?.returnDetailsBuyer?.data?.labelContent===null && <div className="reportForm">

           
                          <div className="buyer-contact-form">
                            
                            <div className="buyer-contact-form-col report-buyer-padd">
                              <div className="form-group">
                                <label className="label-buyer">
                                  Courier Service
                                </label>
                                <span className="red-star">*</span>
                                <select
                                  className="form-control input-buyer"
                                  name="reportCategory"
                                  value={courierService}
                                  style={{color:"black"}}

                                  onChange={(e) => onCourierChange(e)}
                                >
                                  <option value=""> Choose Type</option>
                          {sellerProductResponse?.courierService?.data?.courierServicesDetails?.map(
                            (item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            )
                          )}
                                </select>
                                {/* {errors.reportCategory &&
                                touched.reportCategory ? (
                                  <div
                                    className="required"
                                    style={{ color: "red" }}
                                  >
                                    {errors.reportCategory}
                                  </div>
                                ) : null} */}

{courierService!=="" && <p className="order-product-text pt-3" style={{fontSize:15}}>
                              <span className="order-item-label" style={{fontSize:15}}> Shipping cost :</span>{" "}
                             <span style={{color:"black"}}> {activityResponse?.courierServiceCharges?.data && activityResponse?.courierServiceCharges?.data[0]?.price &&  "$ "+activityResponse?.courierServiceCharges?.data[0]?.price}
                             </span>    </p>}
                             {/* <div className="row align-items-center mt-4">
                        <div className="col-md-auto">
                          <div className="right-wrap-title order-item-label " style={{fontSize:15}}>
                            Pickup Required
                          </div>
                        </div>
                             <div className="col-md-auto mt-2">
                       
                              <div className="radio d-inline-block mr-4">
                              <input
                            id="true"
                            type="radio"
                            value="true"
                            name="true"
                            checked={pickupRadio==="true" ?true :false}
                            onChange={(e) => setpickupRadio(e.target.value)}
                          />
                                   <label htmlFor="true" className="radio-label">
                            Yes
                          </label>
                              </div>
                              <div className="radio d-inline-block mr-4">
                              <input
                            id="false"
                            value="false"
                            name="false"
                            onChange={(e) => setpickupRadio(e.target.value)}
                            type="radio"
                            checked={pickupRadio === "false" ? true : false}
                          />
                          <label htmlFor="false" className="radio-label">
                            No
                          </label>
                              </div>
                          


                        </div>
             </div> */}
                              </div>
                         
                            </div>
                          </div>

                          <div className="seller-button contact-buyer-btn">
                            <button type="submit" className="list-button ml-4" onClick={handleSubmit}>Continue</button>
                            {/* <button className="list-button bg-none"  
                  //           onClick={() => {
                  // dispatch<any>(
                  //   resetDetail()).then(()=>navigate( state?.route))     
                                    
                  //                   }}
                                    >
                              Cancel
                            </button> */}
                          </div>
                        
            </div>}

          
            

         
            </div>
          </div>

    
        </div>
      </section>

    </div>
  </main>
      <BuyerFooter />
    </DefaultLayout>
  );
};

export default ReturnDetailBuyer;
