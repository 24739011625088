import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderCancelled } from "../../../modules/orders/orderCancelled";
import { createShipmentSave } from "../../../modules/orders/createShipmentSave";
import moment from "moment";
import { getShipperBuyerDetail } from "../../../modules/orders/getShipperBuyerDetail";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { RootState } from "../../../utils/types";

const CreateShipmentModel = ({
  createShipment,
  setCreateShipment,
  
}: any) => {
  const dispatch = useDispatch();
  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );
  // const [date, setDate] = useState(
  //     "");


  const dateCheck =()=>{
    const date1 = moment(new Date());
const date2 = moment(createShipment.date);

// Calculate the absolute difference in hours between the two dates
const hoursDifference = Math.abs(date2.diff(date1, 'hours'));

// Calculate the total difference in days and time
const daysDifference = Math.floor(hoursDifference / 24);
const remainingHours = hoursDifference % 24;
if (daysDifference > 10 || (daysDifference === 10 && remainingHours > 0)) {
  return true
} else {
  return false
}







  }
  const onChange = (e: any) => {
    if(e.target.name=="ShippingDateAndTime")
    {
      let newDate=new Date(e.target.value);
      let isoDate=moment(newDate).format('YYYY-MM-DDTHH:mm:ss zzz');
      const dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
      });
      setCreateShipment({
        ...createShipment,
        [e.target.name]:  moment(newDate).format('YYYY-MM-DDTHH:mm:ss Z'),
        
        date:e.target.value
      });
    // setDate(
    //    e.target.value,
    // )
    }
    else
      {  setCreateShipment({
          ...createShipment,
          [e.target.name]: e.target.value,
        });}
      };

      useEffect(()=>{
        dispatch<any>(getShipperBuyerDetail({orderId:createShipment.orderId}))
      },[createShipment.orderId])
      // useEffect(()=>{
      //  if(order?.shippingBuyerDetail?.data?.services==false)
      //   {
      //     setCreateShipment({...createShipment, serviceId:null})
      //   }
      // },[order?.shippingBuyerDetail])
  
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: createShipment.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Create shipment</div>
            <div className="row">
                    <div className="col-md-6">
            <div className="form-group spacer-feedback-select">
              <label className="label-modal" style={{fontWeight:"bold"}}>
               Buyer address
              </label>
              <p style={{fontSize:12}}>
                                    {order?.shippingBuyerDetail?.data?.buyerAddress1} <br />
                                    {order?.shippingBuyerDetail?.data?.buyerAddress2 ?<> {order?.shippingBuyerDetail?.data?.buyerAddress2} <br /></> :null}  {" "}
                                    {order?.shippingBuyerDetail?.data?.buyerCity}<br />
                                    {order?.shippingBuyerDetail?.data?.buyerCountryName} <br />{" "}
                                    {/* {order?.shippingBuyerDetail?.data?.Country.CountryName} ,{" "} */}
                                    {order?.shippingBuyerDetail?.data?.buyerZipCode}<br />
                                    {order?.shippingBuyerDetail?.data?.buyerMobileNo}<br />

                                  </p>                  
            
            </div>
            </div>
            <div className="col-md-6">
            <div className="form-group spacer-feedback-select">
              <label className="label-modal" style={{fontWeight:"bold"}}>
               Seller address
              </label>
              <p style={{fontSize:12}}>
                                    {order?.shippingBuyerDetail?.data?.sellerAddress1} <br />
                                    {order?.shippingBuyerDetail?.data?.sellerAddress2 ?<> {order?.shippingBuyerDetail?.data?.sellerAddress2} <br /></> :null}  {" "}
                                    {order?.shippingBuyerDetail?.data?.sellerCity}<br />
                                    {order?.shippingBuyerDetail?.data?.sellerCountryName} <br />{" "}
                                    {/* {order?.shippingBuyerDetail?.data?.Country.CountryName} ,{" "} */}
                                    {order?.shippingBuyerDetail?.data?.sellerZipCode}<br />
                                    {order?.shippingBuyerDetail?.data?.sellerMobileNo}<br />

                                  </p>                  
            
            </div>
            </div>
            </div>
            <div className="form-group spacer-feedback-select">
              <label className="label-modal">
                Shipment date and time
              </label>
              <p style={{fontSize:12}}>(Note: Date should not be in past and it should be within 10 days from current date.)</p>
              <input  type="datetime-local" name="ShippingDateAndTime" value={createShipment.date} onChange={onChange} style={{width:"100%" , padding:4, fontSize:14}} id="birthdaytime"   />
                  
              { createShipment?.ShippingDateAndTime?.length===0 &&  <p style={{color:"red"}} >
              *Required!
              </p>}
             { dateCheck()}
              { (moment(createShipment?.date).isBefore(new Date()) ||  dateCheck())  && <p style={{color:"red", fontSize:10}} >
              The entered date is either a past date or more than 10 days in the future.
              </p>}
            </div>
           {/* {order?.shippingBuyerDetail?.data?.services &&  <div className="">
                      <div className="form-group">
                        <label className="label-modal">
                        Courier Service
                        </label>
                        <select
                          className="form-control input-seller"
                          name="serviceId"
                          value={createShipment?.serviceId}
                          onChange={(e) =>
                            setCreateShipment({
                              ...createShipment,
                              serviceId: e.target.value ,
                            })
                          }
                        >
                          <option value=""> Select Service</option>
                          {order?.shippingBuyerDetail?.data?.courierServicesName.map(
                            (item, Index) => (
                              <option value={item.id}>{item.name}</option>
                            )
                          )}
                        </select>
                 
                          {(createShipment?.serviceId=="" ) && <p style={{color:"red"}} >
               *Required!
              </p>}
                      </div>
                    </div>} */}
            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Description</label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="Write something"
                value={
                  createShipment.ShipmentDescription 
                }
                onChange={(e) =>
                  setCreateShipment({
                    ...createShipment,
                    ShipmentDescription: e.target.value ,
                  })
                }
              ></textarea>
               { createShipment?.ShipmentDescription?.length===0 &&  <p style={{color:"red"}} >
               *Required!
              </p>}
              <p>
                {createShipment.ShipmentDescription === null
                  ? 0
                  : createShipment?.ShipmentDescription?.length}
                /500
              </p>
           
            </div>

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn modal-cancel-order-btn"
                onClick={() => {
                  dispatch<any>(createShipmentSave({  "ShippingDateAndTime": createShipment.ShippingDateAndTime,
                  "orderId": createShipment.orderId,
                  "ShipmentDescription": createShipment.ShipmentDescription,
                  // "serviceId": order?.shippingBuyerDetail?.data?.services ? createShipment?.serviceId : null,
                }));
                  setCreateShipment({ ...createShipment, visible: "none" });
                }}
                disabled={
                  createShipment.ShipmentDescription.length === 0 ||
                  createShipment.ShippingDateAndTime === ""  
                    ? true
                    : false
                }
              >
                Create 
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() =>
                  setCreateShipment({
                    ...createShipment,
                    visible: "none",
                    reason: null,
                    description: null,
                  })
                }
              >
              Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateShipmentModel;
