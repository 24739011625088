import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { RootState } from "../../../utils/types";
import { ToastContainer } from "react-toastify";
import Footer from "../../../components/footer/Footer";
import { Formik, Form } from "formik";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { getStateByCountryCode } from "../../../modules/common/getStateByCountryCode";
import { getCountries } from "../../../modules/common/getCountries";
import * as Yup from "yup";
import { BASE_URLS } from "../../../utils/api.urls";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { resetOrder } from "../../../modules/orders/resetOrder";
import { constant } from "../../../utils/constant";
import { notify } from "../../../modules/notification/notify";
import "react-toastify/dist/ReactToastify.css";
import { postReturnRequest } from "../../../modules/orders/postReturnRequest";
import { postRefundReturn } from "../../../modules/orders/postRefundReturn";
import { getReturnDetails } from "../../../modules/orders/getReturnDetails";

const ReturnRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstRender = useRef(true);
  const { state } = useLocation();
  const [address, setAddress] = useState({

    id:0,
    address1: "",
    address2: "",
    city: "",
    stateId: 0,
    countryId: 2,
    zipCode: "",
    mobileNo:""
  });
  const [stateName, setStateName]=useState("")
  const [CountryName, setCountryName]=useState("")
  const handleAddressChange = (e: any) => {
    setAddress({
        ...address,
        [e.target.name]: e.target.value,
    });
}
const handleCountryChange = (e: any) => {
  setAddress({
      ...address,
      [e.target.name]: e.target.value,
      stateId:0
  });

  let countryCode = e.target[e.target.selectedIndex].getAttribute("data-code");
  dispatch<any>(getStateByCountryCode(countryCode));
};
const common = useSelector<RootState, RootState["common"]>(
  CommonResponseSelector
);

let order = useSelector<RootState, RootState["sellerOrders"]>(
  SellerOrdersResponseSelector
);



useEffect(() => {
  if (isFirstRender.current) {

    dispatch<any>(getReturnDetails(state));
    if (common.countries === null) dispatch<any>(getCountries());
    
  }
  isFirstRender.current = false;
}, []);

useEffect(() => {
  if(order?.returnDetail && common.countries)
  {

    let countryData = common?.countries?.value.find(
      (item: any) =>
        item.Id ==
        order?.returnDetail?.data?.countryId
    );
    
  
    if(order?.returnDetail?.data?.countryId)
    dispatch<any>(getStateByCountryCode(countryData?.CountryCode));
    setCountryName( countryData?.CountryName ? countryData?.CountryName :"")


  }
  
 
}, [common?.countries,order?.returnDetail ]);

useEffect(() => {
 if(order.returnDetail?.data?.stateId)
 {

  let stateData = common?.state?.value.find(
    (item: any) =>
      item.Id ==
      order?.returnDetail?.data?.stateId
  );
  setStateName( stateData?.StateName ? stateData?.StateName :"")




 }
  
 
}, [common?.state, order.returnDetail]);
useEffect(() => {
if(order.returnDetail)
  setAddress({...address,id:state, address1:order.returnDetail?.data?.address1, address2:order.returnDetail?.data?.address2,city:order.returnDetail?.data?.city,countryId:order.returnDetail?.data?.countryId,stateId:order.returnDetail?.data?.stateId,zipCode:order.returnDetail?.data?.zipCode.toString(),mobileNo:order.returnDetail?.data?.mobileNo.toString()})
 
}, [order.returnDetail]);

const ShippingSchema = Yup.object().shape({
  

  city:   Yup.string()
  .min(2, "Too Short, min 2 letters allowed!")
  .max(50, "Too Long, max 50 letters allowed!")
  .required("*Required!").nullable(),
  mobileNo: Yup.string()
  .min(9, "Too Short!, min 10 digits allowed!")
  .max(10, "Too Long, max 10 digits allowed!")
  .required("*Required!"),
    zipCode: Yup.string()
    .min(5, "Too Short, min 5 digits allowed!")
            .max(6, "Too Long, max 6 digits allowed!")
            .required("*Required!").nullable(),
    address1: Yup.string()
    .min(5, "Too Short, min 5 letters allowed!")
    .max(100, "Too Long, max 100 letters allowed!")
    .required("*Required!").nullable(),
    countryId: Yup.string()
    .required("*Required!").nullable() .test(
        "Is positive?",
        "*Required!",
        (value) =>
          value === undefined ||
          value === null ||
          (Number(value) !=0)
      ),
      stateId: Yup.string()
    .required("*Required!").nullable() .test(
        "Is positive?",
        "*Required!",
        (value) =>
          value === undefined ||
          value === null ||
          (Number(value) !=0)
      ),
    
});


useEffect(() => {
  if (order.saveData != null) {
    if (order.saveData.success == "Y") {
      notify(order.saveData.message, constant.SUCCESS);
    dispatch<any>(getReturnDetails(state));
      
    } else if (order.saveData.success == "N") {
      notify(order.saveData.message, constant.ERROR);
    } 
    
  }else if (order.saveDataError !== null)
  notify(order.saveDataError, constant.ERROR);
dispatch<any>(resetOrder());
}, [order.saveData, order.saveDataError]);
  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to="/seller/overview">efindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
            <Link to="/seller/orderReturn">Return</Link>

            </li>
            <li className="spacer">{">"}</li>
            
            <li>
            Return request

            </li>
          </ul>
        </div>
      </section>

      <main className="seller-page-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="order-detail-heading">
                <h2 className="seller-head-style">Return request</h2>
              </div>
              <div className="order-detail-block">
              <div className="return-request-head-padd">
                     
                     <div className="refund-checkbox-block">
                        <div className="ch-item seller-ch-item">
                          <p>
                            <input type="radio" id="test1" checked={order.returnDetail?.data.status!=="Return request"} name="test1"/>
                            <label><span>Return started</span></label>
                          </p>
                        </div>

                        <div className="ch-item seller-ch-item">
                          <p>
                            <input type="radio" id="test2" checked={order.returnDetail?.data.itemReceivedDate!==null} name="test2"/>
                            <label><span>Item delivered</span></label>
                          </p>
                        </div>

                        <div className="ch-item seller-ch-item">
                          <p>
                            <input type="radio" id="test3" checked={order.returnDetail?.data.completedDate!==null} name="test3"/>
                            <label><span>Refund sent</span></label>
                          </p>
                        </div>
                     </div>
                  </div>
                <div className="return-request-padd">
                  {/* <div className="return-row-one">
                    <div className="return-title">
                      The buyer requested a return.
                    </div>
                    <div className="return-msg">
                      Please respond by Sep 07 or we approve it for you.
                    </div>
                  </div> */}

                  <div>
                    <div className="return-title">Reason for return</div>
                    <small className="return-small-text">
                      {order.returnDetail?.data.returningReason} <br /> {order.returnDetail?.data.description}
                    </small>
                  </div>

            {  
            
            
         (   <div className="respond-block">
                          <div className="return-title return-respond-padd">
                      Your return address
                    </div>
                          { (
                            <>
                            
                              { (
                                <p className="address-return-details">
                                  {address.address1 + ","} <br />
                                  {address.address2 && address.address2 + ","} {" "}
                                  {address.city+ ","}
                                  <br />
                                  {stateName} ,{" "}
                                  {CountryName} ,{" "}
                                  {address.zipCode+ ","}<br />
                                  {address.mobileNo}

                                </p>
                              )}{" "}
                            </>
                          )}
  { order?.returnDetail?.data?.itemReceivedDate!==null && order?.returnDetail?.data?.completedDate==null && <button
                      type="button"
                   onClick={()=>
  dispatch<any>(postRefundReturn(state))
                  
                  }
                      className="btn btn-primary continue-btn   "
                    >
                      Initiate refund
                    </button>}
                          
                        </div>)
                        
                        
                        
  
                  }

                  <div className="order-summary-block">
                    <div className="return-title">Order summary</div>
                    <div className="order-product-block">
                      <div className="order-product-left">
                        <div className="order-product-img"><img
                                      src={
                                        BASE_URLS.AWS_URL +
                                        "/Thumb" +
                                        order?.returnDetail?.data?.productImage
                                      }
                                      alt="dummy image"
                                      
                                    /></div>
                        <div className="order-product-text">
                          {order?.returnDetail?.data?.productName}
                          {order?.returnDetail?.data?.variant && JSON.parse(order?.returnDetail?.data?.variant)?.map((product:any)=>(
                                  <p> {`${product.AttName} : ${product.Option}`} </p>
                                  ))

                                 }
                               <p>  {"Return Id : "+ state}</p>
                        </div>
                      </div>
                      <div className="order-product-right">
                        <div className="order-product-row">
                          <div className="order-product-col-left">Quantity</div>
                          <div className="order-product-col-right">{order?.returnDetail?.data?.quantity}</div>
                        </div>
                        <div className="order-product-row">
                          <div className="order-product-col-left">
                            <strong>Sub total</strong>
                          </div>
                          <div className="order-product-col-right">${order?.returnDetail?.data?.quantity * order?.returnDetail?.data?.purchasingPrice + order?.returnDetail?.data?.quantity * order?.returnDetail?.data?.shipmentAmount}</div>
                        </div>
                        <div className="order-product-row border-bottom-0">
                          <div className="order-product-col-left">
                            <strong>Estimate refund</strong>
                          </div>
                          <div className="order-product-col-right">${order?.returnDetail?.data?.estimatedRefund}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </DefaultLayout>
  );
};

export default ReturnRequest;
