import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../layout/DefaultLayout";
import { getSuggestion } from "../../modules/buyer/product/getSuggestion";
import { buyerProductSelector } from "../../modules/buyer/product/selectors";
import { deleteToken } from "../../modules/userLogin/deleteToken";
import { resetLogout } from "../../modules/userLogin/resetLogout";
import { setSocialDetails } from "../../modules/userLogin/setSocialDetails";
import { RootState } from "../../utils/types";
import Autocomplete from "react-autocomplete";
import AdvanceSearch from "./component/AdvanceSearch";
import { getHomepage } from "../../modules/buyer/homepage/getHomepage";
import { homepageSelector } from "../../modules/buyer/homepage/selectors";

import watchlistImg from '../../assets/images/w-img1.png'
import { getWatchlist } from "../../modules/buyer/myEfindit/activity/getWatchlist";
import { activityResponseSelector } from "../../modules/buyer/myEfindit/activity/selectors";
import "./header.scss";
import { BASE_URLS } from "../../utils/api.urls";
import DefaultImage from "../../assets/images/defaultImage.png";
import { resetDetail } from "../../modules/buyer/product/resetDetail";
import { CartItemsResponseSelector } from "../../modules/cartItems/selector";
import { setCartItems } from "../../modules/cartItems/setCartItems";
import { toInteger } from "lodash";
import { notify } from "../../modules/notification/notify";
import { constant } from "../../utils/constant";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getWebPImage, imageOnError} from "../../utils/helpers";
import { getCartItems } from "../../modules/cartItems/getCartItems";

interface Props {
  filter?: any;
  setFilter?: any;
  currentPage?: string;
  setcurrentPage?: any;
  subCategory?: any;
  option?: any;
}

const BuyerHeader = ({
  filter,
  setFilter,
  currentPage,
  setcurrentPage,
  subCategory,
  option,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [suggestionData, setSuggestionData] = useState([]);
  const [key, setKey] = useState(0);
  const [search, setSearch] = useState("");
  const [watchList, setWatchList] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [totalProduct, setTotalProduct ] = useState(0);


  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );

  let cartItemsResponse = useSelector<RootState, RootState["totalCartItems"]>(
    CartItemsResponseSelector
  );

  const callWatchlist = () => {
    dispatch<any>(
      getWatchlist({
        currentPage: 1,
        rows: 5,
        status: "",
      })
    );
  };
  
  useEffect(() => {
    if(watchList){
      callWatchlist();
    }
  }, [watchList]);

  const [advance, setAdvance] = useState({
    visible: "none",
    auction: "",
    fixed: "",

    condition: "",
    categoryId: "",
    startPrice: "",
    endPrice: "",
    titleAndDescription: "",
    showItemPriceFrom: "",
    minPrice: "",
    maxPrice: "",
    new: "",
    used: "",
    listing: "",
    notSpecified: "",
    soldItems: "",
    completedListings: "",
    noOfBidsFrom: "",
    minBiddingNo: "",
    maxBiddingNo: "",
  });
  const homepageResponse = useSelector<RootState, RootState["homepage"]>(
    homepageSelector
  );
  const buyerProductResponse = useSelector<
    RootState,
    RootState["buyerProduct"]
  >(buyerProductSelector);

  const Logout = () => {
    dispatch<any>(
      setSocialDetails({
        provider: "",
        profile: "",
        type: "logout",
      })
    )
      .then(dispatch<any>(deleteToken({})))
      .then(dispatch<any>(resetLogout()));
  };

  const handleOnSearch = (event: any, results: any) => {
    // onSearch will have as the first callback parameter
    // the string searched and htmlFor the second the results.
    dispatch<any>(getSuggestion(event));
  };
  const handleOnSearchClick = () => {
    // onSearch will have as the first callback parameter
    // the string searched and htmlFor the second the results.
  if(search==="" && advance.categoryId==="")
  {
     notify("Please select a category or enter some search value",constant.DUPLICATE_ERROR)
  } 
  else{ setAdvance({
      ...advance,
      visible: "none",
    });
    if (filter === undefined && setFilter === undefined)
      navigate("/buyer/searchResult", {
        state: { search, advance },
      });
    else {
      setFilter({
        ...filter,
        search: search,
        auction: advance.auction,
        fixed: advance.fixed,

        startPrice: advance.startPrice,
        endPrice: advance.endPrice,
        titleAndDescription: advance.titleAndDescription,
        showItemPriceFrom: advance.showItemPriceFrom,
        minPrice: advance.minPrice,
        maxPrice: advance.maxPrice,
        new: advance.new,
        used: advance.used,
        listing: advance.listing,
        notSpecified: advance.notSpecified,
        soldItems: advance.soldItems,
        completedListings: advance.completedListings,
        noOfBidsFrom: advance.noOfBidsFrom,
        minBiddingNo: advance.minBiddingNo,
        maxBiddingNo: advance.maxBiddingNo,
        categoryId: advance.categoryId,
      });
      setcurrentPage(0);
    }}
  };
  const handleSearchChange = (event: any) => {
   
    setSearch(event.target.value);
    if (event.target.value !== "")
      dispatch<any>(getSuggestion(event.target.value));
  };


  const hideWatchList = (event: any) => {
    setWatchList(false);
    event.preventDefault();
  };


  const handleOnSelect = (val: any) => {
    // the item selected
    setSearch(val);

    if (filter === undefined && setFilter === undefined)
      navigate("/buyer/searchResult", {
        state: { search: val, advance },
      });
    else {
      setFilter({
        ...filter,
        search: val,
        auction: advance.auction,
        fixed: advance.fixed,

        startPrice: advance.startPrice,
        endPrice: advance.endPrice,
        titleAndDescription: advance.titleAndDescription,
        showItemPriceFrom: advance.showItemPriceFrom,
        minPrice: advance.minPrice,
        maxPrice: advance.maxPrice,
        new: advance.new,
        used: advance.used,
        listing: advance.listing,
        notSpecified: advance.notSpecified,
        soldItems: advance.soldItems,
        completedListings: advance.completedListings,
        noOfBidsFrom: advance.noOfBidsFrom,
        minBiddingNo: advance.minBiddingNo,
        maxBiddingNo: advance.maxBiddingNo,
        categoryId: advance.categoryId,
      });
      setcurrentPage(0);
    }
  };

  useEffect(() => {
    let suggestion: any = [];
    buyerProductResponse?.buyerProduct?.data?.forEach((item, Index) => {
      suggestion.push({
        id: Index,
        name: item.suggestion,
      });
    });
    setSuggestionData(suggestion);
  }, [buyerProductResponse?.buyerProduct?.data]);

  useEffect(() => {
    if (homepageResponse?.homepage === null) dispatch<any>(getHomepage());

    if(localStorage.getItem("authorization") === null && localStorage.getItem("cart") !== null){
      let cart = JSON.parse(localStorage.getItem("cart") || "[]");
      dispatch<any>(setCartItems(cart.length));
    }
    else
    dispatch<any>(getCartItems());
  }, []);

  useEffect(() => {
    if(localStorage.getItem("authorization") === null) {
      let cart = JSON.parse(localStorage.getItem("cart") || "[]");
      let total =0;
      cart.map((item:any)=>{
        total=total+ item.quantity;
      })
      setTotalProduct(total);
    }

    if(localStorage.getItem("authorization") != null) {

      // if(localStorage.getItem("userCartItem") === null){
        setTotalProduct(cartItemsResponse.totalProducts);
      // }
      // }else{
      //   const userCartItem = JSON.parse(localStorage.getItem("userCartItem")!);
      //   setTotalProduct(userCartItem);
      // }

    }

  }, [cartItemsResponse]);


  useEffect(() => {
    if (subCategory !== undefined && subCategory) {
      setAdvance({
        ...advance,
        categoryId: subCategory.id,
      });
    }
  }, [subCategory]);

  useEffect(() => {
    if (subCategory !== undefined && subCategory)
      navigate("/buyer/searchResult", {
        state: { search, advance, subCategory },
      });

    if(advance.visible === "block"){
      document.body.classList.add('hide-scroll');
    }else{
      document.body.classList.remove('hide-scroll');
    }

  }, [advance]);
  useEffect(() => {
   

  }, [localStorage.getItem("UserName")]);
  useEffect(() => {
    if (filter !== undefined) {
      setSearch(filter.search);
      setAdvance({
        ...advance,
        auction: filter.auction,
        fixed: filter.fixed,

        startPrice: filter.startPrice,
        endPrice: filter.endPrice,
        titleAndDescription: filter.titleAndDescription,
        showItemPriceFrom: filter.showItemPriceFrom,
        minPrice: filter.minPrice,
        maxPrice: filter.maxPrice,
        new: filter.new,
        used: filter.used,
        listing: filter.listing,
        notSpecified: filter.notSpecified,
        soldItems: filter.soldItems,
        completedListings: filter.completedListings,
        noOfBidsFrom: filter.noOfBidsFrom,
        minBiddingNo: filter.minBiddingNo,
        maxBiddingNo: filter.maxBiddingNo,
        categoryId: filter.categoryId,
      });
    }
  }, [filter]);

  const handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Perform your action here
      handleOnSearchClick();
      // You can call a function or update the state, etc.
    }
  };
  return (
    <DefaultLayout>
          
      {" "}
      <div>
        <header className="header">
          <div className="top-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="top-section-container">
                    <div className="top-section-left">
                      <ul className="list-unstyled">
                        {localStorage.getItem("UserName") !== null &&
                          localStorage.getItem("authorization") !== null && (
                            <li className="menu-username">
                               <Link to="/buyer/myaccount" >
                               Hi{""} <span style={{color:"#0000FF"}}>{localStorage.getItem("UserName")?.substring(0,20) }</span> 
                              </Link>
                            
                            </li>
                          )}
                        <li className="menu-deal">
                          <Link to="/buyer/dailyDeal" style={{color:"#0000FF"}}>Daily deals</Link>
                        </li>

                        {/* <li className="menu-help">
                          <a href="#">Help &amp; Contact</a>
                        </li> */}
                      </ul>
                    </div>
                    <div className="top-section-right">
                      <ul className="list-unstyled">
                        {/* <li className="menu-shipto">
                          <a href="#">Ship to</a>
                        </li> */}
                        {localStorage.getItem("authorization") !== null &&
                        localStorage.getItem("role") === "Customer" ? (
                          <>
                              {" "}
                            <li className={`dropdown menu-watchlist${watchList ? " open" :" hide"}`}>
                              <div  className=""  style={{cursor:"pointer"}} onClick={() => setWatchList(!watchList)}>
                                Watchlist
                              </div>
                              <div className="dropDownMenu" onMouseLeave={ (e) => hideWatchList(e) }>

                                  {activityResponse?.watchlist?.data == null && (
                                    <div className="no-watch-list">
                                      No records found
                                    </div>
                                  )}

                                  {activityResponse?.watchlist?.data?.products?.map(
                                    (product, Index) => {
                                      return (
                                        <div className="watch-each-item">
                                          <div className="media">
                                            <div  style={{cursor:"pointer"}} onClick={() =>
        dispatch<any>(
          resetDetail()).then(()=>navigate(`/buyer/productDetail/${product.productId}/${product.variantId}/${new Date()}`))      
      }> 
                                            <picture>
                                              <source srcSet={getWebPImage(product.defaultImage)} type="image/webp"/>
                                              <LazyLoadImage
                                                className="prod-img"
                                                src={BASE_URLS.AWS_URL +
                                                  "/Thumb" +
                                                  product.defaultImage}
                                                  onError={imageOnError}
                                                />
                                            </picture>

                                            </div>
                                          </div>
                                          <div className="context">
                                            <h4>
                                            <div  style={{cursor:"pointer"}} onClick={() =>
        dispatch<any>(
          resetDetail()).then(()=>navigate(`/buyer/productDetail/${product.productId}/${product.variantId}/${new Date()}`))      
      }> 
                                              {product.title.length > 40 ?
                                                  `${product.title.substring(0, 40)}...` : product.title
                                                }
                                              </div>
                                            </h4>
                                            <div className="price">${product.buyNowPrice? product.buyNowPrice : product.actualPrice}</div>
                                          </div>
                                        </div>        
                                      );
                                    }
                                  )}
                                </div>
                            </li>

                            <li className="dropdown">
                              <Link to="/buyer/activity/recentlyViewed">
                                My efindit
                              </Link>
                              <div className="dropDownMenu dropDownHoverMenu">
                                <ul>
                                  <li>
                                    <Link to="/buyer/activity/recentlyViewed">
                                      Recently viewed
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/buyer/activity/bidsOffers">
                                      Bids/Offers
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/buyer/activity/purchaseHistory">
                                      Purchase history
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/buyer/activity/buyAgain">
                                      Buy again
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/buyer/activity/watchlist">
                                      Watch list
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/buyer/activity/savedSearches">
                                      Saved searches
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/buyer/activity/savedSellers">
                                      Saved sellers
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={"/login-seller"}>
                                      Sell
                                    </Link>
                                  </li>
                                  <li>
                                    <a href="#" onClick={Logout}>
                                      Logout
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </>
                        ) : (
                          <li className="menu-login">
                            <Link to="/login" style={{color:"#0000FF"}}>Login</Link>
                          </li>
                        )}
                        <li className="cart-menu">
                            <Link to="/buyer/myCart">
                              <i className={totalProduct > 0 ? 'cart-icon active' : 'cart-icon'}>
                                  {totalProduct > 0 && (
                                    <span>
                                      {totalProduct}
                                    </span>
                                  )}
                              </i>
                            </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="logo-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="logo-container" >
                    <div className="logo" >

            { currentPage==="home" ?       <i className="logo-img" style={{cursor:"pointer"}} onClick={()=>  
        window.location.reload()
                      
                      }></i> :
                      <Link to={"/home"} >
                        <i className="logo-img"></i>
                      </Link>}
                   
                      <div className="menuHamberger" onClick={ (e) => setMobileMenu(!mobileMenu) }>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>

                    </div>

                    <div className="search-block">
                      <div
                        className="form-group"
                        style={{ padding: 0, backgroundColor: "white" }}
                      >
                        <Autocomplete
                          getItemValue={(item) => item.name}
                          items={suggestionData}
                          renderItem={(item, isHighlighted) => (
                            <div
                              style={{
                                background: isHighlighted
                                  ? "lightgray"
                                  : "white",
                                cursor: "pointer",
                                paddingLeft: 24,
                                fontSize: 15,
                                zIndex: 100,
                                position: "relative",
                                marginRight: 20,
                              }}
                            >
                              {item.name}
                            </div>
                          )}
                          value={search}
                          autoHighlight={false}
                          inputProps={{
                            style: {
                              width: "100%",
                              height: "33px",
                              boxShadow: "none",
                              paddingLeft: 24,
                              // background: "#e4f3f7",
                              // border: '2px outset lightgray',
                              // borderRadius: '5px',
                              // fontWeight: "bold",
                              borderColor: "transparent",
                              marginRight: "20px",
                            },
                            onKeyDown: handleKeyDown,
                            maxLength: 500,
                            placeholder: " Search for anything",
                            spellCheck: false,
                          }}
                          onChange={(e) => handleSearchChange(e)}
                         
                          onSelect={(val) => handleOnSelect(val)}
                          wrapperStyle={{}}
                          menuStyle={{
                            width: "100%",
                          }}
                        />
                      </div>

                      <div className="categories-box">
                        <select
                          id="category"
                          name="category"
                          className="select-category"
                          value={advance.categoryId}
                          onChange={(e) =>
                            setAdvance({
                              ...advance,
                              categoryId: e.target.value,
                            })
                          }
                        >
                          <option value="">All categories</option>
                          {option && (
                            <option value={option.id}>{option.name}</option>
                          )}

                          {homepageResponse?.homepage?.data?.parentCategorys?.map(
                            (item, Index) => (
                              <option
                                key={item.categoryId}
                                value={item.categoryId}
                              >
                                {item.categoryName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="search-button-block">
                      <button
                        type="button"
                        className="btn btn-primary search-btn form-button"
                        onClick={handleOnSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          setAdvance({
                            ...advance,
                            visible:
                              advance.visible === "block" ? "none" : "block",
                          })
                        }
                        className="btn btn-primary adv-search-btn form-button"
                      >
                        Advanced search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <AdvanceSearch advance={advance} setAdvance={setAdvance} />
        <section className="category-section">
            <nav className="category-nav">
              <ul className="category-menu list-unstyled">
                
                <li className={currentPage == "" ? "active" : ""}>
                  <Link to={`/home`}>Home</Link>
                </li>

                {/* <li>
                  <Link className="saved-item" to="/buyer/activity/savedSearches">Saved</Link>
                </li> */}
                <li className=" dropdownBlock">
                       
            
                  <Link className="saved-item" to="/buyer/activity/savedSearches">Saved</Link>
              
                    
                        <div className="dropDownMenu">
                          <div className="columns">
                            <div className="group">
                              {/* <h3>More Categories</h3> */}
                            
                              <Link to="/buyer/activity/savedSearches">Saved searches</Link>

                                <Link
                                   to="/buyer/activity/savedSellers"
                                >
                                  Saved sellers
                                </Link>
                            </div>
                            {/* <div className="group">
                              <h3>More Categories</h3>
                              <a href="">Cell phones and accessories</a>
                              <a href="">Video games and consoles</a>
                              <a href="">Computers and tablets</a>
                            </div> */}
                          </div>
                        </div>
                      </li>
                {homepageResponse?.homepage?.data?.buyerHomePageCategory?.map(
                  (item, Index) => (
                    <>
                      <li className={currentPage == item.name ? "dropdownBlock active" : "dropdownBlock"}>
                        <Link
                          to={`/buyer/productCategory/${item.id}/${item.name}`}
                        >
                          {item.name}
                        </Link>
                        <div className="dropDownMenu">
                          <div className="columns">
                            <div className="group">
                              {/* <h3>More Categories</h3> */}
                              {item?.childs?.map((child, Index) => (
                                <Link
                                  to={`/buyer/productCategory/${child.id}/${child.name}`}
                                >
                                  {child.name}
                                </Link>
                              ))}
                            </div>
                            {/* <div className="group">
                              <h3>More Categories</h3>
                              <a href="">Cell phones and accessories</a>
                              <a href="">Video games and consoles</a>
                              <a href="">Computers and tablets</a>
                            </div> */}
                          </div>
                        </div>
                      </li>
                    </>
                  )
                )}
                <li>
                  <Link to={"/login-seller"}>Sell</Link>
                </li>
              </ul>
            </nav>
        </section>

        <section className={`mob-category-section${mobileMenu ? " open" :" hide"}`}>
          <div className="closeBtn" onClick={ (e) => setMobileMenu(!mobileMenu) }></div>
          <nav className="mob-category-nav">
            <ul className="mob-category-menu list-unstyled">
              <li className="active top-item">
                <Link to={`/home`}>Home</Link>
              </li>
              <li className="top-item">
                <Link to="/buyer/activity/savedSearches">Saved searches</Link>
              </li>
              <li className="top-item">
                <Link to="/buyer/activity/savedSellers">Saved sellers</Link>
              </li>
              {homepageResponse?.homepage?.data?.buyerHomePageCategory?.map(
                  (item, Index) => (
                  <>
                    <li className="top-item"><Link to={`/buyer/productCategory/${item.id}/${item.name}`}>{item.name}</Link></li>
                  </>
                  )
                )}
                <li className="top-item">
                  <Link to={"/login-seller"}>Sell</Link>
                </li>
            </ul>
          </nav>
        </section>
      </div>
    </DefaultLayout>
  );
};

export default BuyerHeader;
