import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { saveSellingDetails } from "../../../modules/sellerProduct/saveSellingDetails";
import { RootState } from "../../../utils/types";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reset } from "../../../modules/sellerProduct/reset";
import { getSellerProductDetails } from "../../../modules/sellerProduct/getSellerProductDetails";
import { resetCategorySuccess } from "../../../modules/categories/slice";
import { MultiSelect } from "react-multi-select-component";
import { getAttributes } from "../../../modules/sellerProduct/getAttributes";
import {
  deleteVariantImage,
  delVariant,
  fetchAttributeOption,
  postAttributeImage,
} from "../../../modules/sellerProduct/api";
import Select from "react-dropdown-select";
import CROSS from "../../../assets/images/cross.png";
import { BASE_URLS } from "../../../utils/api.urls";
import { deleteVariant } from "../../../modules/sellerProduct/deleteVariant";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import { useNavigate } from "react-router-dom";
interface Props {
  nextStep: any;
  setProductId: any;
  prevStep: any;
  productId: any;
  setstep?: any;
}

const SellingDetails = ({
  prevStep,
  nextStep,
  setProductId,
  productId,
  setstep,
}: Props) => {
  interface sellingValues {
    productId: string | null;
    format: number;
    duration: number | null;
    listingOnSave: boolean;
    listingDate: string | null;
    actualPrice: number | null | string;
    buyNowPrice: number | null;
    reservePrice: number | null;
    letBuyerMakeOffer: boolean;
    AutomaticallyAcceptOffersAtleast: number | null;
    AutomaticallyDeclineOffersLowerThan: number | null;
    quantity: number | null | string;
    sellAsLot: boolean;
    LotQuantity: string | null;
    isPrivateListing: boolean;
    requireImmediatePayment: boolean;
    salesTax: number | null;
    salesTaxApplicableForShippingAndHandling: boolean;
    isDomesticReturnAccepted: boolean;
    isInternationalReturnAccepted: boolean;
    relistIfNotSold: boolean;
    DeliveryInDays: number | null;
    ReturnInDays: number | null;
    BuyerPaysForReturnShipping: boolean;
    BiddingEndDate: string;
    Isdraft: boolean;
    Variant: any;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sellerProductResponse = useSelector<
    RootState,
    RootState["sellerProduct"]
  >(SellerProductResponseSelector);
  const [draft, setDraft] = useState(false);
  let submitAction: string | undefined = undefined;

  const [editableDuration, setEditableDuration] = useState(false);
  const [sellingData, setSellingData] = useState<sellingValues>({
    productId:
      localStorage.getItem("productId") !== null
        ? localStorage.getItem("productId")
        : productId,
    format: 1,
    duration: null,
    listingOnSave: true,
    listingDate: null,
    actualPrice: null,
    buyNowPrice: null,
    reservePrice: null,
    letBuyerMakeOffer: true,
    AutomaticallyAcceptOffersAtleast: null,
    AutomaticallyDeclineOffersLowerThan: null,
    quantity: null,
    sellAsLot: false,
    LotQuantity: null,
    isPrivateListing: false,
    requireImmediatePayment: false,
    salesTax: null,
    salesTaxApplicableForShippingAndHandling: false,
    isDomesticReturnAccepted: false,
    isInternationalReturnAccepted: false,
    relistIfNotSold: false,
    DeliveryInDays: null,
    ReturnInDays: null,
    BuyerPaysForReturnShipping: false,
    BiddingEndDate: "",
    Isdraft: false,
    Variant: { Variants: [], Images: [] },
  });

  const isFirstRender = useRef(true);
  const today = new Date();
  let month: string = (today.getMonth() + 1).toString();
  let day: string = today.getDate().toString();
  let year = today.getFullYear();
  if (Number(month) < 10) month = "0" + month.toString();
  if (Number(day) < 10) day = "0" + day.toString();
  let minDate = year + "-" + month + "-" + day;
  const [scheduleIsDisabled, setScheduleISDisabled] = useState(true);
  const [autoOfferHidden, setAutoOfferHidden] = useState(true);
  const [lotQuantityHidden, setLotQuantityHidden] = useState(true);
  const [scheduleDateFormat, setScheduleDateFormat] = useState({
    scheduleDate: "",
    scheduleTimeH: "1",
    scheduleTimeM: "0",
    scheduleTimeT: "00",
  });
  const [selected, setSelected] = useState<any>([]);
  const [attributes, setAttributes] = useState<any>([]);
  const [attributesOptions, setAttributesOptions] = useState<any>([]);
  const [colorID, setColorID] = useState(0);
  const [images, setImages] = useState<any>([]);
  const [submitClicked, setSubmitClicked] = useState<any>(false);

  const [selectedColors, setSelectedColors] = useState<any>([]);
  const [combination, setCombination] = useState<any>([]);
  const [selectedAttributesOptions, setSeletectedAttributesOptions] = useState(
    []
  );

  const SellingSchema = Yup.object().shape({
    format: Yup.string().required("*Required!"),
    // duration: Yup.string().required("*Required!"),
    // listingDate: Yup.string()
    // .nullable()
    // // .required("*Required!")
    // .test(
    //   "Is positive?",
    //   "Date and time cannot be less than current date and time",
    //   (value) =>
    //     value === undefined ||
    //     value === null ||
    //     (moment.utc(value).local().isBefore(new Date))
    // ),
    actualPrice: Yup.number()
      .nullable()
      // .required("*Required!")
      .test(
        "Is positive?",
        "The number must be greater than 0 and less than 10000000000!",
        (value) =>
          value === undefined ||
          value === null ||
          (value > 0 && value <= 9999999999.99)
      ).test(
        "Is positive?",
        "Actual price cannot be less than or equal to buy it now price",
        (value) =>
          value === undefined ||
          value === null ||
          ( (sellingData?.buyNowPrice ?  sellingData?.buyNowPrice < value  :true))
      ),
    AutomaticallyAcceptOffersAtleast: Yup.number()
      .nullable()
      .test(
        "Is positive?",
        "The number must be greater than 0 and less than 10000000000!",
        (value) =>
          value === undefined ||
          value === null ||
          (value > 0 && value <= 9999999999.99 )
      ).test(
        "Is positive?",
        "Automatically accept offers value cannot be less than or equal to automatically decline offers value",
        (value) =>
          value === undefined ||
          value === null ||
          ( (sellingData?.AutomaticallyDeclineOffersLowerThan ?  sellingData?.AutomaticallyDeclineOffersLowerThan <value  :true))
      ).test(
        "Is positive?",
        "Automatically accept offers value cannot be greater than or equal to buying price",
        (value) =>
          value === undefined ||
          value === null ||
          ( (sellingData?.buyNowPrice ? sellingData?.buyNowPrice  >= value :( sellingData?.actualPrice )? Number(sellingData?.actualPrice)  >= value :true))
      ),
 
    AutomaticallyDeclineOffersLowerThan: Yup.number()
      .nullable()
      .test(
        "Is positive?",
        "The number must be greater than 0 and les than 10000000000!",
        (value) =>
          value === undefined ||
          value === null ||
          (value > 0 && value <= 9999999999.99 )
      ).test(
        "Is positive?",
        "Automatically decline offers value cannot be greater than or equal to buying price",
        (value) =>
          value === undefined ||
          value === null ||
          ( (sellingData?.buyNowPrice ? sellingData?.buyNowPrice  >=value :( sellingData?.actualPrice )? Number(sellingData?.actualPrice)  >=value :true))
      ),
    buyNowPrice: Yup.number()
      .nullable()
      .test(
        "Is positive?",
        "The number must be greater than 0 and less than 10000000000!",
        (value) =>
          value === undefined ||
          value === null ||
          (value > 0 && value <= 9999999999.99)
      ),
    reservePrice: Yup.number()
      .nullable()
      .test(
        "Is positive?",
        "The number must be greater than 0 and less than 10000000000!",
        (value) =>
          value === undefined ||
          value === null ||
          (value > 0 && value <= 9999999999.99)
      ),
    quantity: Yup.number()
      .nullable()
      // .required("*Required!")
      .test(
        "Is positive?",
        `${
          sellingData.format == 1
            ? "The number must be greater than 0 and les than 2147483640!"
            : "quantity cannot be greated than 1 in case of auction"
        }`,
        (value) =>
          value === undefined ||
          value === null ||
          (sellingData.format == 1 && value > 0 && value <= 32767) ||
          (sellingData.format == 2 && value == 1)
      ),
    isPrivateListing: Yup.string().required("*Required!"),
    requireImmediatePayment: Yup.string().required("*Required!"),
    salesTax: Yup.number()
      .nullable()
      .required("Required")
      .test(
        "Is positive?",
        "The value must be greater than 0 and less than 100",
        (value) =>
          value === undefined || value === null || (value > 0 && value < 100)
      ),
    // DeliveryInDays: Yup.number()
    //   .nullable()
    //   .required("*Required!")
    //   .test(
    //     "Is positive?",
    //     "The number must be greater than 0 and less than 211!",
    //     (value) =>
    //       value === undefined || value === null || (value > 0 && value <= 210)
    //   ),
    ReturnInDays: Yup.number()
      .nullable()
      .required("*Required!")
      .test(
        "Is positive?",
        "The number must be greater than 0 and less than 211!",
        (value) =>
          value === undefined || value === null || (value > 0 && value <= 210)
      ),
  });

  const onChange = (e: any) => {
    if (e.target.name === "format" && e.target.value === "1") {
      setEditableDuration(false);
      setSellingData({
        ...sellingData,
        [e.target.name]: e.target.value,
        duration: null,
      });
    } else if (e.target.name === "format" && e.target.value === "2" && sellingData?.Variant?.Variants.length==0) {
      setSellingData({
        ...sellingData,
        [e.target.name]: e.target.value,
        duration: 3,
        buyNowPrice:null,
      });
      setEditableDuration(true);
    } 
    else if (e.target.name === "format" && e.target.value === "2" && sellingData?.Variant?.Variants.length!=0) {
      notify("Please delete all the variants in order to change buying format", constant.ERROR)
      // setSellingData({
      //   ...sellingData,
      //   [e.target.name]: e.target.value,
      //   duration: 3,
      // });
      // setEditableDuration(true);
    } 
    else  {
      setSellingData({
        ...sellingData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onCheckboxChange = (e: any) => {
    let value = e.target.checked ? true : false;
    if (e.target.name === "sellAsLot" && value === false) {
      setLotQuantityHidden(true);
      setSellingData({
        ...sellingData, LotQuantity:null   ,[e.target.name]: value,
      })
    }
   
   else if (e.target.name === "letBuyerMakeOffer" && value === true) {
      setAutoOfferHidden(false);
      setSellingData({
        ...sellingData,
        [e.target.name]: value,
      });
    }

   else if (e.target.name === "letBuyerMakeOffer" && value === false) {
      setAutoOfferHidden(true);
      setSellingData({
        ...sellingData,   AutomaticallyAcceptOffersAtleast: null,
        AutomaticallyDeclineOffersLowerThan: null ,[e.target.name]: value,
      })
    
    }
    else
    setSellingData({
      ...sellingData,
      [e.target.name]: value,
    });

    if (e.target.name === "sellAsLot" && value === true) {
      setLotQuantityHidden(false);
    }
    
  };

  const onRadioChange = (e: any) => {
    let value = e.target.value === "yes" ? true : false;
    if (value === true) {
      setScheduleISDisabled(true);
      setSellingData({
        ...sellingData,
        [e.target.name]: value,
        listingDate: null,
      });
      // setScheduleDateFormat({
      //   scheduleDate: "",
      //   scheduleTimeH: "1",
      //   scheduleTimeM: "0",
      //   scheduleTimeT: "00",
      // });
    } else {
      setScheduleISDisabled(false);
      setSellingData({
        ...sellingData,
        [e.target.name]: value,
        listingDate: "",
      });
    }
  };

  // const scheduleDateHandle = (e: any) => {
  //   const { name, value } = e.target;

  //   setScheduleDateFormat({
  //     ...scheduleDateFormat,
  //     [name]: numberPad(value),
  //   });
  // };

  const handleSelectedOptions = (selected: any, label: string) => {
    let newData: any = [];

    for (const [key, value] of Object.entries(selectedAttributesOptions)) {
      if (key === label) newData[key] = selected;
      else newData[key] = value;
    }
    setSeletectedAttributesOptions(newData);
  };
  useEffect(() => {
    calculateSelectedColors(sellingData.Variant.Variants);
  }, [colorID]);

  useEffect(() => {
    if (sellingData.format == 2)
      setAutoOfferHidden(true)
      setSellingData({ ...sellingData,letBuyerMakeOffer:false, AutomaticallyAcceptOffersAtleast:null, AutomaticallyDeclineOffersLowerThan:null, Variant: { Variants: [], Images: [] }, relistIfNotSold:false });
  }, [sellingData.format]);

  useEffect(() => {
    if (!scheduleIsDisabled) {
      let date = new Date(
        scheduleDateFormat.scheduleDate +
          " " +
          scheduleDateFormat.scheduleTimeH +
          ":" +
          scheduleDateFormat.scheduleTimeM +
          ":" +
          scheduleDateFormat.scheduleTimeT
      ).toISOString();
      setSellingData({
        ...sellingData,
        listingDate: date,
      });
    }
  }, [scheduleDateFormat]);

  useEffect(() => {
    if (isFirstRender.current) {
      if (sellerProductResponse.sellerProductDetails == null)
        dispatch<any>(getSellerProductDetails(sellingData.productId));
      dispatch<any>(getAttributes());
    }
    isFirstRender.current = false;
  }, []);
  useEffect(() => {
    if (sellerProductResponse?.attributes) {
      let options: any = [];
      sellerProductResponse?.attributes?.data?.forEach((element) => {
        options.push({ label: element.name, value: element.attributeId });
      });
      setAttributes(options);
    }
  }, [sellerProductResponse?.attributes]);

  useEffect(() => {
    if (
      sellerProductResponse?.attributes &&
      sellerProductResponse?.sellerProductDetails
    ) {
      if (
        sellerProductResponse?.sellerProductDetails?.value[0]?.Variants.length >
        0
      ) {
        let select: any = [];
        let Variants: any = [];
        JSON.parse(
          sellerProductResponse?.sellerProductDetails?.value[0]?.Variants[0]
            ?.Variant
        ).forEach((item: any) => {
          let data = sellerProductResponse?.attributes?.data?.find(
            (attribute: any) => attribute.attributeId === item.AId
          );
          if (data) select.push({ label: data.name, value: data.attributeId });
        });
        sellerProductResponse?.sellerProductDetails?.value[0]?.Variants.forEach(
          (attribute: any) => {
            let obj: any = {};
            obj["VariantId"] = attribute.Id;
            obj["Quantity"] = attribute.Quantity;
            obj["ActualPrice"] = attribute.ActualPrice;
            obj["BuyNowPrice"] = attribute.BuyNowPrice;
            obj["VariantsCollection"] = JSON.parse(attribute.Variant);

            Variants.push(obj);
          }
        );
        let images: any = [];
        if (sellerProductResponse?.sellerProductDetails?.value[0].Images) {
          sellerProductResponse?.sellerProductDetails?.value[0].Images?.forEach(
            (image: any) => {
              if (image.AttributeId)
                images.push({
                  AttributeId: image.AttributeId,
                  AttributeOption: image.AttributeOption,
                  id: image.Id,
                  ImgName: image.ImageName,
                });
            }
          );
        }
        setSellingData({
          ...sellingData,
          quantity: null,
          actualPrice: null,
          buyNowPrice: null,
          reservePrice: null,
          letBuyerMakeOffer:false,
          AutomaticallyAcceptOffersAtleast: null,
          AutomaticallyDeclineOffersLowerThan: null,
          Variant: { Variants: Variants, Images: images },
        });
        calculateSelectedColors(Variants);
        setSelected(select);
      }
    }
  }, [
    sellerProductResponse?.attributes,
    sellerProductResponse?.sellerProductDetails,
  ]);
  const getAttributeOptions = async () => {
    let updatedOptions: any = [...attributesOptions];
    let selectedOptions: any = [];

    let options = [];
    const promises: any = [];
    selected.forEach(async (element: any) => {
      if (element.label === "Color") setColorID(element.value);
      promises.push(fetchAttributeOption(element.value));
    });
    options = await Promise.all(promises);
    options?.map((item, Index) => {
      let newData: any = [];
      item?.data?.forEach((data: string) => {
        newData.push({
          label: data,
          value: data,
          id: selected[Index]["value"],
        });
      });
      if (item?.data === null)
        updatedOptions["A" + selected[Index]["value"] + "A"] = [];
      else updatedOptions["A" + selected[Index]["value"] + "A"] = newData;
      selectedOptions[selected[Index]["label"]] = [];
    });
    setAttributesOptions(updatedOptions);
    setSeletectedAttributesOptions(selectedOptions);
  };

  useEffect(() => {
    if (selected.length > 0) {
      getAttributeOptions();
    }
    handleChangeTableWithAttributes(selected);
  }, [selected]);
  useEffect(() => {
    if (
      sellerProductResponse?.sellerProductDetails?.value?.length > 0 &&
      sellingData.actualPrice == null
    ) {
      setSellingData({
        ...sellingData,
        productId:
          localStorage.getItem("productId") !== null
            ? localStorage.getItem("productId")
            : productId,
        format: sellerProductResponse?.sellerProductDetails?.value[0].Format
          ? sellerProductResponse?.sellerProductDetails?.value[0].Format
          : 1,
        duration: sellerProductResponse?.sellerProductDetails?.value[0].Duration
          ? sellerProductResponse?.sellerProductDetails?.value[0].Duration
          : null,
        listingOnSave:
          sellerProductResponse?.sellerProductDetails?.value[0].ListingOnSave ==
            true ||
          sellerProductResponse?.sellerProductDetails?.value[0].ListingOnSave ==
            null
            ? true
            : false,
        listingDate: sellerProductResponse?.sellerProductDetails?.value[0]
          .ListingDate
          ? sellerProductResponse?.sellerProductDetails?.value[0].ListingDate
          : null,
        actualPrice: sellerProductResponse?.sellerProductDetails?.value[0]
          .ActualPrice
          ? sellerProductResponse?.sellerProductDetails?.value[0].ActualPrice
          : null,
        buyNowPrice: sellerProductResponse?.sellerProductDetails?.value[0]
          .BuyNowPrice
          ? sellerProductResponse?.sellerProductDetails?.value[0].BuyNowPrice
          : null,
        reservePrice: sellerProductResponse?.sellerProductDetails?.value[0]
          .ReservePrice
          ? sellerProductResponse?.sellerProductDetails?.value[0].ReservePrice
          : null,
        letBuyerMakeOffer:
          sellerProductResponse?.sellerProductDetails?.value[0]
            .LetBuyerMakeOffer !== null
            ? sellerProductResponse?.sellerProductDetails?.value[0]
                .LetBuyerMakeOffer
            : false,
        AutomaticallyAcceptOffersAtleast: sellerProductResponse
          ?.sellerProductDetails?.value[0].AutomaticallyAcceptOffersAtleast
          ? sellerProductResponse?.sellerProductDetails?.value[0]
              .AutomaticallyAcceptOffersAtleast
          : null,
        AutomaticallyDeclineOffersLowerThan: sellerProductResponse
          ?.sellerProductDetails?.value[0].AutomaticallyDeclineOffersLowerThan
          ? sellerProductResponse?.sellerProductDetails?.value[0]
              .AutomaticallyDeclineOffersLowerThan
          : null,
        quantity: sellerProductResponse?.sellerProductDetails?.value[0].Quantity
          ? sellerProductResponse?.sellerProductDetails?.value[0].Quantity
          : null,
        sellAsLot:
          sellerProductResponse?.sellerProductDetails?.value[0].SellAsLot !==
          null
            ? sellerProductResponse?.sellerProductDetails?.value[0].SellAsLot
            : false,
        LotQuantity: sellerProductResponse?.sellerProductDetails?.value[0]
          .LotQuantity
          ? sellerProductResponse?.sellerProductDetails?.value[0].LotQuantity.toString()
          : null,
        isPrivateListing:
          sellerProductResponse?.sellerProductDetails?.value[0]
            .IsPrivateListing !== null
            ? sellerProductResponse?.sellerProductDetails?.value[0]
                .IsPrivateListing
            : false,
        requireImmediatePayment:
          sellerProductResponse?.sellerProductDetails?.value[0]
            .RequireImmediatePayment !== null
            ? sellerProductResponse?.sellerProductDetails?.value[0]
                .RequireImmediatePayment
            : false,
        salesTax: sellerProductResponse?.sellerProductDetails?.value[0].SalesTax
          ? sellerProductResponse?.sellerProductDetails?.value[0].SalesTax
          : null,
        salesTaxApplicableForShippingAndHandling:
          sellerProductResponse?.sellerProductDetails?.value[0]
            .SalesTaxApplicableForShippingAndHandling !== null
            ? sellerProductResponse?.sellerProductDetails?.value[0]
                .SalesTaxApplicableForShippingAndHandling
            : false,
        isDomesticReturnAccepted:
          sellerProductResponse?.sellerProductDetails?.value[0]
            .IsDomesticReturnAccepted !== null
            ? sellerProductResponse?.sellerProductDetails?.value[0]
                .IsDomesticReturnAccepted
            : false,
        isInternationalReturnAccepted:
          sellerProductResponse?.sellerProductDetails?.value[0]
            .IsInternationalReturnAccepted !== null
            ? sellerProductResponse?.sellerProductDetails?.value[0]
                .IsInternationalReturnAccepted
            : false,
        relistIfNotSold:
          sellerProductResponse?.sellerProductDetails?.value[0]
            .RelistIfNotSold !== null
            ? sellerProductResponse?.sellerProductDetails?.value[0]
                .RelistIfNotSold
            : false,
        DeliveryInDays: sellerProductResponse?.sellerProductDetails?.value[0]
          .DeliveryInDays
          ? sellerProductResponse?.sellerProductDetails?.value[0].DeliveryInDays
          : null,
        ReturnInDays: sellerProductResponse?.sellerProductDetails?.value[0]
          .ReturnInDays
          ? sellerProductResponse?.sellerProductDetails?.value[0].ReturnInDays
          : null,
        BuyerPaysForReturnShipping:
          sellerProductResponse?.sellerProductDetails?.value[0]
            .BuyerPaysForReturnShipping !== null
            ? sellerProductResponse?.sellerProductDetails?.value[0]
                .BuyerPaysForReturnShipping
            : false,
        // BiddingEndDate: sellerProductResponse?.sellerProductDetails?.value[0]
        //   .
        //   ? sellerProductResponse?.sellerProductDetails?.value[0].
        //   : "",
        // Isdraft: sellerProductResponse?.sellerProductDetails?.value[0].Format
        //   ? sellerProductResponse?.sellerProductDetails?.value[0].Format
        //   : false,
      });

      sellerProductResponse?.sellerProductDetails?.value[0].Format === 2
        ? setEditableDuration(true)
        : setEditableDuration(false);

      sellerProductResponse?.sellerProductDetails?.value[0]
        .LetBuyerMakeOffer === true
        ? setAutoOfferHidden(false)
        : setAutoOfferHidden(true);
      sellerProductResponse?.sellerProductDetails?.value[0].SellAsLot === true
        ? setLotQuantityHidden(false)
        : setLotQuantityHidden(true);
      if (
        sellerProductResponse?.sellerProductDetails?.value[0]?.ListingDate !==
        null
      ) {
        // setScheduleDateFormat({
        //   scheduleDate: numberPad(
        //     sellerProductResponse?.sellerProductDetails?.value[0].ListingDate.substring(
        //       0,
        //       10
        //     )
        //   ),
        //   scheduleTimeH: numberPad(
        //     new Date(
        //       sellerProductResponse?.sellerProductDetails?.value[0].ListingDate
        //         ? sellerProductResponse?.sellerProductDetails?.value[0]
        //             .ListingDate
        //         : ""
        //     ).getHours()
        //   ),
        //   scheduleTimeM: numberPad(
        //     new Date(
        //       sellerProductResponse?.sellerProductDetails?.value[0].ListingDate
        //         ? sellerProductResponse?.sellerProductDetails?.value[0]
        //             .ListingDate
        //         : ""
        //     ).getMinutes()
        //   ),
        //   scheduleTimeT: "00",
        // });
        setScheduleISDisabled(false);
      }
    }
  }, [sellerProductResponse.sellerProductDetails]);
  useEffect(() => {
    if (sellerProductResponse.saveData != null) {
      if (sellerProductResponse.saveData.success == "Y") {
        notify(sellerProductResponse.saveData.message, constant.SUCCESS);
        if(draft)
        {
         navigate("/seller/draftListing");
        }
        else
        {
        if (sellerProductResponse.saveData.productId === undefined)
          dispatch<any>(
            getSellerProductDetails(localStorage.getItem("productId"))
          ).then(nextStep());

        }
      } else if (sellerProductResponse.saveData.success == "N") {
        notify(sellerProductResponse.saveData.message, constant.ERROR);
      }
      dispatch<any>(reset("saveData"));
    }
  }, [sellerProductResponse.saveData]);

  const numberPad = (d: any) => {
    return d < 10 ? "0" + d.toString() : d.toString();
  };

  const submitFormData = (values: any) => {
    let data = { ...values };

    let imageValidated=true;
if(values.Variant.Variants.length >0)
{
 selectedColors.forEach((element:any) => {
 let check= values.Variant.Images.find((item:any)=>item.AttributeOption==element )
 if(check===undefined)
 imageValidated=false;
 });
}

    if (values.Variant.Variants.length == 0) data.Variant = null;

if(imageValidated)
    dispatch<any>(saveSellingDetails(JSON.stringify(data)));
    else
    notify("Image required for color", constant.ERROR)
  };

  const goToPrevious = (e: any) => {
    e.preventDefault();

    prevStep();
  };


  const handleTableChange = (e: any, index: number) => {
    let data = [...sellingData.Variant?.Variants];
    if(e.target.name==="BuyNowPrice")
    {
      data[index][e.target.name] = e.target.value==="" ? null :e.target.value ;

    }
    else
    data[index][e.target.name] = e.target.value;

    setSellingData({
      ...sellingData,
      Variant: { Variants: data, Images: sellingData.Variant.Images },
    });
  };
  const handleStatusChange = async (param: any) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleTableDelete(param),
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const handleTableDelete = async (index: number) => {
    let data = [...sellingData.Variant?.Variants];
    // let deleted = data.splice(index, 1);
    let deleted = data[index];
    if (deleted.VariantId) {
      let response = await delVariant(deleted.VariantId);
      if (response?.success === "Y") {
        data.splice(index, 1);
        notify(response?.message, constant.SUCCESS);
      } else
        notify(
          response?.message ? response?.message : "Something went wrong",
          constant.ERROR
        );
    } else data.splice(index, 1);

    setSellingData({
      ...sellingData,
      Variant: { Variants: data, Images: sellingData.Variant.Images },
    });
    if(data.length===0)
    {
      setSelectedColors([])
    }
  };
  const handleSelectOptions = (index: number, values: any, AId: number) => {
    if (values.length > 0) {
      let data = [...sellingData.Variant?.Variants];
      // data[index][e.target.name] = e.target.value;
      let newData: any = [];
      data[index]["VariantsCollection"].forEach((element: any) => {
        if (element.AId === AId)
          newData.push({
            AId: element.AId,
            OpName: values[0].label,
          });
        else newData.push(element);
      });

      data[index]["VariantsCollection"] = newData;
      setSellingData({
        ...sellingData,
        Variant: { Variants: data, Images: sellingData.Variant.Images },
      });

      calculateSelectedColors(data);
    }
  };
  const calculateSelectedColors = (data: any) => {
    let selected: any = [];
    data.forEach((element: any) => {
      let color = element.VariantsCollection.find(
        (item: any) => item.AId === colorID
      );

      let colorAlreadyPresent = selected.find(
        (item: any) => item === color.OpName
      );
      if (colorAlreadyPresent === undefined && color) {
        selected.push(color.OpName);
      }
    });
    setSelectedColors(selected);
  };

  const handleChangeTableWithAttributes = (attributes: any) => {
    let data = [...sellingData.Variant?.Variants];
    data.forEach((variant: any, Index: number) => {
      let newOptions: any = [];
      selected.forEach((element: any) => {
        if (
          variant.VariantsCollection.find(
            (attri: any) => attri.AId === element.value
          ) === undefined
        )
          newOptions.push({
            AId: element.value,
            OpName: "",
            name: element.label,
          });
        else {
          let prevValue = variant.VariantsCollection.find(
            (attri: any) => attri.AId === element.value
          );
          newOptions.push(prevValue);
        }
      });
      data[Index]["VariantsCollection"] = newOptions;
    });

    setSellingData({
      ...sellingData,
      Variant: { Variants: data, Images: sellingData.Variant.Images },
    });
  };
  const addRowToTable = () => {
    let obj: any = {};
    obj["VariantId"] = null;
    obj["Quantity"] = "";
    obj["ActualPrice"] = "";
    obj["BuyNowPrice"] = "";
    let variantsCollection: any = [];
    selected.forEach((item: any) => {
      // obj[name] = product[i];
      variantsCollection.push({
        AId: item.value,
        OpName: "",
        // name: item.label,
      });
    });

    obj["VariantsCollection"] = variantsCollection;
    let data: any = [...sellingData.Variant.Variants];
    data.push(obj);
    setSellingData({
      ...sellingData,
      quantity: null,
      actualPrice: null,
      buyNowPrice: null,
      reservePrice: null,
      letBuyerMakeOffer:false,
      AutomaticallyAcceptOffersAtleast: null,
      AutomaticallyDeclineOffersLowerThan: null,

      Variant: { Variants: data, Images: sellingData.Variant.Images },
    });
    setAutoOfferHidden(true);
  };
  // const handleTableDelete = (index: number) => {
  //   let data = [...combination];
  //   data.splice(index, 1);
  //   setCombination(data);
  //   setSellingData({
  //     ...sellingData,
  //     Variant: { Variants: data, Images: [] },
  //   });
  // };

  const handleChangeImage = async (e: any, color: string) => {
    if (e.target.files) {
      if (e.target.files[0] != undefined) {
        if (e.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
          const formData = new FormData();
          const data = {
            AttributeId: colorID,
            AttributeOption: color,
            ProductId: sellingData.productId,
          };
          formData.append("file", e.target.files[0]);
          formData.append("varaint", JSON.stringify(data));
          let response = await postAttributeImage(formData);
          let images: any = [...sellingData.Variant.Images];
          if (response?.success === "Y")
            images.push({
              AttributeId: colorID,
              AttributeOption: color,
              id: response.id,
              temporary: true,
              ImgName: response.imgName,
            });
          setSellingData({
            ...sellingData,
            Variant: { Variants: sellingData.Variant.Variants, Images: images },
          });
        }
      }
    }
  };
  const handleDeleteImage = async (id: number, image: any) => {
    let response = await deleteVariantImage({
      id: id,
      productId: sellingData.productId,
      temporary: image.temporary !== undefined ? "True" : "False",
    });
    if (response.success === "Y") {
      let images: any = [...sellingData.Variant.Images];
      let index = images.findIndex((image: any) => image.id === id);
      images.splice(index, 1);
      setSellingData({
        ...sellingData,
        Variant: { Variants: sellingData.Variant.Variants, Images: images },
      });
    }
  };

  const onChangeListingDate=(e:any)=>{
    let newDate=new Date(e.target.value);
    let isoDate=new Date(newDate.toUTCString()).toISOString();
    setSellingData({
      ...sellingData,
      listingDate: isoDate,
    });
  }
  return (
    <>
      <ToastContainer />
      <div className="tabs-nav-block">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <div
              className="nav-link "
              style={{
                cursor: "pointer",
              }}
              onClick={() => setstep(1)}
            >
              Listing detail
            </div>
          </li>
          <li className="nav-item active">
            <div
              className="nav-link active"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.Format !== null &&
                setstep(2)
              }
            >
              Selling details
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.DomesticCourierServiceId !== null &&
                setstep(3)
              }
            >
              Shipping details
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.VolumePricing &&
                setstep(4)
              }
            >
              Sell it faster
            </div>
          </li>
        </ul>
      </div>
      <Formik
        initialValues={sellingData}
        validationSchema={SellingSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          let data = { ...values };
          if (submitAction === "primary") {
          
            data.Isdraft = true;
            submitFormData(data);
            setDraft(true);

          } else {
            // set state to show warning message
            if( sellerProductResponse?.sellerProductDetails?.value[0].IsListingActive==null || sellerProductResponse?.sellerProductDetails?.value[0].IsListingActive==false)
             data.Isdraft = true;

            submitFormData(data);
          }
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <>
            <Form>
              <div className="seller-form-block">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Format
                        </label>
                        <select
                          className="form-control input-seller"
                          name="format"
                          onChange={onChange}
                          value={sellingData.format}
                        >
                          <option value="2">Auction-style</option>
                          <option value="1">Fixed price</option>
                        </select>
                        {errors.format && touched.format ? (
                          <div className="required">{errors.format}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {editableDuration && (
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="label-seller">
                            <span className="red-star">*</span>Duration
                          </label>
                          <select
                            className="form-control input-seller"
                            name="duration"
                            onChange={onChange}
                            value={
                              sellingData.duration === null
                                ? "3"
                                : sellingData.duration
                            }
                          >
                            <option value="3">3 days</option>
                            <option value="5">5 days</option>
                            <option value="7">7 days</option>
                            <option value="10">10 days</option>
                          </select>
                          {errors.duration && touched.duration ? (
                            <div className="required">{errors.duration}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-3">
                      <div className="ch-item seller-ch-item">
                        <p>
                          <input
                            type="radio"
                            id="scheduleNow"
                            value="yes"
                            name="listingOnSave"
                            defaultChecked={sellingData.listingOnSave}
                            onChange={onRadioChange}
                            checked={
                              sellingData.listingOnSave === false ? false : true
                            }
                          />
                          <label htmlFor="scheduleNow">
                            Start my listings when I submit them
                          </label>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="ch-item seller-ch-item">
                        <p>
                          <input
                            type="radio"
                            id="scheduleDate"
                            value="no"
                            name="listingOnSave"
                            onChange={onRadioChange}
                            checked={
                              !sellingData.listingOnSave === false
                                ? false
                                : true
                            }
                          />
                          <label htmlFor="scheduleDate">
                            Schedule to start on
                          </label>
                        </p>
                      </div>
                      <div className="form-group form-group-schedule">
                        {/* <input
                          type="date"
                          className="form-control input-seller"
                          disabled={scheduleIsDisabled}
                          name="scheduleDate"
                          min={minDate}
                          value={scheduleDateFormat.scheduleDate}
                          onChange={scheduleDateHandle}
                        /> */}
                        {/* <select
                          className="form-control input-seller"
                          name="scheduleTimeH"
                          disabled={scheduleIsDisabled}
                          onChange={scheduleDateHandle}
                          value={scheduleDateFormat.scheduleTimeH}
                        >
                          {!scheduleIsDisabled &&
                            (() => {
                              let optionsM = [];
                              for (let index = 0; index < 24; index++) {
                                optionsM.push(
                                  <option value={index} key={index}>
                                    {numberPad(index)}
                                  </option>
                                );
                              }
                              return optionsM;
                            })()}
                        </select>
                        <select
                          className="form-control input-seller"
                          name="scheduleTimeM"
                          disabled={scheduleIsDisabled}
                          onChange={scheduleDateHandle}
                          value={scheduleDateFormat.scheduleTimeM}
                        >
                          {(() => {
                            let optionsH = [];
                            for (let index = 0; index < 60; index++) {
                              if (index % 5) continue;
                              optionsH.push(
                                <option value={index} key={index}>
                                  {numberPad(index)}
                                </option>
                              );
                            }
                            return optionsH;
                          })()}
                        </select> */}
                        <input type="datetime-local" disabled={scheduleIsDisabled}  name="listingDate" value={sellingData?.listingDate? moment.utc(sellingData?.listingDate).local().format('YYYY-MM-DDTHH:mm') :""} onChange={onChangeListingDate} style={{width:"100%" , padding:4, fontSize:14}} id="birthdaytime"   />
                        {sellingData?.listingDate!==null && sellingData?.listingDate!=="" && moment.utc(sellingData?.listingDate).local().isBefore(new Date) && (
                          <div className="required">ListingDate cannot be less than current date</div>
                        ) 
                      
}
{!scheduleIsDisabled && (sellingData?.listingDate==null || sellingData?.listingDate=="") && (
                          <div className="required">*Required!</div>
                        ) 
}
                        {/* <select
                          className="form-control input-seller"
                          name="scheduleTimeT"
                          disabled={scheduleIsDisabled}
                          onChange={scheduleDateHandle}
                        >
                          <option>AM</option>
                          <option>PM</option>
                        </select> */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-xs-12">
                      <label className="label-seller">Price ($)</label>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <label className="label-seller item-specify">
                        <span className="red-star">*</span>Price ($)
                      </label>
                      <input
                        type="number"
                        name="actualPrice"
                        disabled={
                          sellingData.Variant.Variants.length > 0 ? true : false
                        }
                        onChange={(e) =>
                          setSellingData({
                            ...sellingData,
                            [e.target.name]:
                              e.target.value == "" &&
                              sellingData.Variant.Variants.length > 0
                                ? null
                                : e.target.value,
                          })
                        }
                        value={
                          sellingData.actualPrice === null
                            ? ""
                            : sellingData.actualPrice
                        }
                        className="form-control input-seller"
                      />
                      {errors.actualPrice && touched.actualPrice ? (
                        <div className="required">{errors.actualPrice}</div>
                      ) : null}
                      {(submitClicked && (sellingData.actualPrice==null || sellingData.actualPrice=="" ) && sellingData.Variant.Variants.length == 0)? (
                        <div className="required">*Required!</div>
                      ) : null}
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <label className="label-seller item-specify">
                        Buy it now price ($)
                      </label>
                      <input
                        type="number"
                        name="buyNowPrice"
                        disabled={
                         ( sellingData.Variant.Variants.length > 0 || sellingData?.format==2) ? true : false
                        }
                        onChange={(e) =>
                          setSellingData({
                            ...sellingData,
                            [e.target.name]:
                              e.target.value === "" ? null : e.target.value,
                          })
                        }
                        value={
                          sellingData.buyNowPrice === null
                            ? ""
                            : sellingData.buyNowPrice
                        }
                        className="form-control input-seller"
                      />
                      {errors.buyNowPrice && touched.buyNowPrice ? (
                        <div className="required">{errors.buyNowPrice}</div>
                      ) : null}
                    </div>
                    {/* <div className="col-lg-2 col-md-3">
                      <label className="label-seller item-specify">
                        Reserve Price ($)
                      </label>
                      <input
                        type="number"
                        name="reservePrice"
                        onChange={(e) =>
                          setSellingData({
                            ...sellingData,
                            [e.target.name]:
                              e.target.value === "" ? null : e.target.value,
                          })
                        }
                        disabled={
                          sellingData.Variant.Variants.length > 0 ? true : false
                        }
                        value={
                          sellingData.reservePrice === null
                            ? ""
                            : sellingData.reservePrice
                        }
                        className="form-control input-seller"
                      />
                      {errors.reservePrice && touched.reservePrice ? (
                        <div className="required">{errors.reservePrice}</div>
                      ) : null}
                    </div> */}
                { sellingData?.format==1 && sellingData?.Variant?.Variants.length==0 &&  <div className="col-lg-12 col-md-12 col-xs-12">
                      <div>&nbsp;</div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="buyerOffer"
                            name="letBuyerMakeOffer"
                            onChange={onCheckboxChange}
                            checked={
                              sellingData.letBuyerMakeOffer == true
                                ? true
                                : false
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="buyerOffer"
                          >
                           Buyers can submit offers, which can improve your chances of selling by 3-7%, as observed from recent individual seller listings.
                          </label>
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="row form-group" hidden={autoOfferHidden}>
                    <div className="col-lg-3 col-md-4">
                      <label className="label-seller item-specify">
                        Automatically accept offers at least (in $)
                      </label>
                      <input
                        type="number"
                        name="AutomaticallyAcceptOffersAtleast"
                        onChange={(e) =>
                          setSellingData({
                            ...sellingData,
                            [e.target.name]:
                              e.target.value == "" ? null : e.target.value,
                          })
                        }
                        value={
                          sellingData.AutomaticallyAcceptOffersAtleast === null
                            ? ""
                            : sellingData.AutomaticallyAcceptOffersAtleast
                        }
                        className="form-control input-seller"
                      />

                      {errors.AutomaticallyAcceptOffersAtleast &&
                      touched.AutomaticallyAcceptOffersAtleast ? (
                        <div className="required">
                          {errors.AutomaticallyAcceptOffersAtleast}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <label className="label-seller item-specify">
                        Automatically decline offers lower than (in $)
                      </label>
                      <input
                        type="number"
                        name="AutomaticallyDeclineOffersLowerThan"
                        onChange={(e) =>
                          setSellingData({
                            ...sellingData,
                            [e.target.name]:
                              e.target.value == "" ? null : e.target.value,
                          })
                        }
                        value={
                          sellingData.AutomaticallyDeclineOffersLowerThan ===
                          null
                            ? ""
                            : sellingData.AutomaticallyDeclineOffersLowerThan
                        }
                        className="form-control input-seller"
                      />
                      {errors.AutomaticallyDeclineOffersLowerThan &&
                      touched.AutomaticallyDeclineOffersLowerThan ? (
                        <div className="required">
                          {errors.AutomaticallyDeclineOffersLowerThan}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 mt-1">
                      <label className="label-seller">
                        <span className="red-star">*</span>Quantity
                      </label>
                      <input
                        type="text"
                        name="quantity"
                        disabled={
                          sellingData.Variant.Variants.length > 0 ? true : false
                        }
                        onChange={(e) =>
                          setSellingData({
                            ...sellingData,
                            [e.target.name]:
                              e.target.value == "" ? null : e.target.value,
                          })
                        }
                        value={
                          sellingData.quantity === null
                            ? ""
                            : sellingData.quantity
                        }
                        className="form-control input-seller"
                      />
                      {errors.quantity && touched.quantity ? (
                        <div className="required">{errors.quantity}</div>
                      ) : null}
                      {submitClicked && (sellingData.quantity === null || sellingData.quantity === "" ) && sellingData.Variant.Variants.length ==0 &&
                        <div className="required">*Required!</div>
                      }
                    </div>
                 {/* {sellingData?.format==1 &&   <div className="col-lg-12 col-md-12 col-xs-12">
                      <div>&nbsp;</div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="sellAlot"
                            name="sellAsLot"
                            onChange={onCheckboxChange}
                            checked={
                              sellingData.sellAsLot === false ? false : true
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="sellAlot"
                          >
                            Sell as lot
                          </label>
                        </div>
                      </div>
                    </div>} */}
                    {/* <div
                      className="col-md-3 form-group"
                      hidden={lotQuantityHidden}
                    >
                      <label className="label-seller">Lot Quantity</label>
                      <input
                        type="text"
                        name="LotQuantity"
                        onChange={onChange}
                        value={
                          sellingData.LotQuantity === null
                            ? ""
                            : sellingData.LotQuantity
                        }
                        className="form-control input-seller"
                        placeholder="enter value"
                      />
                          {(sellingData?.LotQuantity===null || sellingData?.LotQuantity==="" ) && (
                        <div className="required">*Required!</div>
                      ) }
                    </div> */}
                  </div>
                  {sellingData.format == 1 && (
                    <div>
                      {" "}
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">
                          <label className="label-seller">
                            Product variation
                          </label>
                        </div>
                        <div className="col-md-3">
                          <label className="label-seller item-specify">
                            Attributes(Should be unique for all variants)
                          </label>
                          {/* <MultiSelect
                            options={attributes}
                            value={selected}
                            onChange={setSelected}
                            labelledBy="Select"
                            // isCreatable
                            // ClearIcon={false}
                            className=""
                          /> */}
                          <Select
                            multi={true}
                            options={attributes}
                            values={selected}
                            onChange={(values) => setSelected(values)}
                          />
                        </div>
                      </div>
                      {
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              {selected?.map((item: any) => (
                                <th scope="col"><span className="red-star">*</span>{item.label}</th>
                              ))}
                              <th scope="col"><span className="red-star">*</span>Quantity</th>
                              <th scope="col"><span className="red-star">*</span>Actual price($)</th>
                              <th scope="col">Buy it now price($)</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sellingData?.Variant?.Variants?.map(
                              (combo: any, Index: number) => (
                                <tr>
                                  {combo?.VariantsCollection?.map(
                                    (variant: any) => (
                                      <td>
                                        <Select
                                          options={
                                            attributesOptions[
                                              "A" + variant.AId + "A"
                                            ]
                                              ? attributesOptions[
                                                  "A" + variant.AId + "A"
                                                ]
                                              : []
                                          }
                                          create={true}
                                          values={[
                                            {
                                              value: variant.OpName,
                                              label: variant.OpName,
                                            },
                                          ]}
                                          onChange={(values) =>
                                            handleSelectOptions(
                                              Index,
                                              values,
                                              variant.AId
                                            )
                                          }
                                        />
                                      </td>
                                    )
                                  )}
                                  <td>
                                    <input
                                      type="number"
                                      name="Quantity"
                                      min="1"
                                      value={combo.Quantity}
                                      style={{ width: "100%" }}
                                      onChange={(e) =>
                                        handleTableChange(e, Index)
                                      }
                                    />

                                    {combo.Quantity < 1 ||
                                      (combo.Quantity > 32767 && (
                                        <p>
                                          The number must be greater than 0 and
                                          less than 32767!
                                        </p>
                                      ))}
                                  </td>

                                  <td>
                                    <input
                                      type="number"
                                      name="ActualPrice"
                                      min="0"
                                      step="any"
                                      value={combo.ActualPrice}
                                      style={{ width: "100%" }}
                                      onChange={(e) =>
                                        handleTableChange(e, Index)
                                      }
                                    />
                                    {(combo.ActualPrice < 0.1 ||
                                      combo.ActualPrice > 9999999999.99) && (combo.ActualPrice > combo.BuyNowPrice) &&(
                                        <p>
                                          The number must be greater than 0 and
                                          less than 10000000000!
                                        </p>
                                      )}
                                      {combo.ActualPrice <= combo.BuyNowPrice &&
                                        <p>Actual price cannot be less than or equal to buy it now price</p>
                                      }
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="BuyNowPrice"
                                      min="0"
                                      step="any"

                                      value={combo.BuyNowPrice ? combo.BuyNowPrice :""}
                                      style={{ width: "100%" }}
                                      onChange={(e) =>
                                        handleTableChange(e, Index)
                                      }
                                    />
                                    {combo.BuyNowPrice < 0.1 ||
                                      (combo.BuyNowPrice > 9999999999.99 && (
                                        <p>
                                          The number must be greater than 0 and
                                          less than 10000000000!
                                        </p>
                                      ))}
                                  </td>
                                  <td>
                                    {" "}
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => handleStatusChange(Index)}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                            <tr>
                              <td>
                                {" "}
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => addRowToTable()}
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      }
                   { selectedColors.length>0 &&  <label className="label-seller item-specify">
                   <span className="red-star">*</span> Colors
                      </label>}
                      {selectedColors.length>0 &&  selectedColors.map((color: any) => {
                        let totalImages: any = [];
                        sellingData.Variant?.Images?.map((image: any) => {
                          if (image.AttributeOption === color)
                            totalImages.push(image);
                        });
                        return (
                          <div className="imageUpload">
                            <div className="row">
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  className="form-control input-seller"
                                  disabled={true}
                                  name="color"
                                  value={color}
                                />
                                {/* <select
                          className="form-control input-seller"
                          value={colorOption}
                          onChange={(e: any) => setColorOption(e.target.value)}
                        >
                          <option value={""}>Select Color</option>

                          {selectedColors.map((color: any) => (
                            <option value={color}>{color}</option>
                          ))}
                        </select> */}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-8">
                                <div className="upload__box">
                                  <div className="upload__btn-box">
                                    <label className="label-seller item-specify">
                                    <span className="red-star">*</span> Upload Image
                                    </label>

                                    {totalImages.length <= 4 && (
                                      <div className="input-group mb-3 w-50">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Upload
                                          </span>
                                        </div>
                                        <div className="custom-file">
                                          <input
                                            type="file"
                                            className="custom-file-input upload__inputfile"
                                            id="inputGroupFile01"
                                            // multiple={true}
                                            onChange={(e: any) =>
                                              handleChangeImage(e, color)
                                            }
                                            accept="image/png,image/jpeg,image/jpg"
                                            data-max_length="20"
                                          />
                                          <label
                                            className="custom-file-label"
                                            htmlFor="inputGroupFile01"
                                          >
                                            Choose file
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="upload__img-wrap upload__image-wrapper">
                                    <div className="d-flex image-flex-wrapper">
                                      {sellingData.Variant?.Images.map(
                                        (image: any, index: number) => {
                                          if (image.AttributeOption === color)
                                            return (
                                              <div
                                                key={index}
                                                className="image-item "
                                              >
                                                <img
                                                  src={
                                                    BASE_URLS.AWS_URL +
                                                    image.ImgName
                                                  }
                                                  alt=""
                                                  width="100"
                                                  height="120"
                                                />
                                                <div className="d-flex justify-content-between">
                                                  <div
                                                    className="remove-img-close"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      handleDeleteImage(
                                                        image.id,
                                                        image
                                                      );
                                                    }}
                                                  >
                                                    <img src={CROSS} alt="" />
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          else return null;
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* <div className="row">
                    <div className="col-md-3">
                      <label className="label-seller">Private listing</label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-xs-12">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="privateListing"
                            name="isPrivateListing"
                            onChange={onCheckboxChange}
                            checked={
                              sellingData.isPrivateListing === false
                                ? false
                                : true
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="privateListing"
                          >
                            Private listing
                          </label>
                          {errors.isPrivateListing &&
                          touched.isPrivateListing ? (
                            <div className="required">
                              {errors.isPrivateListing}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    {/* <div className="col-lg-3">
                      <label className="label-seller">Payment options</label>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="requirePaymentoption"
                            name="requireImmediatePayment"
                            onChange={onCheckboxChange}
                            checked={
                              sellingData.requireImmediatePayment === false
                                ? false
                                : true
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="requirePaymentoption"
                          >
                            Require immediate payment with Buy it Now
                          </label>
                          {errors.requireImmediatePayment &&
                          touched.requireImmediatePayment ? (
                            <div className="required">
                              {errors.requireImmediatePayment}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-3">
                      <label className="label-seller">
                        <span className="red-star">*</span>Sales tax
                      </label>
                      <div className="form-group form-group-schedule pb-0">
                        <input
                          type="number"
                          name="salesTax"
                          onChange={onChange}
                          value={
                            sellingData.salesTax === null
                              ? ""
                              : sellingData.salesTax
                          }
                          className="form-control input-seller"
                          placeholder="%"
                        />
                        {errors.salesTax && touched.salesTax ? (
                          <div className="required">{errors.salesTax}</div>
                        ) : null}
                      </div>
                      {/* <div className="custom-control custom-checkbox custom-control-inline">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="salesTaxApplicableForShippingAndHandling"
                          onChange={onCheckboxChange}
                          checked={
                            sellingData.salesTaxApplicableForShippingAndHandling ===
                            false
                              ? false
                              : true
                          }
                          id="saleapplicableHandling"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="saleapplicableHandling"
                        >
                          Also apply to shipping and handling costs
                        </label>
                      </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <label className="label-seller">Return options</label>
                      <div className="form-group pb-0 mb-0">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="isDomesticReturnAccepted"
                            onChange={onCheckboxChange}
                            checked={
                              sellingData.isDomesticReturnAccepted === false
                                ? false
                                : true
                            }
                            id="isDomesticReturnAcceptedField"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="isDomesticReturnAcceptedField"
                          >
                            Domestic returns accepted
                          </label>
                        </div>
                      </div>
                      <div className="form-group pb-0 mb-0">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            name="isInternationalReturnAccepted"
                            onChange={onCheckboxChange}
                            checked={
                              sellingData.isInternationalReturnAccepted ===
                              false
                                ? false
                                : true
                            }
                            className="custom-control-input"
                            id="internationReturn"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="internationReturn"
                          >
                            International returns accepted
                          </label>
                        </div>
                      </div>
                      <div className="form-group pb-0 mb-0">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="BuyerPaysForReturnShipping"
                            onChange={onCheckboxChange}
                            checked={
                              sellingData.BuyerPaysForReturnShipping === false
                                ? false
                                : true
                            }
                            id="BuyerPaysForReturnShippingField"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="BuyerPaysForReturnShippingField"
                          >
                            Buyer pays for return shipping
                          </label>
                        </div>
                      </div>
                      <div className="row mt-3">
                        {/* <div className="col-md-3 form-group">
                          <label className="label-seller">
                            <span className="red-star">*</span> Delivery In Days
                          </label>
                          <input
                            type="number"
                            name="DeliveryInDays"
                            onChange={onChange}
                            value={
                              sellingData.DeliveryInDays === null
                                ? ""
                                : sellingData.DeliveryInDays
                            }
                            className="form-control input-seller"
                          />
                          {errors.DeliveryInDays && touched.DeliveryInDays ? (
                            <div className="required">
                              {errors.DeliveryInDays}
                            </div>
                          ) : null}
                        </div> */}

                        <div className="col-md-3 form-group">
                          <label className="label-seller">
                            <span className="red-star">*</span> Return In days
                          </label>
                          <input
                            type="number"
                            name="ReturnInDays"
                            onChange={onChange}
                            value={
                              sellingData.ReturnInDays === null
                                ? ""
                                : sellingData.ReturnInDays
                            }
                            className="form-control input-seller"
                          />
                          {errors.ReturnInDays && touched.ReturnInDays ? (
                            <div className="required">
                              {errors.ReturnInDays}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="col-lg-12">
                      <div className="item-group">
                        <small>
                          Returns will not be accepted unless you select
                          domestic or international return options above. The
                          item can always be returned if it doesn't match the
                          listing description.
                        </small>
                      </div>
                    </div>
                {sellingData?.format==2 &&     <div className="col-lg-12">
                      <div className="checkbox-seller">
                        <input
                          type="checkbox"
                          name="relistIfNotSold"
                          onChange={onCheckboxChange}
                          checked={
                            sellingData.relistIfNotSold === false ? false : true
                          }
                        />
                        <span>
                        This item will be automatically relisted up to 8 times if it remains unsold, with no additional charges for each relist. There are no insertion or optional listing upgrade fees involved.
                        </span>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>

              <div className="seller-bottom-block">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fees-block">
                        <div className="fee-text">
                          Fees:<i className="fee-icon"></i>{" "}
                          <span className="fee-price">{sellerProductResponse?.platformFees?.data?.platFormFee}%</span>
                        </div>
                      </div>
                      <div className="seller-bottom-buttons">
                        <div className="seller-btm-text">
                          By selecting List item, you agree to pay the above
                          fees; accept the eFindit{" "}
                          <a href="#">User agreement</a>,{" "}
                          <a href="#">Payments terms</a> of Use and Marketing
                          terms of Service; acknowledge reading the User Privacy
                          Notice; and assume full responsibility for the item
                          offered and the content of your listing.
                        </div>
                      </div>

                      <div className="seller-button">
                        <button
                          className="list-button"
                          type="button"
                          onClick={() => {
                            setSubmitClicked(true);
                            handleSubmit();
                          }}
                          name="submit"
                        >
                          Save & next
                        </button>
                        <button
                        type="button"
                          className="list-button bg-none"
                          onClick={goToPrevious}
                        >
                          Back
                        </button>
                        {/* <button className="list-button bg-none" name="preview">Preview</button> */}
                        <button
                          className="list-button bg-none"
                          type="button"
                          onClick={() => {
                            submitAction = "primary";
                            handleSubmit();
                          }}
                        >
                          Save as draft
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default SellingDetails;
