import React from "react";
import { Link } from "react-router-dom";

interface Props {
  currentPage: string;
}
const OrderNavPanel = ({ currentPage }: Props) => {
  return (
    <div className="col-md-2">
      <div className="leftNavBar">
        <ul>
          <li>
            <Link style={currentPage == "AllOrder" ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {}} to={"/seller/orders"}> All orders</Link>
          </li>
          <li>
            <Link style={
              currentPage == "AwaitingShip" ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {}
            } to={"/seller/awaitingShip"}> Awaiting shipment</Link>
          </li>

          <li>
            <Link style={
              currentPage == "PaidShipped" ? { background: "#d7d7d7", fontWeight: "450", color: "#000"  } : {}
            } to={"/seller/orderPaidShipped"}>Paid and shipped</Link>
          </li>
          <li>
            <Link style={currentPage == "Delivered" ? { background: "#d7d7d7", fontWeight: "450", color: "#000"  } : {}} to={"/seller/deliveredOrder"}>Delivered</Link>
          </li>
        </ul>

        <ul>
          <li>
            <Link style={
              currentPage == "Cancellation" ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {}
            } to={"/seller/orderCancellation"}>Cancellations</Link>
          </li>
          <li>
            <Link style={currentPage == "RetunOrder" ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {}} to={"/seller/orderReturn"}>Returns</Link>
          </li>

          {/* <li>
            <a href="#">Request and Disputes</a>
          </li> */}
        </ul>

        {/* <ul>
          <li>
            <a href="#">Return Preferences</a>
          </li>
          <li>
            <a href="#">Learning Resources</a>
          </li>
        </ul> */}
      </div>
    </div>
  );
};

export default OrderNavPanel;
