export const BASE_URLS = {
  // BASE_URL: 'http://localhost:3000/',
  BASE_URL: "https://api.efindit.net/api/",
  API_VERSION: "v1",
  AWS_URL: "https://aws-efindit.s3.amazonaws.com",
};

export const API_URLS = {
  CATEGORY_TEMPLATE_CRUD:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/ItemSpecification",
  CATEGORIES_CRUD: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Category",
  CATEGORIES_HEADER:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/category/categoryforbuyermenu",
    CMS_CRUD: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Cms",

  SOCIAL_MEDIA_CRUD:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/socialmedia",
  NOTES_UPLOAD_IMAGE:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/product/details/image",
  BUYER_DETAIL:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/User/BuyerDetail/",
  ADDRESS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/User/",
  BUYER_SEARCH:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/User/SearchBuyer/",
    PLATFORM_FEES:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/PlatFormFee/",
  SELLERS:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/User/Sellers?PageNumber=",
  SELLER_DETAIL:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/User/SellerDetail/",
  SELLER_SEARCH:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/User/SearchSeller/",
  SELLER_FEEDBACK: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/FeedBack/",
  SELLER_ORDER_FEEDBACK:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/SellerOrderFeedBack/",
  SELLER_STORE_FEEDBACK:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/sellerstore/mystore",
  PRODUCTS:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/Product?$select=ProductId,Title,ItemSpecification,Quantity,Active,CategoryId",
  PRODUCT_STATUS:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Product/Admin/Deactivate",
  PRODUCT_SEARCH:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/Product?$select=Title&$filter=contains(Title,'",
  ORDERS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Order",
  ORDER_DETAIL_BUYER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/order/buyerorderdetails/",
  ORDER_INVOICE:  BASE_URLS.BASE_URL + BASE_URLS.API_VERSION +"/invoice/order/",
  RETURN_ORDERS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/return",
  USER_STATUS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/User",
  DASHBOARD: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/DashBoard",
  COMMON: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Common",
  CHANGE_PASSWORD:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/ChangePassword",
  GET_COUNTRY: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Country",
  GET_STATE: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/State",
  PROFILE: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/User",
  PURCHASE_HISTORY:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Order/BuyerPurchaseHistory?",

  RECENTLY_VIEWED:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/RecentlyViewed",

  STORE_TRAFFIC:
  BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Store/Traffic",
    
  ADD_TO_CART: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/ShoppingCart",
  GET_CART_COUNT: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/shoppingcart/carttotatitems",
  CHECKOUT: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/checkout",

  SAVED_SEARCH: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Search/SavedSearch",
  GET_SAVED_SEARCH: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Search/GetSavedSearchDetails?",
  DELETE_SAVED_SEARCH: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Search/DeleteSavedSearch",
  DELETE_ALL_SAVED_SEARCH: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Search/DeleteAllSavedSearch",

  BUY_AGAIN: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Purchase?",
  BIDS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Bidding?",
  DEALS:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/DealsAndOffers/GetProducts",
  DEALS_HEADINGS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Deals",
  OFFERS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/BuyerOffer?",
  COUNTER_OFFER_SELLER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/sellercounteroffer",
  COUNTER_OFFER_BUYER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/buyercounteroffer",
  OFFER_ACTION_BUYER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/buyeroffer/acceptrejectselleroffer",



  
  SAVE_NEW_DEAL_SELLER:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/DealsAndOffers",
  ACCOUNT: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Account",
  PROFILE_PIC: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/ProfilePic",
  USER_LOGIN: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/SignIn",

  GET_REFRESH_TOKEN:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/RefreshToken",
    GET_VERIFICATION_LINK:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/EmailVerificationLink",
  DELETE_REFRESH_TOKEN:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/DeleteRefreshToken",
  REGISTER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Registration",
  FORGET_PASSWORD:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/GeneratePasswordLink",
  GET_SUGGESTION:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Search/ProductSuggestion?",
  GET_OTP: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/ResetPasswordOtp",
  VERIFY_OTP:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/VerifyResetPasswordOtp",
  RESET_PASSWORD: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/ResetPassword",
  GET_TRAFFIC:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Seller/DashBoardTraffic",
  GET_SELLER_DASHBOARD:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Seller/DashBoard",
  DASHBOARD_SALES:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Seller/DashBoardSales",
  SELLER_PRODUCT: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Product",
  ACCEPT_REJECT_OFFER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/buyeroffer/acceptrejectbuyeroffer",
  ACCEPT_REJECT_BID: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Bidding/acceptrejectbuyerbids",
  SELLER_STORE_PRODUCT: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/sellerstore/SearchSellerProducts?sellerid=",

  SELLER_PRODUCTS_BY_CATEGORY: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/product/sellerproducts",
  SELLER_OFFERS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Product/OfferActiveListing",
  SELLER_BIDS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Bidding/ProductsWithBidding",
  SELLER_PAYMENTS: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/payment/available",

  COURIER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Courier",

  SELLER_OFFERS_USER_LIST: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/sellerstore/GetSellerOfferUserListAsync",
  SELLER_BIDS_USER_LIST: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Bidding/UsersAndBidding",
  DEALS_ADDED_BY_SELLER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/DealsAndOffers/DealsName",

  DELETE_VARIANT:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/product/deleteproductvaraint/",

  GET_ATTRIBUTES:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/attribute/seller",

  SELLER_STORE:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/SellerStore?$select=StoreName,ProfilePic, Description,Active,TinNo,OfficialId,TaxPayerId,PayPalAccountLinked,PayPalPermissionGranted,PayPalEmailIdConfirmed,PayPalPaymentsReceivable&$expand=SocialMedia($select=SocialMediaId,Link)",
  SAVE_SELLER_STORE:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/SellerStore",
  SAVE_PROFILE_PIC:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/sellerstore/ProfilePic",
  GET_PAYPAL_LINK:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/SellerStore/OnboardSellerPayPal",
    DELINK_PAYPAL:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/SellerStore/DelinkPayPalAccount",
  LINK_PAYPAL_ACCOUNT:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/SellerStore/VerifySellerOnboardedPayPal",
  CONFIRM_EMAIL:
    BASE_URLS.BASE_URL +
    BASE_URLS.API_VERSION +
    "/Account/UserEmailConfirmation/",
  STORE_ADDRESS:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Account/SellerStoreAddress",
  CONTACT_BUYER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/contactbuyer",
  REPORT_BUYER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Report",
  ALL_MESSAGES:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Message/AllMessages",
  ARCHIVES_MESSAGE:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Message/ArchivesMessage",
  FLAG_UNFLAG_MESSAGE:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Message/FlagUnFlagMessage",
  READ_UNREAD_MESSAGE:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Message/ReadUnReadMessage",
    MESSAGE_COUNT:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/message/buyermessagescounts",
  DELETE_MESSAGE_BY_USER:
    BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Message/DeleteMessageByUser",
  PLACE_BID: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Bidding",
  ACTIVATE_USER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/user/accountactivationrequest/",
  DEACTIVATE_SELLER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/user/deactivateseller",

  BUYER: {
    HOMEPAGE: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/buyerhomepage",
  DEACTIVATE_USER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/user/deactivatebuyer",

    SAVED_PAYMENTS:BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/payment/tokens",
    PRODUCT_CATEGORY_PAGE:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/category/childcategory/",
    SAVED_SELLER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/SavedSeller",
    SAVE_USERNAME:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Account/UserName",
    GET_WATCHLIST: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/WatchList?",
    GET_SAVED_SELLERS:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/SavedSeller",
    SAVE_MOBILE_NO:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Account/UserMobileNo",
    SAVE_PROFILE_ADDRESS:
      BASE_URLS.BASE_URL +
      BASE_URLS.API_VERSION +
      "/Account/UserPersonalAddress",
    SAVE_OFFFER_NOTE:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/BuyerOffer/Note",
    SAVE_WATCHLIST_NOTE:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/WatchList/Note",
    DELETE_WATCHLIST: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/WatchList",
    SAVE_FEEDBACK: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/FeedBack",
  CONTACT_SELLER: BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/contactseller",

    GET_SINGLE_REVIEW:BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/buyerratingandreview",
    SAVE_PRODUCT_REVIEW:BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/ratingandreview",
    SAVE_VOTE:BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/VoteReview",

    SAVE_BID_NOTE:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Bidding/Notes",
    SAVE_SHIPPING_ADDRESS:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Account/ShippingAddress",
      SAVE_SHIPPING_ADDRESS_DEFAULT:
      BASE_URLS.BASE_URL + BASE_URLS.API_VERSION + "/Account/ShippingDefaultAddress",
    DELETE_SHIPPING_ADDRESS:
      BASE_URLS.BASE_URL +
      BASE_URLS.API_VERSION +
      "/account/deleteshippingaddress",
  },
};
