import matchers from "@testing-library/jest-dom/matchers";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { getDashboard } from "../../../modules/sellerDashboard/getDashboard";
import { getDashboardFeedbacks } from "../../../modules/sellerDashboard/getDashboardFeedbacks";
import { getDashboardTasks } from "../../../modules/sellerDashboard/getDashboardTasks";
import { DashboardResponseSelector } from "../../../modules/sellerDashboard/selectors";
import { getSales } from "../../../modules/sellerSales/getSales";
import { SalesResponseSelector } from "../../../modules/sellerSales/selectors";
import { getTraffic } from "../../../modules/sellerTraffic/getTraffic";
import { TrafficResponseSelector } from "../../../modules/sellerTraffic/selectors";
import { RootState } from "../../../utils/types";
import { Dashboard } from "../../dashboard/dashboard";
import { Chart } from "react-google-charts";
import { Link, useNavigate } from "react-router-dom";
import { reset } from "../../../modules/sellerProduct/reset";
import SellerBreadCrumbs from "../../../components/sellerBreadCrumbs/SellerBreadCrumbs";
import Footer from "../../../components/footer/Footer";
import { resetCategorySuccess } from "../../../modules/categories/slice";
import HeaderNavigationSeller from "../HeaderNavigationSeller";

export const Overview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const d = new Date();
  const isFirstRender = useRef(true);
  const [graph, setGraph] = useState([]);
  const token = localStorage.getItem("authorization");
  let traffic = useSelector<RootState, RootState["sellerTraffic"]>(
    TrafficResponseSelector
  );
  let dashboard = useSelector<RootState, RootState["dashboard"]>(
    DashboardResponseSelector
  );

  let sales = useSelector<RootState, RootState["sales"]>(SalesResponseSelector);
  const options = {
    title: "Sales ",
    chartArea: { width: "90%" },
    // hAxis: {
    //   title: "Months",
    //   minValue: 0,
    // },
    // vAxis: {
    //   title: "Sales",
    // },
  };
  const refreshDashboard = async () => {
    dispatch<any>(getDashboard());
    dispatch<any>(getTraffic());
    dispatch<any>(getSales());
    dispatch<any>(getDashboardTasks());
    dispatch<any>(getDashboardFeedbacks());
  };
  useEffect(() => {
    if (isFirstRender.current) {
      refreshDashboard();
    }
    // } else {
    //   dispatch<any>(resetSeller("sellers"))
    //     .then(dispatch<any>(resetProduct("products")))
    //     .then(dispatch<any>(resetReturnOrder("return")))
    //     .then(dispatch<any>(resetOrder("orders")));
    // }
    isFirstRender.current = false;
  }, [token]);

  useEffect(() => {
    if (sales.sales !== null) {
      let graphData: any = [["Months", "Sales"]];
      sales.sales.data.sellerSalesMonthWiseGraph.forEach(function (
        arrayItem: any
      ) {
        graphData.push([arrayItem.month, arrayItem.salesAmount]);
      });
      setGraph(graphData);
    }
  }, [sales.sales]);
  return (
    <DefaultLayout>
      <Header />
      <SellerBreadCrumbs />

     <HeaderNavigationSeller currentPage="Overview" />

      <main className="seller-page-section">
        <div className="container seller-overview-section">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="overview-block">
                <div className="overview-head">
                  <h3>Task</h3>
                </div>
                {dashboard.tasks !== null && (
                  <div className="overview-task">
                    <div className="overview-task-list">
                      <div className="task-list-l">
                        New orders received. Plan for shipping
                      </div>
                      <div className="task-list-r">
                        <span>
                          {dashboard.tasks.data.orders}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <Link to={"/seller/awaitingShip"}>View</Link>
                      </div>
                    </div>

                    {dashboard.tasks.data.feedBacks?.map(
                      (feedback: any, Index: number) => (
                        <div className="overview-task-list">
                          <div className="task-list-l">
                            Feedback send by <a href="#">{feedback.name}</a>
                          </div>
                          <div className="task-list-r">
                       
                            <Link to="/seller/storeFeedback">View</Link>
                         
                          </div>
                        </div>
                      )
                    )}
                    {dashboard.tasks.data.cancelledOrders?.map(
                      (order: any, Index: number) => (
                        <div className="overview-task-list">
                          <div className="task-list-l">
                            Order cancelled by <a href="#">{order.name}</a>
                          </div>
                          <div className="task-list-r">
                            <div
                              className=""
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "#3453c8",
                              }}
                              onClick={() => {
                                navigate("/seller/cancelOrderDetail", {
                                  state: order.id,
                                });
                              }}
                            >
                              View
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    {dashboard.tasks.data.productRunningShorts?.map(
                      (product: any, Index: number) => (
                        <div className="overview-task-list">
                          <div className="task-list-l">
                            "<a href="#">{product.title}</a>" running short
                            kindly update stock
                          </div>
                          <div className="task-list-r">
                            <div
                              className="product-detail"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "#3453c8",
                              }}
                              onClick={() => {
                                localStorage.setItem(
                                  "productId",
                                  product.productid
                                );
                                dispatch<any>(reset())
                                  .then(dispatch<any>(resetCategorySuccess()))
                                  .then(navigate("/seller/editListing"));
                              }}
                            >
                              View
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {/* <div className="overview-task-list">
                      <div className="task-list-l">
                        <a href="#">User ID</a> asked "user query" please
                        respond (check with rohit sir)
                      </div>
                      <div className="task-list-r">
                        <a href="#">Reply</a>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="overview-block">
                <div className="overview-head">
                  <h3>Sales</h3>
                  {/* <a href="#" className="view-all-link">
                    View all
                  </a> */}
                </div>
                {sales.sales !== null && (
                  <div className="overview-task">
                    <div className="overview-graph">
                      <Chart
                        chartType="ComboChart"
                        data={graph}
                        options={options}
                        graphID="BarChart"
                        width="100%"
                        height="100%"
                        legendToggle
                        // chartEvents={chartEvents}
                      />
                    </div>

                    <div className="overview-task-list">
                      <div className="task-list-l">Today</div>
                      <div className="task-list-r">
                        <div
                          className={` ${
                            sales.sales.data.sellerSalesDayWise.todaySale -
                              sales.sales.data.sellerSalesDayWise
                                .previousDaySale >
                            0
                              ? "increase-icon"
                              : "decrease-icon"
                          }`}
                        >
                          {sales.sales.data.sellerSalesDayWise.todaySale -
                            sales.sales.data.sellerSalesDayWise
                              .previousDaySale >
                          0
                            ? "+$"
                            : "-$"}
                          {Math.round(
                            Math.abs(
                              sales.sales.data.sellerSalesDayWise.todaySale -
                                sales.sales.data.sellerSalesDayWise
                                  .previousDaySale
                            ) * 100
                          ) / 100}
                        </div>
                      </div>
                    </div>

                    <div className="overview-task-list">
                      <div className="task-list-l">Last 7 days</div>
                      <div className="task-list-r">
                        <div
                          className={` ${
                            sales.sales.data.sellerSalesDayWise
                              .current7DaySale -
                              sales.sales.data.sellerSalesDayWise
                                .previous7DaySale >
                            0
                              ? "increase-icon"
                              : "decrease-icon"
                          }`}
                        >
                          {sales.sales.data.sellerSalesDayWise.current7DaySale -
                            sales.sales.data.sellerSalesDayWise
                              .previous7DaySale >
                          0
                            ? "+$"
                            : "-$"}
                          {Math.round(
                            Math.abs(
                              sales.sales.data.sellerSalesDayWise
                                .current7DaySale -
                                sales.sales.data.sellerSalesDayWise
                                  .previous7DaySale
                            ) * 100
                          ) / 100}
                        </div>
                      </div>
                    </div>

                    <div className="overview-task-list">
                      <div className="task-list-l">Last 31 days</div>
                      <div className="task-list-r">
                        <div
                          className={` ${
                            sales.sales.data.sellerSalesDayWise
                              .current31DaySale -
                              sales.sales.data.sellerSalesDayWise
                                .previous31DaySale >
                            0
                              ? "increase-icon"
                              : "decrease-icon"
                          }`}
                        >
                          {sales.sales.data.sellerSalesDayWise
                            .current31DaySale -
                            sales.sales.data.sellerSalesDayWise
                              .previous31DaySale >
                          0
                            ? "+$"
                            : "-$"}
                          {Math.round(
                            Math.abs(
                              sales.sales.data.sellerSalesDayWise
                                .current31DaySale -
                                sales.sales.data.sellerSalesDayWise
                                  .previous31DaySale
                            ) * 100
                          ) / 100}
                        </div>
                      </div>
                    </div>

                    <div className="overview-task-list">
                      <div className="task-list-l">Last 90 days</div>
                      <div className="task-list-r">
                        <div
                          className={` ${
                            sales.sales.data.sellerSalesDayWise
                              .current90DaySale -
                              sales.sales.data.sellerSalesDayWise
                                .previous90DaySale >
                            0
                              ? "increase-icon"
                              : "decrease-icon"
                          }`}
                        >
                          {sales.sales.data.sellerSalesDayWise
                            .current90DaySale -
                            sales.sales.data.sellerSalesDayWise
                              .previous90DaySale >
                          0
                            ? "+$"
                            : "-$"}{" "}
                          {Math.round(
                            Math.abs(
                              sales.sales.data.sellerSalesDayWise
                                .current90DaySale -
                                sales.sales.data.sellerSalesDayWise
                                  .previous90DaySale
                            ) * 100
                          ) / 100}
                        </div>
                      </div>
                    </div>

                    {/* <div className="ins-text">
                      Instruction for data showing above
                    </div> */}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="overview-block">
                <div className="overview-head">
                  <h3>Orders</h3>
                  <Link className="view-all-link" to={"/seller/orders"}>
                    View all
                  </Link>
                </div>
                {dashboard.dashboard !== null && (
                  <div className="overview-task">
                    <div className="order-list-block">
                      <div className="order-list-item">
                        <div className="order-list-l f-bold">
                          New orders received
                        </div>
                        <div className="order-list-r">
                          <div className="order-qty f-bold">
                            {dashboard.dashboard.data.totalNewOrders}
                          </div>

                          <Link className="f-bold" to={"/seller/awaitingShip"}>
                            View all
                          </Link>
                        </div>
                      </div>

                      {dashboard.dashboard.data.newOrders.map(
                        (order, Index: number) => (
                          <div className="order-list-item" key={Index}>
                            <div className="order-list-l">
                              <a href="#">
                                {order.title.length > 10
                                  ? order.title.substring(0, 10) + ".."
                                  : order.title}{" "}
                              </a>
                            </div>
                            <div className="order-list-r">
                              <div className="order-qty">{order.quantity}</div>
                              <div>{order.purchasingPrice}</div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    <div className="order-list-block">
                      <div className="order-list-item">
                        <div className="order-list-l f-bold">Cancel order</div>
                        <div className="order-list-r">
                          <div className="order-qty f-bold">
                            {dashboard.dashboard.data.totalCancelledOrders}
                          </div>
                          <Link
                            className="f-bold"
                            to={"/seller/orderCancellation"}
                          >
                            View all
                          </Link>
                        </div>
                      </div>

                      {dashboard.dashboard.data.cancelOrders.map(
                        (order, Index: number) => (
                          <div className="order-list-item" key={Index}>
                            <div className="order-list-l">
                              <a href="#">
                                {order.title.length > 10
                                  ? order.title.substring(0, 10) + ".."
                                  : order.title}{" "}
                              </a>
                            </div>
                            <div className="order-list-r">
                              <div className="order-qty">{order.quantity}</div>
                              <div>{order.purchasingPrice}</div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="overview-block">
                <div className="overview-head">
                  <h3>Listing</h3>
                  <div className="overview-btn-group">
                    <div
                      className="view-all-link"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // reset();
                        dispatch<any>(reset()).then(() => {
                          localStorage.setItem("productId", "");
                          navigate("/seller/createListing");
                        });
                      }}
                    >
                      Create listing
                    </div>
                    <Link className="view-all-link" to={"/seller/listing"}>
                      View all
                    </Link>
                  </div>
                </div>
                <div className="overview-task">
                  <div className="overview-task-list">
                    <div className="task-list-l f-bold">All listing</div>
                    <div className="task-list-r">
                      <Link to={"/seller/listing"}>View all</Link>
                    </div>
                  </div>
                  <div className="overview-task-list">
                    <div className="task-list-l f-bold">Unsold</div>
                    <div className="task-list-r">
                      <Link to={"/seller/unsoldListing"}>View all</Link>
                    </div>
                  </div>
                  <div className="overview-task-list">
                    <div className="task-list-l f-bold">Sold</div>
                    <div className="task-list-r">
                      <Link to={"/seller/soldListing"}>View all</Link>
                    </div>
                  </div>
                  <div className="overview-task-list">
                    <div className="task-list-l f-bold">Drafts</div>
                    <div className="task-list-r">
                      <Link to={"/seller/draftListing"}>View all</Link>
                    </div>
                  </div>

                  <div className="overview-task-list">
                    <div className="task-list-l f-bold">Scheduled</div>
                    <div className="task-list-r">
                      <Link to={"/seller/scheduledListing"}>View all</Link>
                    </div>
                  </div>
                  <div className="overview-task-list">
                    <div className="task-list-l f-bold">Ended</div>
                    <div className="task-list-r">
                      <Link to={"/seller/endedListing"}>View all</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="overview-block">
                <div className="overview-head">
                  <h3>Traffic</h3>
                  <div className="overview-btn-group">
                    {/* <a href="#" className="view-all-link">
                      View all
                    </a> */}
                  </div>
                </div>
                {traffic.sellerTraffic !== null && (
                  <div className="overview-task">
                    <div className="overview-task-list">
                      <div className="task-list-l">Today</div>
                      <div className="task-list-r">
                        <div
                          className={` ${
                            traffic.sellerTraffic.data.todayTraffic -
                              traffic.sellerTraffic.data.previousDayTraffic >
                            0
                              ? "increase-icon"
                              : "decrease-icon"
                          }`}
                        >
                          {/* {traffic.sellerTraffic.data.todayTraffic -
                            traffic.sellerTraffic.data.previousDayTraffic >
                          0
                            ? "+"
                            : "-"} */}
                          {Math.abs(
                            traffic.sellerTraffic.data.todayTraffic -
                              traffic.sellerTraffic.data.previousDayTraffic
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="overview-task-list">
                      <div className="task-list-l">Last 7 days</div>
                      <div className="task-list-r">
                        <div
                          className={` ${
                            traffic.sellerTraffic.data.current7DayTraffic -
                              traffic.sellerTraffic.data.previous7DayTraffic >
                            0
                              ? "increase-icon"
                              : "decrease-icon"
                          }`}
                        >
                          {/* {traffic.sellerTraffic.data.current7DayTraffic -
                            traffic.sellerTraffic.data.previous7DayTraffic >
                          0
                            ? "+"
                            : "-"} */}
                          {Math.round(
                            Math.abs(
                              traffic.sellerTraffic.data.current7DayTraffic -
                                traffic.sellerTraffic.data.previous7DayTraffic
                            ) * 100
                          ) / 100}
                        </div>
                      </div>
                    </div>

                    <div className="overview-task-list">
                      <div className="task-list-l">Last 31 days</div>
                      <div className="task-list-r">
                        <div
                          className={` ${
                            traffic.sellerTraffic.data.current31DayTraffic -
                              traffic.sellerTraffic.data.previous31DayTraffic >
                            0
                              ? "increase-icon"
                              : "decrease-icon"
                          }`}
                        >
                          {/* {traffic.sellerTraffic.data.current31DayTraffic -
                            traffic.sellerTraffic.data.previous31DayTraffic >
                          0
                            ? "+"
                            : "-"} */}
                          {Math.round(
                            Math.abs(
                              traffic.sellerTraffic.data.current31DayTraffic -
                                traffic.sellerTraffic.data.previous31DayTraffic
                            ) * 100
                          ) / 100}
                        </div>
                      </div>
                    </div>

                    <div className="overview-task-list">
                      <div className="task-list-l">Last 90 days</div>
                      <div className="task-list-r">
                        <div
                          className={` ${
                            traffic.sellerTraffic.data.current90DayTraffic -
                              traffic.sellerTraffic.data.previous90DayTraffic >
                            0
                              ? "increase-icon"
                              : "decrease-icon"
                          }`}
                        >
                          {/* {traffic.sellerTraffic.data.current90DayTraffic -
                            traffic.sellerTraffic.data.previous90DayTraffic >
                          0
                            ? "+"
                            : "-"} */}
                          {Math.round(
                            Math.abs(
                              traffic.sellerTraffic.data.current90DayTraffic -
                                traffic.sellerTraffic.data.previous90DayTraffic
                            ) * 100
                          ) / 100}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="overview-block">
                <div className="overview-head">
                  <h3>Feedback</h3>
                  <div className="overview-btn-group">
                    <a
                      href="#"
                      className="view-all-link"
                      onClick={() => {
                        navigate("/seller/storeFeedback");
                      }}
                    >
                      View all
                    </a>
                  </div>
                </div>
                <div className="overview-task feedback-overview">
                  <div className="feedback-numbers">Last 30 days</div>

                  {dashboard.feedbacks !== null && (
                    <div className="feedback-total">
                      <div className="">
                        {dashboard.feedbacks.data.positive} Feedbacks
                      </div>
                      {/* <div className="feedback-negative">
                        {dashboard.feedbacks.data.negative} Negative
                      </div> */}
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </DefaultLayout>
  );
};
