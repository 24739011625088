import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMessagesCount } from "../../../modules/buyer/myEfindit/message/getMessagesCount";
import { messageResponseSelector } from "../../../modules/buyer/myEfindit/message/selectors";
import { RootState } from "../../../utils/types";

interface Props {
  currentPage: string;
}

const GroupTab = ({ currentPage }: Props) => {

  const dispatch = useDispatch();
  let messageResponse = useSelector<RootState, RootState["messages"]>(
    messageResponseSelector
);
  useEffect(() => {
    dispatch<any>(getMessagesCount());
}, []);
  return (
    <div className="groupTabs">
      <ul className="list-unstyled tabList">
        <li className={`${currentPage === "Activity" ? "active" : ""}`}>
          <Link to="/buyer/activity/recentlyViewed">Activity</Link>
        </li>
        <li className={`${currentPage === "Messages" ? "active" : ""}`}>
        <Link to="/buyer/messages/inbox">Messages ({messageResponse?.messagesCount?.data})</Link>
        </li>
        <li className={`${currentPage === "Account" ? "active" : ""}`}>
          <Link to="/buyer/myaccount">Account</Link>
        </li>
      </ul>

      <div className="name">
      <span style={{
                            color:"#3453c8",
                            fontSize:14
                        }}>{localStorage.getItem("UserName")?.substring(0,20)}</span>
      </div>
    </div>
  );
};

export default GroupTab;
